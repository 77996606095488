import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Hover } from 'elements/hover';
import formatCnpj from "shared/utils/formatCnpj";
import { PatternFormat } from 'react-number-format';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';

const teste = require('shared/constants/leads.json');

const columns = [
  { id: 'CNPJ', label: 'CNPJ', minWidth: 170 },
  { id: 'Nome', label: 'Nome', minWidth: 100 },
  { id: 'Status', label: 'Status', minWidth: 100 },
  { id: 'ÚltimaAtualização', label: 'Última atualização', minWidth: 100 },
  { id: 'Arquivo', label: 'Arquivo', minWidth: 100 },
];

const DownloadPage = () => {
  const [proposalList, setProposalList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();

  const currencyLocale = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const listProposal = async function () {
    let leads;
    const token = await getToken();
    try {
      if (search !== '') {
        setPage(0);
        const result = await request(
          process.env.REACT_APP_API_URL + `/cnpj/${search}`, "get", token,
         
      );
        leads = result;
      } else {
        const result = await request(
          process.env.REACT_APP_API_URL + `/leads`, 'get', token,
        );
        leads = result;
      }
      setProposalList(leads);
    } catch (error) {
      console.error(error);
      setProposalList([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  async function downloadCSV(aId) {
    const token = await getToken();
    setLoading(true);
    try {
      const resultAnalysis = await request(
        process.env.REACT_APP_API_URL + `/analysis/${aId}`, 'get', token
      );
      const result = await request(
        process.env.REACT_APP_API_URL + `/legal/${aId}`,'get', token
      );
      const resultPricing = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${aId}`,'get', token
      );
      const response = result;
      const analysis = resultAnalysis;
      const pricing = resultPricing;
      let companyName = '';
      let companyCnpj = '';
      let tradingName = '';
      let companyAddress = '';
      let city = '';
      let zipCode = '';
      let district ='';
      let state = '';
  
      for (const f of response.formAnswer) {
        if (f.form.question == 'Razão Social:') {
          companyName = f?.questionAnswer;
        } else if (f.form.question == 'CNPJ:') {
          companyCnpj = f?.questionAnswer;
        } else if (f.form.question == 'Nome Fantasia:') {
          tradingName = f?.questionAnswer;
        } else if (f.form.question == 'Endereço Sede (Rua, nº, complemento):') {
          companyAddress = f?.questionAnswer;
        }else if (f.form.question == 'Bairro:') {
          district = f?.questionAnswer;
        } else if (f.form.question == 'Cidade:') {
          city = f?.questionAnswer;
        } else if (f.form.question == 'Estado:') {
          state = f?.questionAnswer;
        }else if (f.form.question == 'CEP:') {
          zipCode = f?.questionAnswer;
        }
      }
  
      const csvContent = `
      DADOS PARA PREENCHIMENTO DO FORMULÁRIO DE CONTRATAÇÃO - ENTREPAY,,,,
  ,,,,
  DADOS DA CONTRATANTE;;;DADOS DOS SIGNATÁRIOS;
  Origem: Formulário Inicial,,,Origem: Formulário Dados Contratuais;
  Razão Social,${companyName},,Representante Legal 1,
  CNPJ,${companyCnpj},,Nome Completo,${response?.legalForm?.legal1}
  Nome Fantasia,${tradingName},,Cargo,${response?.legalForm?.occupationLegal1}
  Endereço Sede,${companyAddress},,CPF/CNPJ,${
        response?.legalForm?.cpfOrCnpjLegal1
      }
  Cidade,${city},,Email,${response?.legalForm?.emailLegal1}
  Bairro,${district},,,
  CEP,${zipCode},,Representante Legal 2,
  Estado,${state},,Nome Completo,${response?.legalForm?.legal2}
  Email,${analysis?.email},,Cargo,${response?.legalForm?.occupationLegal2}
  ,,,CPF/CNPJ,${response?.legalForm?.cpfOrCnpjLegal2}
  DADOS BANCÁRIOS DO CLIENTE,,,Email,${response?.legalForm?.emailLegal2}
  Origem: Formulário Dados Contratuais,,,,
  Nome do Titular,${response?.legalForm?.accountHolder},,Testemunha;
  CPF/CNPJ,${response?.legalForm?.cpfOrCnpjAccountHolder},,Nome Completo,${
        response?.legalForm?.witness
      }
  Banco,${response?.legalForm?.bank},,Cargo,${
        response?.legalForm?.occupationWitness
      }
  Agência,${response?.legalForm?.branch},,CPF,${
        response?.legalForm?.cpfOrCnpjWitness
      }
  Conta,${response?.legalForm?.account},,Email,${
        response?.legalForm?.emailWitness
      }
  ,,,,
  DADOS OPERACIONAIS,,,,
  Origem: Forecast,,,,
  TPV Médio,${currencyLocale.format(pricing?.[0]?.tpvAverage)},,,
  TICKET MÉDIO,${currencyLocale.format(pricing?.[0]?.tmAverage)},,,
  IRÁ ANTECIPAR?,${response?.forecastGeneral?.prepayment > 0 ? 'SIM' : 'NÃO'},,,,
  PERCENTUAL DE ANTECIPAÇÃO,${response?.forecastGeneral?.prepayment}%,,,
  PRAZO MÉDIO,${pricing?.[0]?.weightedDays},,,
  TECNOLOGIA (SE ATUARÁ PRESENTE E/OU DIGITAL);ONLINE;PRESENTE,,
  QUAL A PORCENTAGEM POR TECNOLOGIA,${
        response?.forecastGeneral?.performanceWithoutCard
      }%,${response?.forecastGeneral?.performanceWithCard}%,,
  PORCENTAGEM POR PRODUTO (DÉBITO/CRÉDITO/PARCELADO), DÉBITO ${response?.forecastGeneral?.performanceByDebit}%,CRÉDITO ${response?.forecastGeneral?.performanceByCredit}%,PARCELADO ${response?.forecastGeneral?.performanceByInstallment}%,
  ,,,,
  PREÇO E GARANTIA,,,,
  Origem: Sistema Interno EntrePay,,,,
  GARANTIAS APLICADA POR RISCO, Valor + % reserva,,,,
  GARANTIA, ${analysis?.guarantee?.acceptedGuarantee},,,,
  CONDIÇÃO ESPECIAL RISCO, ${analysis?.specialConditionRisk != null ? analysis?.specialConditionRisk  : "NÃO POSSUI"},,,
  CONDIÇÃO ESPECIAL PRICING, ${analysis?.specialConditionPricing != null ? analysis?.specialConditionPricing  : "NÃO POSSUI"},,,
  RISCO ESTIMADO DO CLIENTE,${response?.riskLevel},,,
  ,,,,
  PRECIFICAÇÃO APROVADA PELO CLIENTE (ITC + FEE),Fee,,,
  ${pricing?.[0]?.id ? `de ${pricing?.[0]?.tpvMin} até ${pricing?.[0]?.tpvMax} ,${pricing?.[0]?.discountFee != 0 ? pricing?.[0]?.discountFee : pricing?.[0]?.fee }%,,,` : ''}
  ${pricing?.[1]?.id ? `de ${pricing?.[1]?.tpvMin} até ${pricing?.[1]?.tpvMax} ,${pricing?.[1]?.discountFee != 0 ? pricing?.[1]?.discountFee : pricing?.[1]?.fee }%,,,` : ''}
  ${pricing?.[2]?.id ? `de ${pricing?.[2]?.tpvMin} até ${pricing?.[2]?.tpvMax} ,${pricing?.[2]?.discountFee != 0 ? pricing?.[2]?.discountFee : pricing?.[2]?.fee }%,,,` : ''}
  ,,,,
  TAXA DE ANTECIPAÇÃO,${pricing?.[0]?.prepaymentFee}%,,,
      `.replace(/;/g, ',');
  
      const universalBOM = '\uFEFF'
      const blob = new Blob([universalBOM + csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
    
  
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'DadosContratoJuridico.csv');
      link.style.display = 'none';
      document.body.appendChild(link);
  
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      await axios.put(
        process.env.REACT_APP_API_URL + `/analysis/${aId}`,
        {
          statusCsv: 'Baixado',
        }
      );
      
      toast.success("Documento gerado com sucesso.");
      listProposal();
    } catch (error) {
      toast.error("Erro ao gerar documento, por favor tente mais tarde.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    listProposal();
  }, []);

  const downloadXLSX = function (aId) {
    downloadCSV(aId);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: '100%',
            textAlign: 'center',
            padding: { xs: 8, lg: 12, xl: '48px 64px' },
            overflow: 'hidden',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Diretório de Dados Contratuais
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ justifyContent: 'end', display: 'flex' }}>
          <PatternFormat 
   required
   customInput={TextField}
   style={{ height: '30px', marginRight: '10px' }}
   value={search} 
   allowLeadingZeros
   format="##.###.###/####-##" 
   allowEmptyFormatting 
   onValueChange={(values, sourceInfo) => {
    setSearch(values.value);
            }}
   mask="_" 
   
   
   />
            <Button
              onClick={listProposal}
              sx={{ backgroundColor: '#000536', mb: 5, mt:1 }}
              variant="contained"
              small
            >
              Buscar
            </Button>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: '#000536', color: '#ffffff' }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {teste
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const analysis = row.analysis;
                    let status = '';
                    let date = '';
                    if (analysis?.acceptTerms?.status === 'Pendente') {
                      status = 'Aguardando Termos';
                    } else if (analysis?.statusForm === null) {
                      status = 'Aguardando Formulario';
                      date = analysis?.acceptTerms?.termsAcceptDate;
                    } else if (analysis?.statusForecastCompleted === null) {
                      status = 'Aguardando Forecast';
                      date = analysis?.dateForm;
                    } else if (analysis?.statusUpload === null) {
                      status = 'Aguardando Upload';
                      date = analysis?.forecastDate;
                    } else if (analysis?.statusRisk === "Em analise") {
                      status = 'Aguardando Risco';
                      date = analysis?.uploadDate;
                    } else if (analysis?.guarantee?.statusGuarantee === null) {
                      status = 'Aguardando Garantia';
                      date = analysis?.guarantee?.statusPreApprovalDate;
                    } else if (analysis?.statusPricing === "Em analise") {
                      status = 'Aguardando Pricing';
                      date = analysis?.guarantee?.guaranteeDate;
                    } else if (analysis?.statusProposal === null) {
                      status = 'Aguardando Proposta';
                      date = analysis?.PricingDate;
                    } else if (analysis?.statusCompliance === null) {
                      status = 'Aguardando Compliance';
                      date = analysis?.dateCompliance;
                    } else if (analysis?.legalForm === null) {
                      status = 'Aguardando dados para contrato';
                      date = analysis?.dateCompliance; 
                    } else if (analysis?.statusCsv === null) {
                      status = 'Disponivel';
                      date = analysis?.proposalDate;
                    } else {
                      status = 'Baixado';
                      date = analysis?.dateCsv;
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell> {formatCnpj(row?.cnpj)}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{status?.toUpperCase()}</TableCell>
                        <TableCell>
                          {date != '' && date != null
                            ? format(parseISO(date), 'dd-MM-yyyy HH:mm:ss')
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {' '}
                          <Button
  onClick={() => downloadXLSX(row?.analysis?.id)}
  sx={{
    backgroundColor: '#000536',
    borderRadius: '10px',
  }}
  variant="contained"
  size="small"
  disabled={
    loading ||
    (status !== 'Disponivel' && status !== 'Baixado')
  }
>
  Download
</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={proposalList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Hover loading={loading} />
      </Box>
    </Fragment>
  );
};

export default DownloadPage;
