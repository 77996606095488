export const formatDocument = (aDoc) => {
  if (aDoc.length === 14) {
    // cnpj
    return aDoc.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }

  if (aDoc.length === 11) {
    // cpf
    return aDoc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return aDoc;
};
