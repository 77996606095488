/**
 * Lista as transações referentes a pagamentos de boleto nas máquinas Paynet.
 * Todos os pagamentos de boleto têm como EC a própria ENTREPAY
 * Para fazer esse busca, precisamos de 3 APIs da Paynet (SPR, SLG E PSI), pois nenhuma traz todas
 * as informações.
 *
 * Primeiro a gente lista as transações do PSI, que nos traz as que foram referentes a boleto (diferente
 * da SLG que traz todas transações, inclusive as transações 'normais' do EC).
 * Porém o PSI não traz todos os dados, então precisamos do SLG.
 * Com essa lista de transações do PSI em mãos, a gente lista no SLG todas as transações do mesmo período e pega
 * as correspondentes pelo número do NSU da transação, que é comum entre as duas APIs.
 *
 * Além disso, para saber qual é o EC, eu pego a lista de todos os terminais e busco no SPR. Informoterminal e ela retorna EC.
 * Depois pego também o agendamento na PSI, mas agora no método de consulta de agendamentos, pelo ID da transação no PSI.
 * Ele retorna o código de barras, valor do boleto, descontos, etc.
 *
 * Na SLG ele traz, entre outras coisas, o valor da transação (com MDR e sem MDR), no entanto é possível que a pessoa pague múltiplos boletos
 * em uma só transação. Aí na SLG vai trazer o valor total da transação, e não de cada boleto.
 */

import React, { useEffect, useState, Fragment, useRef } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { plain } from 'shared/utils/plain';
import { exportCSV } from 'shared/utils/exportCSV';
import { formatDocument } from 'shared/utils/formatDocument';
import { DataTable } from 'elements/dataTable';
import { Hover } from 'elements/hover';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { NumericFormat } from 'react-number-format';

import {
  Box,
  Card,
  TextField,
  FormControl,
  Modal,
  Button,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import axios from 'axios';

import { useAuthMethod } from 'hooks/AuthHooks';

import { Detalhado } from './Detalhado';

export const ConsultaTransacoes = () => {
  const isMounted = useRef(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [dataInicio, setDataInicio] = useState(dayjs());
  const [dataFim, setDataFim] = useState(dayjs());

  const [comissao, setComissao] = useState(0.35);

  const [loading, setLoading] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);

  // Quando clica sobre um item da tabela, pega o CNPJ selecionado
  // e monta uma lista filtrada só com as transacoesSlg dele para exibir no detalhe (modal)
  const [transacoes, setTransacoes] = useState([]);

  // Tabela com os totais por EC
  const [tabelaConsolidada, setTabelaConsolidada] = useState([]);

  const columnsConsolidado = [
    {
      field: 'total',
      headerName: 'Total Transações',
      width: '100%',
      flex:1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box
          sx={{
          }}
        >
          <SearchIcon sx={{ marginRight: 1 }} />
          {params.value}
        </Box>
      ),
    },
    { field: 'comissao', headerName: 'Comissão',  width: '100%', flex: 1, align: 'center', headerAlign: 'center',},
    { field: 'document', headerName: 'CNPJ/CPF',  width: '100%', flex: 1, align: 'center', headerAlign: 'center',},
    { field: 'name', headerName: 'Nome',  width: '100%', flex: 1, align: 'center', headerAlign: 'center',},
  ];

  useEffect(() => {
    isMounted.current = true;
  }, []);

  // se trouxe novos dados, atualiza a tabela consolidada
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    updateConsolidatedData();
  }, [transacoes, comissao, isMounted]);

  const handleChangeInicio = (newValue) => {
    setDataInicio(dayjs(newValue));
  };

  const handleChangeFim = (newValue) => {
    setDataFim(dayjs(newValue));
  };

  ///REQUESTS

  const fetchDataPaynetPsi = async function (aToken) {
    try {
      const trans = [];

      let page = 1;
      let result;
      // lista as transacoes
      do {
        result = await sendRequestPaynetPsi(aToken, page);
        page = page + 1;
        // adiciona as transações
        trans.push(...result.data);
      } while (!Boolean(result.paginator.is_last_page));

      // agora busca o agendamento de cada transacao
      const promises = [];
      for (const t of trans) {
        promises.push(buscaAgendamentoPsi(aToken, [t.id]));
      }
      result = await Promise.allSettled(promises); // o retorno é na mesma ordem
      console.log(result);
      for (const [index, item] of result.entries()) {
        if (item.status === 'fulfilled') {
          trans[index].agendamento = item.value;
        } else {
          trans[index].agendamento = {
            digitable_line: '',
            due_date: '',
            discount_amount: '0',
            nominal_value: '0',
            amount: '0',
          };
        }
      }

      return trans;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const sendRequestPaynetPsi = async (aToken, aPage) => {
    try {
      const params = {
        method: 'POST',
        url: 'https://backend.entrepay.com.br/paynet-psi/transactions',
        params: {
          page: aPage,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
        data: {
          filters: {
            date_from: dataInicio.format('YYYY-MM-DD'),
            date_until: dataFim.format('YYYY-MM-DD'),
          },
        },
      };
      // console.log(params);
      const res = await axios(params);
      console.log('DATA PSI:', res.data.data);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const buscaAgendamentoPsi = async (aToken, aId) => {
    try {
      const params = {
        method: 'GET',
        url: `https://backend.entrepay.com.br/paynet-psi/payments/show/${aId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
      };
      console.log('-------------');
      console.log(params);
      const res = await axios(params);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const fetchDataPaynetSlg = async function (aToken) {
    try {
      const t = [];

      let page = 1;
      let result;
      do {
        result = await sendRequestPaynetSlg(aToken, page);
        page = page + 1;
        // adiciona as transações
        t.push(...result.data);
      } while (!Boolean(result.paginator.is_last_page));

      console.log('DATA slg:', t);
      return t;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const sendRequestPaynetSlg = async (aToken, aPage) => {
    try {
      const params = {
        method: 'POST',
        url: 'https://backend.entrepay.com.br/paynet-slg/transactions',
        params: {
          page: aPage,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
        data: {
          filters: {
            //            cnpj: '17887874000105', // Cnpj da Entrepay
            transaction_type_id: 2, // autorizada
            initial_created_at: dataInicio.format('YYYY-MM-DD'),
            final_created_at: dataFim.format('YYYY-MM-DD'),
          },
        },
      };
      // console.log(params);
      const res = await axios(params);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const fetchDataPaynetSpr = async function (aToken, aTerminalSerialNumber) {
    try {
      const params = {
        method: 'POST',
        url: 'https://backend.entrepay.com.br/paynet-spr/point-services',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
        data: {
          serial_number: aTerminalSerialNumber,
        },
      };
      // console.log(params);
      const res = await axios(params);
      if (res.data.data.length) {
        console.log('DATA spr:', res.data.data);
        return res.data.data[0];
      } else {
        return null;
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  // Função de comparação pelo nome
  // usada para ordenar a lista
  function compare(a, b) {
    if (plain(a.name) < plain(b.name)) {
      return -1;
    }
    if (plain(a.name) > plain(b.name)) {
      return 1;
    }
    return 0;
  }

  // Pega a lista de todas as transações e monta
  // uma lista consolidada com os totais por empresa
  const updateConsolidatedData = async function () {
    const companies = [];

    // Verifica se já tem aquele CNPJ na lista consolidada de empresas
    const checkCompany = function (aArray, aDocument) {
      let result = false;

      for (const item of aArray) {
        if (item.document === aDocument) {
          result = true;
        }
      }

      return result;
    };

    // Incrementa o contador de transações daquela empresa
    const increaseCount = function (aArray, aDocument) {
      for (let item of aArray) {
        if (item.document === aDocument) {
          item.total += 1;
          return;
        }
      }
    };

    for (const t of transacoes) {
      // verifica se não tem a empresa, então adiciona
      const document = t.spr?.company?.document;
      if (document && !checkCompany(companies, document)) {
        companies.push({
          total: 0,
          document: document,
          name: t.spr.company.trade_name,
        });
      }

      increaseCount(companies, document);
    }

    // Guarda no state, por ordem alfabética de nome
    const rows = companies.sort(compare).map((item, index) => ({
      id: index,
      total: item.total,
      comissao: (item.total * comissao).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
      name: item.name,
      document: formatDocument(item.document), // CNPJ/CPF
    }));

    setTabelaConsolidada(rows);
  };

  const handleCloseModal = () => {
    setSelectedCompany(null);
  };

  const handleCellClick = (e) => {
    console.log(e.row.document);

    // pega o cnpj sem pontos e traços
    const document = e.row.document.replace(/[///./-]/g, '');

    for (const t of transacoes) {
      if (t.spr.company.document === document) {
        setSelectedCompany(t.spr.company);
        return;
      }
    }
  };

  const handleSearch = async (e) => {
    try {
      setLoading(true);

      const psi = [];
      const slg = [];
      const spr = [];

      /// FUNÇÕES AUXILIARES

      // retorna a transação SLG pelo número do NSU
      const getSlg = function (aNsu) {
        for (const s of slg) {
          if (s.nsu === aNsu) {
            return s;
          }
        }
        return null;
      };

      // retorna os dados do estabelecimento (api spr) pelo número do terminal
      const getSpr = function (aSerial) {
        for (const s of spr) {
          if (tiraZeros(s?.serial_number) === aSerial) {
            return s;
          }
        }
        return null;
      };

      // remove os zeros 'a esquerda
      const tiraZeros = (aSerial) => {
        return parseInt(aSerial, 10).toString();
      };

      // Pega o token do login para passar na chamada
      const token = await getToken();

      const promises = [];
      promises.push(fetchDataPaynetSlg(token));
      promises.push(fetchDataPaynetPsi(token));

      let result = await Promise.allSettled(promises); // o retorno é na mesma ordem, por isso a primeira é a SLG
      if (result[0].status === 'fulfilled') {
        slg.push(...result[0].value);
      }
      if (result[1].status === 'fulfilled') {
        psi.push(...result[1].value);
      }

      // monta a lista com os numeros de serie dos terminais
      // de cada transacao PSI
      const terminals = psi.map((item) => {
        return tiraZeros(item.serial_number); // na PSI vem com zeros à esquerda, mas para buscar na SPR precisa remover
      });
      // remove os duplicados
      const uniqueTerminals = [...new Set(terminals)];

      // busca o estabelecimento de cada terminal
      promises.splice(0, promises.length); // limpa o array
      for (const t of uniqueTerminals) {
        promises.push(fetchDataPaynetSpr(token, t));
      }
      result = await Promise.allSettled(promises);
      for (const r of result) {
        if (r.status == 'fulfilled') {
          spr.push(r.value);
        }
      }

      // agora busca o agendamento de cada ID que veio do PSI

      // Agora monta o array de transações
      // cada objeto contém:
      // - a transação PSI,
      // - sua transação SLG correspondente (pode ter mais de uma PSI para a mesma SLG)
      // - SPR com a empresa daquele terminal
      const trs = [];
      for (const p of psi) {
        const tr = {
          psi: p,
          slg: getSlg(p.acquirer_nsu),
          spr: getSpr(tiraZeros(p.serial_number)),
        };
        trs.push(tr);
      }

      setTransacoes(trs);
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    } finally {
      setLoading(false);
    }
  };

  ///////// CSV /////////////
 /* const handleExportAll = async function () {
    let csvData =
      'Data;Hora;Valor Transação;Valor Líquido Transação;MDR transação;Terminal;Bandeira;Produto;Método;Cartão;Parcelas;NSU;Código de Barras;Vencimento;Desconto;Valor Nominal;Valor Boleto;Estabelecimento;CNPJ\r\n';
  
    for (const item of transacoes) {
      // Data
      csvData += new Date(item.slg.date).toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      csvData += ';';
  
      // Hora
      csvData += new Date(item.slg.date).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      csvData += ';';
  
      // Valor Transacao
      csvData += parseFloat(item.slg.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      csvData += ';';
  
      // Valor líquido transação
      csvData += parseFloat(item.slg.liquid_amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      csvData += ';';
  
      //  mdr
      csvData += (
        parseFloat(item.slg.amount) - parseFloat(item.slg.liquid_amount)
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      csvData += ';';
  
      // Terminal
      csvData += `"=""${item.slg.terminal_identification}"""`;
      csvData += ';';
  
      // Bandeira
      csvData += item.slg.product.brand.name;
      csvData += ';';
  
      // Produto
      csvData += item.slg.product.payment_method.description;
      csvData += ';';
  
      // Metodo
      csvData += item.slg.entry_mode.description;
      csvData += ';';
  
      // Cartao
      csvData += item.slg.card_number;
      csvData += ';';
  
      // Parcelas
      csvData += item.slg.get_installments.length;
      csvData += ';';
  
      // NSU
      csvData += `"=""${item.slg.nsu}"""`;
      csvData += ';';
  
      // codigo de barras
      csvData += `"=""${item.psi.agendamento.bank_slip}"""`;
      csvData += ';';
  
      // vencimento
      csvData += new Date(item.psi.agendamento.due_date).toLocaleDateString(
        'pt-BR',
        {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }
      );
      csvData += ';';
  
      // desconto
      csvData += parseFloat(item.psi.agendamento.discount_amount).toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        }
      );
      csvData += ';';
  
      // valorNominal
      csvData += parseFloat(item.psi.agendamento.nominal_value).toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        }
      );
      csvData += ';';
  
      // valorBoleto
      csvData += parseFloat(item.psi.agendamento.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      csvData += ';';
  
      // Estabelecimento
      csvData += item.spr.company.company_name;
      csvData += ';';
  
      // CNPJ
      csvData += formatDocument(item.spr.company.document);
      csvData += ';';
  
      csvData += '\r\n';
    }
    exportCSV(csvData);
  };  */

///////// EXCEL /////////////
const handleExportAll = async function () {
  const excelData = [];

  const headers = [
    'Data',
    'Hora',
    'Valor Transação',
    'Valor Líquido Transação',
    'MDR transação',
    'Terminal',
    'Bandeira',
    'Produto',
    'Método',
    'Cartão',
    'Parcelas',
    'NSU',
    'Código de Barras',
    'Vencimento',
    'Desconto',
    'Valor Nominal',
    'Valor Boleto',
    'Estabelecimento',
    'CNPJ',
  ];
  excelData.push(headers);

  for (const item of transacoes) {
    const rowData = [
      // Data
      new Date(item.slg.date).toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
      // Hora
      new Date(item.slg.date).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      // Valor Transacao
      parseFloat(item.slg.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      // Valor líquido transação
      parseFloat(item.slg.liquid_amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      //  mdr
      (parseFloat(item.slg.amount) - parseFloat(item.slg.liquid_amount)).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      // Terminal
      `"=""${item.slg.terminal_identification}"""`,
      // Bandeira
      item.slg.product.brand.name,
      // Produto
      item.slg.product.payment_method.description,
      // Metodo
      item.slg.entry_mode.description,
      // Cartao
      item.slg.card_number,
      // Parcelas
      item.slg.get_installments.length,
      // NSU
      `"=""${item.slg.nsu}"""`,
      // codigo de barras
      `"=""${item.psi.agendamento.bank_slip}"""`,
      // vencimento
      new Date(item.psi.agendamento.due_date).toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
      // desconto
      parseFloat(item.psi.agendamento.discount_amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      // valorNominal
      parseFloat(item.psi.agendamento.nominal_value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      // valorBoleto
      parseFloat(item.psi.agendamento.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      // Estabelecimento
      item.spr.company.company_name,
      // CNPJ
      formatDocument(item.spr.company.document),
    ];
    excelData.push(rowData);
  }
  exportExcel(excelData);
  };



  return (
    <Fragment>
      <Hover loading={loading} />

      <Modal open={Boolean(selectedCompany)}>
        <Detalhado
          transacoes={transacoes}
          company={selectedCompany}
          comissao={comissao}
          handleClose={handleCloseModal}
        />
      </Modal>

      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: 'flex',
          // flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
            padding: 2,
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 220 } }}
                label={'Inicio'}
                value={dataInicio}
                onChange={handleChangeInicio}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 220 } }}
                label={'Fim'}
                value={dataFim}
                onChange={handleChangeFim}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth>
            <NumericFormat
              required
              label="Comissão por transação"
              customInput={TextField}
              decimalScale={2}
              fixedDecimalScale
              value={comissao}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              prefix="R$ "
              sx={{
                width: '100%',
              }}
              onValueChange={(values, sourceInfo) => {
                setComissao(values.floatValue);
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                m: 1,
                minWidth: 50,
                color: 'primary.contrastText',
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.light' },
              }}
            >
              Pesquisar
            </Button>
          </FormControl>
        </Box>

        <FormControl>
          <Button
            variant="contained"
            onClick={handleExportAll}
            sx={{
              m: 1,
              minWidth: 50,
              color: 'primary.contrastText',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
          >
            Exportar tudo
          </Button>
        </FormControl>

        {/* tabela com as transações consolidadas */}
        <Card
          sx={{
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            padding: 2,
            borderRadius: '16px',
            width: '100%',
            height: '100%',
          }}
        >
          <DataTable
            rows={tabelaConsolidada}
            columns={columnsConsolidado}
            onCellClick={handleCellClick}
          />
        </Card>
      </Box>
    </Fragment>
  );
};
