import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
// import {Form, Formik} from 'formik';
import Toolbar from '@mui/material/Toolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';

// import * as yup from 'yup';

// const validationSchema = yup.object({
//   keyword: yup.string().required('Digite'),
// });

const currencyLocale = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const PrepaymentFee = () => {
  const [prepayment, setPrepayment] = useState();
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();


  const prepaymentFeeList = useCallback(async function () {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/prepaymentfee`, 'get', token
      );
      setPrepayment(result);
    } catch (error) {
      console.error(error);
      setPrepayment([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    prepaymentFeeList();
  }, [prepaymentFeeList]);

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: 'flex',
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: '100%',
          textAlign: 'center',
          overflow: 'hidden',
          padding: { xs: 8, md: 12 },
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Lista de fees por antecipação
              </Typography>
            </Toolbar>
          </AppBar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Preço</TableCell>
                  <TableCell align="center">DE</TableCell>
                  <TableCell align="center">ATÉ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prepayment?.map((prepayment) => {
                  return (
                    <TableRow
                      key={prepayment?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {prepayment?.fee}%
                      </TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(prepayment?.minValue)}
                      </TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(prepayment?.maxValue)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Hover loading={loading} />
      </Card>
    </Box>
  );
};

export default PrepaymentFee;
