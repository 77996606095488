import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import axios from 'axios';
import { Typography, AppBar } from '@mui/material';
import { sortBy } from 'lodash';
import { Hover } from 'elements/hover';
import { toast } from 'react-toastify';
import { Fonts } from 'shared/constants/AppEnums';
import { format, parseISO } from 'date-fns';
import { PatternFormat } from 'react-number-format';
import formatCnpj from 'shared/utils/formatCnpj';
import Button from '@mui/material/Button';
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';





const columns = [
  { id: 'cnpj', label: 'CNPJ', minWidth: 170 },
  { id: 'name', label: 'Nome', minWidth: 100 },
  { id: 'clientType', label: 'Tipo', minWidth: 100 },
  { id: 'precificacao', label: 'Precificacao', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'data', label: 'Data', minWidth: 100 },
  { id: 'data', label: '', minWidth: 100 },
  
];



export const PricingAnalysisList = () => {
  const [proposalList, setProposalList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const listProposal = async function () {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/leads`, 'get', token,
      );
      const leads = sortBy(result, (v) => v.analysis?.statusPricing);
      setProposalList(leads);
    } catch (error) {
      console.error(error);
      setProposalList([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal();
  }, []);

  const handleRowClick = function (aId, pricingType) {
    if (pricingType === "Personalizada") {
      return navigate(`/onboarding/pricing/analysis/customproposal/${aId}`);

    }else if (pricingType === "MDR") {

      return navigate(`/onboarding/pricing/analysis/standardmdr/${aId}`);

    }else {    
      
      return navigate(`/onboarding/pricing/analysis/standardproposal/${aId}`);
  }

  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: '100%',
            textAlign: 'center',
            padding: { xs: 8, lg: 12, xl: '48px 64px' },
            overflow: 'hidden',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Lista de Propostas
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: '#000536', color: '#ffffff' }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const analysis = row?.analysis;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                       <TableCell>{formatCnpj(row.cnpj)}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{analysis?.clientType}</TableCell>
                        <TableCell>
                          {analysis?.pricingType === null
                            ? '-'
                            : analysis?.pricingType}
                        </TableCell>
                        <TableCell>{analysis?.statusPricing?.toUpperCase()}</TableCell>
                        <TableCell>
                          {analysis?.analysisDate
                            ? format(
                                parseISO(analysis.analysisDate),
                                'dd-MM-yyyy HH:mm:ss'
                              )
                            : ''}
                        </TableCell>
                        <TableCell  sx={{ display:'flex' }}>
                          <Button
                        onClick={() => handleRowClick(analysis.id, analysis.pricingType)}
                        sx={{
                              backgroundColor: '#000536',
                              borderRadius: '10px',
                            }}
                            variant="contained"
                            size="small"
                            disabled={analysis ?.statusForecastCompleted?.toUpperCase() !== 'COMPLETO' || analysis?.statusRisk?.toUpperCase() !== "APROVADO" }
                          >
                            Visualizar
                          </Button>
                         
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={proposalList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </Fragment>
  );
};
