import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
// import {Form, Formik} from 'formik';
import Toolbar from '@mui/material/Toolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';
import { DataGrid, ptBR } from "@mui/x-data-grid";



// import * as yup from 'yup';

// const validationSchema = yup.object({
//   keyword: yup.string().required('Digite'),
// });

const currencyLocale = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const FeeMdr = () => {
  const [mdr, setMdr] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const columns = [
    { field: "mcc", headerName: "MCC"},
    { field: "cnae", headerName: "CNAE"},
    { field: "ticketMedioFrom", headerName: "TM de",  valueFormatter: (params) => currencyLocale.format(params.value) },
    { field: "ticketMedioTo", headerName: "TM até",  valueFormatter: (params) => currencyLocale.format(params.value) },
    { field: "tpvFrom", headerName: "TPV de",  valueFormatter: (params) => currencyLocale.format(params.value) },
    { field: "tpvTo", headerName: "TPV até",  valueFormatter: (params) => currencyLocale.format(params.value) },
    { field: "prepaymentMoreThan50", headerName: "Antecipação < 50%", valueFormatter: (params) => params.value === true ? "Sim" : "Não"},
    { field: "cardBrand", headerName: "Bandeira"},
    { field: "product", headerName: "Produto" },
    { field: "mdr", headerName: "MDR", valueFormatter: (params) => ` ${(params.value).toFixed(2)} %` },


  ];


  const mdrList = useCallback(async function () {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/feemdr`, 'get', token
      );
      setMdr(result);
    } catch (error) {
      console.error(error);
      setMdr([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    mdrList();
  }, [mdrList]);

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: 'flex',
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: '100%',
          textAlign: 'center',
          overflow: 'hidden',
          padding: { xs: 8, md: 12 },
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Lista de fees para MDR
              </Typography>
            </Toolbar>
          </AppBar>
          <DataGrid
          rows={mdr}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[30, 60, 100]}
          checkboxSelection={false}
        />
        </Box>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default FeeMdr;
