import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';

export const Hover = ({ loading }) => (
  <Fragment>
    {loading && (
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )}
  </Fragment>
);

Hover.propTypes = {
  loading: PropTypes.bool,
};
