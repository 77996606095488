import React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

export const DataTable = ({ rows, columns, ...other }) => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              allColumns: true,
              delimiter: ';',
              utf8WithBom: true,
            }}
          />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      autoPageSize
      slots={{ toolbar: CustomToolbar }}
      slotProps={{
        printOptions: {
          disableToolbarButton: true,
        },
      }}
      rows={rows}
      columns={columns}
      sx={{
        minHeight: 450,
        width: '100%',
        bgcolor: 'white',
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          fontWeight: 'bold',
        },
      }}
      disableDensitySelector
      disableColumnSelector
      localeText={{
        toolbarFilters: 'Filtro',
        toolbarExport: 'Exportar',
        toolbarExportCSV: 'CSV',
      }}
      {...other}
    />
  );
};
