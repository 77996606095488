import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'elements/dataTable';
import axios from 'axios';
import { Box, Button } from '@mui/material';
import uuid4 from 'uuid4';

import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const BtgWebhooksHealthCheck = () => {
  // token da autenticação
  const { getToken } = useAuthMethod();

  const [loading, setLoading] = useState(false);

  const [data, setData] = React.useState([]);

  const columns = [
    { field: 'entity', headerName: 'Entidade', width: 250 },
    { field: 'endpoint', headerName: 'Endpoint', width: 450 },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      renderCell: (params) => {
        if (Boolean(params.value)) {
          return (
            <CloseIcon
              sx={{
                color: 'red',
              }}
            />
          );
        } else {
          return (
            <CheckIcon
              sx={{
                color: 'green',
              }}
            />
          );
        }
      },
    },
    // { field: 'cnpj', headerName: 'CNPJ', width: 150 },
    // { field: 'nome', headerName: 'Nome', width: 200 },
    // { field: 'email', headerName: 'email', width: 200 },
    // { field: 'tipoConta', headerName: 'Conta', width: 40 },
    // { field: 'banco', headerName: 'Banco', width: 60 },
    // { field: 'agencia', headerName: 'Agência', width: 80 },
    // { field: 'conta', headerName: 'Número', width: 100 },
  ];

  const healthCheck = async () => {
    try {
      setLoading(true);
      // token do ad
      const token = await getToken();

      const status = [];
      const resources = (
        await sendRequest(
          token,
          'https://backend.entrepay.com.br/btg-homolog/cm/webhook/subscription'
        )
      ).resources;

      const d = resources.map((item) => {
        return {
          id: uuid4(),
          entity: item.entity,
          endpoint: item.webhookUrl,
          status: item.blockedManyErrors,
        };
      });
      setData(d);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Inicial
  useEffect(() => {
    healthCheck();
  }, []);

  const sendRequest = async (aToken, aUrl) => {
    const params = {
      method: 'GET',
      url: aUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${aToken}`,
      },
    };
    const res = await axios(params);
    return res.data;
  };

  const handleUpdateWebhooks = async (e) => {
    try {
      setLoading(true);

      // Pega o token do login para passar na chamada
      const token = await getToken();

      const webhook_url = 'https://btgwebhooks-6fpbwigvja-rj.a.run.app';
      const webhook_secret = 'c610d94d-54f3-46c9-aa18-94f3a0c92a41'; // TODO: tirar o secret do código e colocar em lugar seguro

      const params = {
        method: 'PUT',
        url: aUrl,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          resources: [
            {
              entity: 'CreditReservationOperation',
              blockedManyErrors: true,
              qtdConsecutiveErrors: 0,
              webhookUrl: `${webhook_url}`,
              secret: `${webhook_secret}`,
              eventNames: [
                'CREATED',
                'PROCESSING',
                'CANCELLED',
                'COMPLETED',
                'INSUFFICIENT_BALANCE',
                'ERROR',
              ],
              isMtls: false,
            },
            {
              entity: 'SettlementService',
              blockedManyErrors: true,
              qtdConsecutiveErrors: 0,
              webhookUrl: `${webhook_url}`,
              secret: `${webhook_secret}`,
              eventNames: [
                'RECEIVABLE_DISCOUNTING_STARTED',
                'RECEIVABLE_DISCOUNTING_WAITING_BALANCE',
                'CONFIRMED',
                'DIVERGED',
                'ERROR',
              ],
              isMtls: false,
            },
            {
              entity: 'FinancialTransaction',
              blockedManyErrors: true,
              qtdConsecutiveErrors: 0,
              webhookUrl: `${webhook_url}`,
              secret: `${webhook_secret}`,
              eventNames: ['CONFIRMED', 'REVERTED', 'ERROR'],
              isMtls: false,
            },
          ],
        },
      };
      const res = await axios(params);

      await healthCheck();
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Hover loading={loading} />
      <DataTable rows={data} columns={columns} />

      <Button
        sx={{
          borderRadius: 3,
          width: '100%',
          textTransform: 'capitalize',
          marginTop: 'auto',
          height: '40px',
          width: '80px',
          color: 'primary.main',
          bgcolor: 'primary.light',
        }}
        variant="contained"
        onClick={(e) => handleUpdateWebhooks(e)}
      >
        Reestabelecer webhooks
      </Button>
    </Box>
  );
};
