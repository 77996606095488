import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ExitToApp } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import uuid4 from 'uuid4';
import { useAuthMethod, useAuthUser } from '@crema/utility/AuthHooks';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
const drawerWidth = 240;
const topbarHeight = 64;

export const SideBarMenu = ({ pages }) => {
  const { logout } = useAuthMethod();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useAuthUser();
  const [tema, setTema] = useState(false);

  const ICONSIZE = 24;

  const theme = useTheme();

  // Indica qual submenus estão abertos
  const [openedKeys, setOpenedKeys] = useState([]);

  // Qual menu está selecionado
  const [selectedKey, setSelectedKey] = useState('');

  // Array com os nomes e links do menu
  const [menu, setMenu] = React.useState({ key: null, children: [] });

  // Monta o menu lateral, de forma recursiva
  const getMenu = (page, parentPath = '') => {
    const m = {
      key: uuid4(), // para gerar chave única
      title: page.title,
      path: `${parentPath}/${page.path}`,
      icon: page.icon,
      children: [],
    };
    if (page.children && page.children.length > 0) {
      for (const c of page.children) {
        const result = getMenu(c, m.path);
        if (result) {
          m.children.push(result);
        }
      }
    }
    if (Boolean(page.noMenu) === false) {
      return m;
    } else {
      return null;
    }
  };

  let imagem = "";

  const validaUser = () => {
    const dominio = user.email.split('@')[1];
    if(dominio === 'bnb.gov.br'){
      setTema(true);
      imagem = "/assets/images/logo-with-name.png";
    }else{
      setTema(false);
      imagem = 'https://www.bnb.gov.br/o/bnb-dxp-theme/images/logo-bnb.svg';
    }
  };

  console.log("src da imagem: " + imagem);

  const estiloAppBar = {
    //backgroundColor: tema ? "#a6193c" : "#000536",
    backgroundColor: tema ? "#000536" : "#a6193c"
  };

  useEffect(() => {
    const m = {
      key: uuid4(),
      children: [],
    };
    for (const page of pages) {
      const result = getMenu(page);
      if (result !== null) {
        m.children.push(getMenu(page));
      }
    }
    setMenu(m);
    //validaUser();
  }, [pages]);

  function traverse(node, path = [], result = []) {
    if (!node.children.length) result.push(path.concat(node.key));
    for (const child of node.children)
      traverse(child, path.concat(node.key), result);
    return result;
  }

  const getParents = (aKey, aMenu) => {
    const lists = traverse(aMenu);
    console.log(lists);
    console.log(aKey);
    for (const l of lists) {
      if (l.includes(aKey)) {
        return l;
      }
    }
    return [];
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout();
  };

  const handleClick = (key) => {
    setSelectedKey(key);
  };

  const handleClickOpen = (key) => {
    // Se clicou sobre o que está aberto, fecha
    if (openedKeys.includes(key)) {
      const index = openedKeys.indexOf(key);
      const c = [...openedKeys];
      c.splice(index, 1);
      setOpenedKeys(c);
    } else {
      // abre e também
      // fecha os outros que estavam abertos
      const parents = getParents(key, menu);
      const filteredArray = [...openedKeys, key].filter((value) =>
        parents.includes(value)
      );
      setOpenedKeys(filteredArray);
    }
  };

  // quando tem children, chamo recursivo
  const getListElement = (item, level = 1) => {
    // se for um grupo, entao monta
    if (item.children && item.children.length > 0) {
      return (
        <Fragment key={item.key}>
          <ListItemButton
            key={item.key}
            onClick={() => handleClickOpen(item.key)}
            sx={{
              pl: level,
              background: openedKeys.includes(item.key)
                ? (theme) => theme.palette.primary.light
                : 'white',
            }}
          >
            {item.icon && (
              <ListItemIcon sx={{ fontSize: ICONSIZE }}>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={item.title} />
            {openedKeys.includes(item.key) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse
            in={openedKeys.includes(item.key)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.children.map((i, index) => {
                return getListElement(i, level + 1);
              })}
            </List>
          </Collapse>
        </Fragment>
      );
    }

    // Se é um item simples
    return (
      <ListItemButton
        key={item.key}
        onClick={(e) => handleClick(item.key)}
        selected={selectedKey === item.key}
        component={Link}
        to={item.path}
        sx={{ pl: level }}
      >
        {item.icon && (
          <ListItemIcon sx={{ fontSize: ICONSIZE }}>{item.icon}</ListItemIcon>
        )}
        <ListItemText primary={item.title} />
      </ListItemButton>
    );
  };

  // menu lateral
  const drawer = (
    <div>
      <Toolbar
        sx={{
          height: topbarHeight,
        }}
      />
      <Divider />
      <List>
        {menu.children.map((item, index) => {
          return getListElement(item);
        })}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Barra superior */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: topbarHeight,
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Grid
            container
            sx={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: 'none' }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: 'none', lg: 'block'},
              }}
            >
              <img src="/assets/images/logo-with-name.png" />
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: 'none', lg: 'block'},
              }}
            >
              <Typography variant="h6" noWrap component="div">
                {user.email}
              </Typography>
            </Grid>
            <Grid>
              <IconButton
                color="inherit"
                aria-label="logout"
                edge="end"
                onClick={handleLogout}
              >
                <ExitToApp />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Menu lateral  */}
      {/* <Box
        component="nav"
        sx={{ display: "none", width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}

      {/* Conteúdo da página */}
      <Box
        id="OUTLET"
        sx={{
          padding: 1,
          marginTop: 8,
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

SideBarMenu.propTypes = {
  pages: PropTypes.array,
};
