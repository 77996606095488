import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Fonts } from "shared/constants/AppEnums";
import { DataGrid, ptBR } from "@mui/x-data-grid";

const Preview = ({ data, onCancel, onSave }) => {
  const columns = [
    { field: "CNAE ", headerName: "CNAE" },
    { field: "MCC", headerName: "MCC" },
    { field: "NOME MCC", headerName: "NOME MCC", width: 200 },
    {
      field: "CLASSIFICAÇÃO DE RISCO - CARTÃO PRESENTE",
      headerName: "CLASSIFICAÇÃO DE RISCO - CARTÃO PRESENTE",
      width: 100,
    },
    {
      field: "CLASSIFICAÇÃO DE RISCO - VENDA DIGITADA / CARTÃO NÃO PRESENTE ",
      headerName: "CLASSIFICAÇÃO DE RISCO - VENDA DIGITADA / CARTÃO NÃO PRESENTE ",
      width: 200,
    },
    {
      field: "DESCRIÇÃO ",
      headerName: "DESCRIÇÃO",
      width: 200,
    },
    {
      field: "GRUPO ",
      headerName: "GRUPO",
      width: 250,
    },
  ];

  const onClickSave = () => {
    onSave(data.map(value => {
      return {
        mcc: value.MCC,
        riskRating: value["CLASSIFICAÇÃO DE RISCO - CARTÃO PRESENTE"],
        riskRatingDigitada: value["CLASSIFICAÇÃO DE RISCO - VENDA DIGITADA / CARTÃO NÃO PRESENTE "],
        mccName: value["NOME MCC"],
        mccDescription: value["DESCRIÇÃO"],
        mccGroup: value["GRUPO "],
        cnae: value["CNAE "],
      };
    }));
  }

  return (
    <Box>
      <Box sx={{ justifyContent: "end", display: "flex", mb: 5 }}>
        <Button
          onClick={onCancel}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Salvar
        </Button>
      </Box>
      <Box style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[30, 60, 100]}
          checkboxSelection={false}
          getRowId={(row) => row["CNAE "]}
        />
      </Box>
    </Box>
  );
};

Preview.defaultProps = {
  data: [],
};
Preview.propTypes = {
  data: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default Preview;
