import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState, useCallback, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Fonts } from 'shared/constants/AppEnums';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import ReactJson from 'react-json-view';
import Drawer from '@mui/material/Drawer';
import Switch from '@mui/material/Switch';
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Footer = styled('footer')({
  display: 'flex',
  justifyContent: 'end',
  padding: '30px',
});

const CreateRules = () => {
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState('');
  const [selectedParameterComparison, setSelectedParameterComparison] =
    useState('');
  const [condition, setCondition] = useState('');
  const [operation, setOperation] = useState('');
  const [comparisonOperation, setComparisonOperation] = useState('');
  const [value, setValue] = useState('');
  const [score, setScore] = useState(null);
  const [bureaus, setBureaus] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCritical, setIsCritical] = useState(false);
  const [isComparison, setIsComparison] = useState(false);
  const [apiSelected, setApiSelected] = useState('');
  const [description, setDescription] = useState('');
  const [clientType, setClientType] = useState('');
  const navigate = useNavigate();
  const handleClose = () => setIsOpen(false);

  const { id } = useParams();

  const { getToken } = useAuthMethod();


  const operations = React.useMemo(() => [
    { value: 'igual', label: 'Igual' },
    { value: 'igualMenor', label: 'Igual ou menor' },
    { value: 'igualMaior', label: 'Igual ou maior' },
    { value: 'comparar', label: 'Comparar valor com outra API' },
    { value: 'menor', label: 'Menor' },
    // { value: "naoContem", label: "Não contem todos dentro" },
  ]);

  const createRules = async () => {
    const token = await getToken();

    if (
      selectedParameter == '' ||
      condition == '' ||
      operation == '' ||
      value == '' ||
      score == '' ||
      description == ''
    ) {
      return toast.error('Preencha os campos para criar nova regra');
    }
    if (operation == 'compare' && selectedParameterComparison == '') {
      return toast.error('Preencha os campos para criar nova regra');
    }
    try {
      await request(process.env.REACT_APP_API_URL + '/rules','post', token, {
        apiBureaus: selectedParameter,
        parameterPath: condition,
        operation: comparisonOperation !== '' ? comparisonOperation : operation,
        value: value,
        score: score,
        apiBureausComparison: selectedParameterComparison,
        critical: isCritical,
        rulesSectionId: id,
        description,
        clientType,
      });
      listRules();
      toast.success('Regra criada com sucesso.');
    } catch (error) {
      console.error(error);
      toast.error('Erro ao criar regra');
      setLoading(false);
    }
  };

  const listRules = useCallback(async () => {
    const token = await getToken();

    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/rules/${id}`, 'get', token
      );
      const resultBureaus = await request(
        process.env.REACT_APP_API_URL + `/bureaus`,'get', token
      );
      setRules(result);
      setBureaus(resultBureaus);
    } catch (error) {
      toast.error('Erro ao buscar Regras');
    }
  }, []);

  React.useEffect(() => {
    listRules();
  }, [listRules]);

  const onClickDeleteRules = async (scoreId) => {
    const token = await getToken();

    try {
      await request(
        process.env.REACT_APP_API_URL + `/rules/${scoreId}`,'delete', token,
        {}
      );
      listRules();
      toast.success('Regra deletada com sucesso.');
    } catch (error) {
      console.error(error);
      toast.error('Erro ao deletar regra');
      setLoading(false);
    }
  };
  const onClickAddRules = () => {
    createRules();
  };

  const onChangeClientType = (event) => {
    setClientType(event.target.value);
  }

  const onChangeSelectedParameter = (event) => {
    setIsComparison(false);
    setSelectedParameter(event.target.value);
    setIsOpen(true);
    setApiSelected(bureaus.find((api) => event.target.value == api.id));
  };

  const onChangeSelectedParameterComparison = (event) => {
    setIsComparison(true);
    setSelectedParameterComparison(event.target.value);
    setIsOpen(true);
    setApiSelected(bureaus.find((api) => event.target.value == api.id));
  };

  const onChangeCondition = (event) => {
    setCondition(event.target.value);
  };

  const onChangeOperation = (event) => {
    setOperation(event.target.value);
  };

  const onChangeComparisonOperation = (event) => {
    setComparisonOperation(event.target.value);
  };

  const onChangeScore = (event) => {
    setScore(event.target.value);
  };

  const onChangeValue = (event) => {
    setValue(event.target.value);
  };

  const onClickCancel = () => {
    navigate(`/onboarding/risk/rules/precreate`);
  };

  const onSelectedJson = (select) => {
    console.log(select);
    let path;
    if (select.isKey) {
      path = select.namespace;
    } else {
      path = [...select.namespace, select.name];
    }
    const convertedPath = path
      .map((segment) => {
        // Check if the segment is a number
        const segmentNumber = Number(segment);
        if (!isNaN(segmentNumber)) {
          // If it's a number, wrap it with square brackets
          return `[*]`;
        } else {
          // If it's not a number, keep it as is
          return segment;
        }
      })
      .join('.')
      .replace(/\.\[/g, '[');
    if (isComparison) {
      setValue(convertedPath);
    } else {
      setCondition(convertedPath);
    }

    setIsOpen(false);
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: '100%',
            textAlign: 'center',
            padding: { xs: 8, lg: 12, xl: '48px 64px' },
            overflow: 'hidden',
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Criar Regras
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ justifyContent: 'end', display: 'flex' }}></Box>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={2}>
              Descricao
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Parametro
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="api">Api</InputLabel>
                <Select
                  labelId="api"
                  id="api"
                  value={selectedParameter}
                  onChange={onChangeSelectedParameter}
                >
                  {bureaus?.map((api) => {
                    return (
                      <MenuItem key={api?.id} value={api?.id}>
                        {api?.apiName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Condicao
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={condition}
                  disabled={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Operacao
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={operation}
                  onChange={onChangeOperation}
                >
                  {operations.map((v) => (
                    <MenuItem value={v.value} key={v.value}>
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {operation == 'comparar' && (
              <>
                <Grid item xs={2}>
                  Operação Comparação
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="comparisonOperation">
                      Operação Comparação
                    </InputLabel>
                    <Select
                      labelId="comparisonOperation"
                      id="comparisonOperation"
                      value={comparisonOperation}
                      onChange={onChangeComparisonOperation}
                    >
                      {operations
                        .filter((op) => op.value !== 'comparar')
                        .map((v) => (
                          <MenuItem value={v.value} key={v.value}>
                            {v.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  Api Comparação
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="apiComparacao">Api Comparação</InputLabel>
                    <Select
                      labelId="apiComparacao"
                      id="apiComparacao"
                      value={selectedParameterComparison}
                      onChange={onChangeSelectedParameterComparison}
                    >
                      {bureaus?.map((api) => {
                        return (
                          <MenuItem key={api?.id} value={api?.id}>
                            {api?.apiName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={2}>
              Value/Parametro
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={value}
                  onChange={onChangeValue}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Score
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={score}
                  onChange={onChangeScore}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              Critico
            </Grid>
            <Grid item xs={4}>
              <Switch onChange={(e) => setIsCritical(e.target.checked)} />
            </Grid>
            <Grid item xs={2}>
              Tipo
            </Grid>
            <Grid item xs={4}>
  <FormControl fullWidth size="small" required>
    <InputLabel id="clientType">
      Tipo
    </InputLabel>
    <Select
      labelId="clientType"
      id="clientType"
      value={clientType}
      onChange={onChangeClientType}
    >
      <MenuItem value="" disabled>
        Selecione uma opção
      </MenuItem>
      <MenuItem value="VAR" key="psp">
        VAR
      </MenuItem>
      <MenuItem value="PSP" key="var">
        PSP
      </MenuItem>
    </Select>
  </FormControl>
</Grid>
            <Grid item xs={12}>
              <Button
                sx={{ backgroundColor: 'navy', borderRadius: '10px' }}
                variant="contained"
                disabled={loading}
                onClick={onClickAddRules}
              >
                Adicionar
                {loading === true && <CircularProgress />}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 10 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#000536' }}>
                    <TableCell
                      align="left"
                      colSpan={12}
                      sx={{ color: '#ffffff' }}
                    >
                      Regras já existentes
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Descricao</TableCell>
                    <TableCell align="left">Api</TableCell>
                    <TableCell align="left">Parametro</TableCell>
                    <TableCell align="left">Operacao</TableCell>
                    <TableCell align="left">Valor</TableCell>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Pontuação</TableCell>
                    <TableCell align="left">Critico</TableCell>
                    <TableCell align="left">Ação</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rules?.map((rule) => (
                    <TableRow
                      key={rule?.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {rule?.description}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {rule?.apiBureaus?.apiName}
                      </TableCell>
                      <TableCell
                        sx={{ wordBreak: 'break-all', width: 200 }}
                        component="th"
                        scope="row"
                      >
                        {rule?.parameterPath}
                      </TableCell>

                      <TableCell align="left">
                        {operations.find((op) => op.value === rule.operation)
                          ?.label ?? ''}
                      </TableCell>
                      <TableCell align="left">{rule?.value}</TableCell>
                      <TableCell align="left">
                        {rule?.clientType}
                      </TableCell>
                      <TableCell align="left">{rule?.score}</TableCell>
                      <TableCell align="left">
                        {rule.critical ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          sx={{
                            backgroundColor: 'navy',
                            borderRadius: '10px',
                          }}
                          variant="contained"
                          disabled={loading}
                          onClick={() => onClickDeleteRules(rule.id)}
                        >
                          Deletar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Footer>
            <Button
              sx={{ backgroundColor: 'navy', borderRadius: '10px' }}
              variant="contained"
              disabled={loading}
              onClick={onClickCancel}
            >
              Voltar
              {loading === true && <CircularProgress />}
            </Button>
          </Footer>
        </Card>
        <div>
          <Drawer
            anchor={'right'}
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
              sx: { width: '90%' },
            }}
          >
            <Typography sx={{ mb: 5, mt: 5, ml: 5 }}>
              Clique para selecionar o valor
            </Typography>
            {apiSelected?.jsonResponse && (
              <ReactJson
                src={JSON.parse(apiSelected?.jsonResponse)}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
                onSelect={onSelectedJson}
              ></ReactJson>
            )}
          </Drawer>
        </div>
      </Box>
    </Fragment>
  );
};

export default CreateRules;
