import React, { createRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ObjectView } from './ObjectView';

export const Detail = ({ report, currentTopic }) => {
  const contentRef = createRef();
  const [contents, setContents] = useState('');

  const updateContents = function () {
    let t = {};
    for (const topic of Object.keys(report)) {
      if (currentTopic === topic) {
        if (report[currentTopic] === null) {
          t = {};
        } else {
          switch (typeof report[currentTopic]) {
            case 'null':
            case 'undefined':
              {
                t = {};
              }
              break;

            case 'object':
              {
                t = Object.assign(report[currentTopic], {});
              }
              break;

            default: {
              t = report[currentTopic];
            }
          }
        }
      }
    }
    setContents(t);
  };

  // chamado quando o report é alterado
  useEffect(() => {
    updateContents();
  }, [report, currentTopic]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
      }}
      ref={contentRef}
    >
      <>
        <Box isDetailView>
          <ObjectView detail={contents} />
        </Box>
      </>
    </Box>
  );
};
Detail.propTypes = {
  report: PropTypes.object,
  currentTopic: PropTypes.string.isRequired,
};
Detail.defaultProps = {
  report: {},
  currentTopic: '',
};
