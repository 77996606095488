import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { plain } from 'shared/utils/plain';
import { formatDocument } from 'shared/utils/formatDocument';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import uuid4 from 'uuid4';
import XLSX from 'xlsx';

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';
import Papa from "papaparse";

export const RegistroContratoCessao = () => {
  const [loading, setLoading] = useState(false);

  const [dataCSV, setDataCSV] = useState();

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [amount, setAmount] = useState(0);

  const [reservationDate, setReservationDate] = useState(dayjs());
  const handleChangeDate = (newValue) => {
    setReservationDate(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [selectedPaymentNetwork, setSelectedPaymentNetwork] = useState('');
  const handleChangePaymentNetwork = (event) => {
    setSelectedPaymentNetwork(event.target.value);
  };

  const [cardIssuers, setCardIssuers] = useState([]);
  const [selectedCardIssuer, setSelectedCardIssuer] = useState('');
  const handleChangeCardIssuer = (event) => {
    setSelectedCardIssuer(event.target.value);
  };

  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const handleChangeAssignee = (event) => {
    setSelectedAssignee(event.target.value);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
  
    Papa.parse(file, {
      complete: (result) => {
        // Create an array to store all arrays (rows)
        const arrayOfArrays = [];
  
        // Iterate through each row of the CSV data
        result.data.forEach((row) => {
          // Convert the row object to an array
          const rowArray = Object.values(row);
          // Push the array into the main array
          arrayOfArrays.push(rowArray);
        });
  
        // Now arrayOfArrays contains an array for each row of the CSV data
        setDataCSV(arrayOfArrays);
      },
      header: true, // Indica que a primeira linha é o cabeçalho
    });
  };

  const generateFile = () => {
    const rows = [
      {
        Bandeira: "Value1",
        Cessionario: "Value2",
        Data: "Value3",
        Emissor: "Value4",
        Valor: "Value5"
      }
    ];
    
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    /* fix headers */
    XLSX.utils.sheet_add_aoa(worksheet, [["Bandeira", "Cessionario", "Data", "Valor"]], { origin: "A1" });
    
    /* calculate column width */
    const max_width = Object.keys(rows[0]).reduce((w, key) => Math.max(w, key.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];
    
    /* create a CSV file and try to save it as Presidents.csv */
    XLSX.writeFile(workbook, "Example.csv", { bookType: "csv", compression: true });
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== 'AMEX';
  }

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks'
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees'
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      setAssignees(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores da bandeira selecionada
  const fetchCardcardIssuers = async () => {
    try {
      if (selectedPaymentNetwork.length === 0) {
        setCardIssuers([]);
        return;
      }

      setLoading(true);
      const result = await fetchData(
        'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
        {
          paymentNetwork: selectedPaymentNetwork,
        }
      );

      const d = result.map((item) => {
        return {
          value: item.cardIssuerId,
          label: item.name,
        };
      });
      setCardIssuers(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setCardIssuers([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: 'GET',
      url: aUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = '';
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + ' ';
      }
      throw msg;
    }
  };

  const handleRegister = async (event) => {
    try {
      setLoading(true);

      // token do ad
      const token = await getToken();

      const params = {
        method: 'POST',
        url: 'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservation',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          clientRequestId: uuid4(),
          amount: amount * 100,
          reservationDate: reservationDate.format('YYYY-MM-DD'),
          paymentNetwork: selectedPaymentNetwork,
          assigneeAccountId: selectedAssignee,
          cardIssuerId: selectedCardIssuer,
        },
      };
      const res = await axios(params);
      if (res.status === 200) {
        const id = res.data.body.pactualId;
        toast.success(`Contrato registrado com id ${id}`);
      } else {
        let msg = '';
        for (const e of res.errors) {
          console.log(e.message);
          msg += e.message + ' ';
        }
        throw msg;
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchArray = () => {
    setLoading(true);

    const params = {
      method: 'POST',
      url: 'https://southamerica-east1-entrepayments-system-prd.cloudfunctions.net/btg-planilhaCessionario/teste',
      data: dataCSV
    };

    return axios(params)
      .then((res) => {
        console.log(res);

        const dataArray = res.data;
        const status400Indexes = [];

        dataArray.forEach((item, index) => {
          if (item.status === 400) {
            status400Indexes.push(index);
          }
        });

        if (status400Indexes.length > 0) {
          const indexesMessage = status400Indexes.map((index) => `${index + 1}`).join(', ');
          toast.error(`Erro ao inserir as linhas: ${indexesMessage}`);
        } else {
          toast.success('Dados registrados com sucesso!');
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error('Erro ao realizar a chamada ao backend');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTaxId = async (assigneeAccountId) => {
    const makeApiRequest = async () => {
      const url = `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignee/${assigneeAccountId}`;
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer 6NhpTn46L4Yi03Ou5J8qOH3VHGLc`,
            'Content-Type': 'application/json'
          }
        });
        const taxId = response.data.body.body.taxId;
        const issuerName = response.data.body.body.name
        return {taxId, issuerName};
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    };
    return makeApiRequest();
  };

  const getCardIssuerId = async (issuerName) => {
    // Buscar o objeto no array 'd' que tem o 'label' igual ao 'issuerName'
    const selectedIssuer = cardIssuers.find((item) => item.name === issuerName);

    if (selectedIssuer) {
      console.log('Objeto encontrado:', selectedIssuer);
      // Faça o que for necessário com o objeto encontrado
    } else {
      console.log('Objeto não encontrado.');
    }
  };

  /**
   * useEffect
   */
  // Inicial
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardcardIssuers();
  }, [selectedPaymentNetwork]);

  useEffect(() => {
    console.log(amount);
  }, [amount]);

  useEffect(() => {
    if(dataCSV) {
      console.log(dataCSV)
      fetchArray()
    }
  }, [dataCSV]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        padding: 2,
        width: '100%',
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'left',
          padding: 2,
          gap: 2,
          width: '100%',
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Bandeira</InputLabel>
          <Select
            required
            value={selectedPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: '100%',
            }}
          >
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Emissor</InputLabel>
          <Select
            required
            value={selectedCardIssuer}
            label="Emissor"
            onChange={(e) => handleChangeCardIssuer(e)}
            sx={{
              width: '100%',
            }}
          >
            {cardIssuers.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel>Cessionário</InputLabel>
          <Select
            required
            value={selectedAssignee}
            label="Emissor"
            onChange={(e) => handleChangeAssignee(e)}
            sx={{
              width: '100%',
            }}
          >
            {assignees.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            width: 0,
            flexBasis: '100%',
          }}
        />

        <FormControl sx={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              required
              inputProps={{ style: { height: '0.1em', width: 220 } }}
              label={'Data'}
              value={reservationDate}
              onChange={handleChangeDate}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ flexGrow: 1 }}>
          <NumericFormat
            required
            label="Valor"
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale
            value={amount}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix="R$ "
            sx={{
              width: '100%',
            }}
            onValueChange={(values, sourceInfo) => {
              setAmount(values.floatValue);
            }}
          />
        </FormControl>

        {/* <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            label="Observação"
            variant="outlined"
            sx={{
              width: '100%',
            }}
          />
        </FormControl> */}

        <FormControl sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            onClick={handleRegister}
            sx={{
              m: 1,
              width: '100%',
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
          >
            Registrar
          </Button>
        </FormControl>
      </Box>

      <Box
        sx={{
          borderTop: 'solid',
          width: '100%',
        }}
      >
        <FormControl>
          <input
          accept=".csv"
          onChange={handleUpload}
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
        />
        <label htmlFor="raised-button-file">
          <Button 
          variant="raised" 
          component="span" 
          sx={{
            m: 1,
            width: '100%',
            color: 'primary.contrastText',
            bgcolor: 'primary.main',
            '&:hover': { backgroundColor: 'primary.light' },
          }}
          >
          Upload de arquivo para registro em lote
          </Button>
        </label> 
        </FormControl>
        <FormControl>
          <Button 
            onClick={generateFile}
            sx={{
              m: 1,
              width: '100%',
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
              marginLeft: '10%',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
            >
            Download Exemplo
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};