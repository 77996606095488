import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import React, { useState, useCallback } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Fonts } from "shared/constants/AppEnums";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams,useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import { Hover } from "elements/hover";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import { TextareaAutosize, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FileUploader } from "react-drag-drop-files";
import CardContent from "@mui/material/CardContent";
import { useAuthUser } from '@crema/utility/AuthHooks';
import FormikSelect from "elements/inputs/FormikSelect";
import { Form, Formik, Field } from "formik";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormikPatternFormat from 'elements/inputs/FormikPatternFormat';
import InputMask from 'react-input-mask';
import { te } from "date-fns/locale";




const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const currencyLocale = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});
const TypographyStyled = styled(Typography)({
  marginTop: "20px",
  textAlign: "left",
  marginBottom: "5px",
});
const GridStyled = styled(Grid)({
  marginRight: "150px",
});

const StandardPricing = () => {
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState();
  const [bank, setBank] = useState();
  const [qtTechnology, setQtTechnology] = useState();
  const [account, setAccount] = useState();
  const [accountHolder, setAccountHolder] = useState();
  const [cpfOrCnpjAccountHolder, setcpfOrCnpjAccountHolder] = useState();
  const [pricingPartner, setPricingPartner] = useState();
  const [resultAnalysis, setResultAnalysis] = useState();
  const { user } = useAuthUser();
  const [tema, setTema] = useState(false);
  const [technology, setTechnology] = useState("0");
  const [sendtechnology, setSendtechnology] = useState("0");
  const [sections, setSections] = useState([]);
  const [celular, setCelular] = useState();
  const [ecommerce, setEcommerce] = useState();
  const [telefone, setTelefone] = useState();
  const [email, setEmail] = useState();
  const [mcc, setMcc] = useState("");
  const [listMcc, setListMcc] = useState([]);
  const [proposal, setProposal] = useState();
  const [cargo, setCargo] = useState();
  const [name, setName] = useState();
  const [technicalManager, setTechnicalManager] = useState(false);
  const [fezChamada, setFezChamada] = useState(false);
  const [files, setFiles] = useState({});
  const navigate = useNavigate();
  let { id } = useParams();

  const { getToken } = useAuthMethod();

  const CardStyled = styled(Card)({
    width: "70%",
    // height: "50%",
    padding: 0,
    borderStyle: "dotted",
    borderColor: "#2C90D8",
    marginTop: 3,
  });

  const getProposal = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`, 'get', token
      );
        setProposal(result);
        if(result?.dataCnpj?.cnpj.length == 11){
          getMcc();
        }else{
          listProposal();
        }    
        validaUser();
      } catch (error) {
      console.log("ERRO: ",error)
      toast.error("Erro ao listar dados");
    }
  try {
    const resultTechnology = await request( process.env.REACT_APP_API_URL + `/technology/ativo`, 'get', token);
    setSections(resultTechnology?.map(technology => {
      return {
        id: technology?.id,
        tecnoligiaCod: technology?.tecnologiaCod,
        acqueirerCod: technology?.acqueirerCod,
        name: technology?.name,
        description: technology?.description,
        status: technology?.status,
        technicalManager: technology?.technicalManager,
      }
    }));
  }catch(error){
    console.log("ERRO: ", error)
    toast.error("Erro ao listar as tecnologias");
  }
  });

  const getMcc = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/api/getmcc`, 'get', token
      );
        console.log("Get mccs: ", result);
        setListMcc(result);
    } catch (error) {
      console.log("ERRO: ",error)
      toast.error("Erro ao listar dados");
    }
  });

  const listProposal = useCallback(async () => {
    const token = await getToken();
    try {
        let objEnviar = {id: id, mcc: mcc}
        console.log("Enviar: ", objEnviar)
        const [
          result,
        ] = await Promise.all([
          request(process.env.REACT_APP_API_URL + `/accreditation/api/price/`,'post', token, objEnviar,{
            headers: {token: process.env.REACT_APP_CRED_TOKEN},
          }),
        ]);
        console.log("result PRICING: ", result)
        let respTeste = result;
        setPricingPartner(respTeste);
    } catch (error) {
      console.log("ERRO: ",error)
      toast.error("Erro ao listar dados");
    }

  try {
    const resultTechnology = await request( process.env.REACT_APP_API_URL + `/technology/ativo`, 'get', token);
    setSections(resultTechnology?.map(technology => {
      return {
        id: technology?.id,
        tecnoligiaCod: technology?.tecnologiaCod,
        acqueirerCod: technology?.acqueirerCod,
        name: technology?.name,
        description: technology?.description,
        status: technology?.status,
        technicalManager: technology?.technicalManager,
      }
    }));
  }catch(error){
    console.log("ERRO: ", error)
    toast.error("Erro ao listar as tecnologias");
  }
}, []);

const listProposalPF = useCallback(async (doc) => {
  const token = await getToken();

  try {
      let objEnviar = {id: id, mcc: doc}
      console.log("Enviar: ", objEnviar)
      const [
        result,
      ] = await Promise.all([
        request(process.env.REACT_APP_API_URL + `/accreditation/api/price/`,'post', token, objEnviar,{
          headers: {token: process.env.REACT_APP_CRED_TOKEN},
        }),
      ]);
      console.log("result PRICING: ", result)
      let respTeste = result;
      setPricingPartner(respTeste);
  } catch (error) {
    console.log("ERRO: ",error)
    toast.error("Erro ao listar dados");
  }
}, []);



  function encodeObjectToBase64(obj) {
    const jsonString = JSON.stringify(obj);
    const base64String = btoa(jsonString);
    return base64String;
  }

  const ecommerceValidation = (value) => {
    setEcommerce(value);
  };

  const cargoValidation = (value) => {
    setCargo(value);
  };

  const emailValidation = (value) => {
    setEmail(value);
  };

  const nameValidation = (value) => {
    setName(value);
  };

  const telefoneValidation = (value) => {
    const re = /^[0-9]+$/;
    if(re.test(value)){
      setTelefone(value);
    }
  };

  const celularValidation = (value) => {
    const re = /^[0-9]+$/;
    if(re.test(value)){
      setCelular(value);
    }
  };

  const handleBankChange = (value) => {
    const regex = /^[0-9]{1,3}$/; // Expressão regular para validar números de 0 a 999
   
    if (regex.test(value) || value === "") {
      setBank(value);
    }
  };

  const handlesetQtTechnologyChange = (value) => {
    const regex = /^[0-9]{1,3}$/; // Expressão regular para validar números de 0 a 999
   
    if (regex.test(value) || value === "") {
      setQtTechnology(value);
    }
  };

  const handleBankChangeAgencia = (value) => {
    const regex = /^[0-9]{1,5}$/; 
   
    if (regex.test(value) || value === "") {
      setBranch(value);
    }
  };

  const handleBankChangeContaCorrente = (value) => {
    const regex = /^[0-9]{1,13}$/;  
   
    if (regex.test(value) || value === "") {
      setAccount(value);
    }
  };

  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    console.log(file);
    temp[name] = file;
    setFiles(temp);
    console.log(files);
  };
  
  const enviaEmailContrato = async () => {

    const token = await getToken();

    let dadosCadastrais = JSON.parse(resultAnalysis.bigData.bigData.bigData.dadosCadastrais);

    let objDadosAceite = {
      id: id,
      cnpj: resultAnalysis.dataCnpj.cnpj,
      uuid: resultAnalysis.acceptTerms.uuid,
      precoBandeira: pricingPartner.departamento[0].precoBandeira,
      token: token
    }

    let objEnviar = {
      email: resultAnalysis.email,
      nome: resultAnalysis.clientName,
      razaoSocial: dadosCadastrais.Result[0].BasicData.OfficialName,
      cnpj: resultAnalysis.dataCnpj.cnpj,
      contato: "Para nos contatar, envie email para: suporte@entrepay.com.br",
      domicilioBancario: {
        banco: !!resultAnalysis.legalForm && !!resultAnalysis.legalForm.bank ? resultAnalysis.legalForm.bank : bank,
        agencia: !!resultAnalysis.legalForm && !!resultAnalysis.legalForm.branch ? resultAnalysis.legalForm.branch : branch,
        documento: !!resultAnalysis.legalForm && !!resultAnalysis.legalForm.cpfOrCnpjAccountHolder ? resultAnalysis.legalForm.cpfOrCnpjAccountHolder : cpfOrCnpjAccountHolder,
        conta: !!resultAnalysis.legalForm && !!resultAnalysis.legalForm.account ? resultAnalysis.legalForm.account : account,
        titular: !!resultAnalysis.legalForm && !!resultAnalysis.legalForm.accountHolder ? resultAnalysis.legalForm.accountHolder : accountHolder
      },
      precoBandeira: pricingPartner.departamento[0].precoBandeira,
      urlaceite: process.env.REACT_APP_REDIRECT_URI + `/acceptContractTerms/?a=` + encodeObjectToBase64(objDadosAceite),
    }

    console.log("Enviar email: ", objEnviar);


    const response = await request(process.env.REACT_APP_API_URL + `/accreditation/email`,'post', token, objEnviar);
    console.log("Resposta: ", response);

  }

  const saveLegalForm = async () => {
    setLoading(true);
    const token = await getToken();
    let isTrue = false;
    let faceMatch = false;

    console.log("fezChamada: " + fezChamada);
    console.log("QUANTIDADE TOTAL: " + files.size)
    console.log(files)
    if ((files.img1 && files.img2 && proposal?.dataCnpj?.cnpj.length == 11) || (proposal?.dataCnpj?.cnpj.length == 14)){
      if(proposal?.dataCnpj?.cnpj.length == 11){
        // Consulta Face Match
        try{
          const jsonBody = { cpf: proposal?.dataCnpj?.cnpj, nmUsuario: user.email, type: "CREDENCIA" };
          const form = new FormData();
          const jsonBlob = new Blob([JSON.stringify(jsonBody)], {
            type: 'application/json'
          });
          // form.append('json', jsonBlob);
          form.append('json', jsonBlob);
          form.append('file', files?.img1[0]);
          form.append('file', files?.img2[0]);

          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          };

          /*const response = await axios.post(
            process.env.REACT_APP_API_URL + `/api/faceMatch`,
            form,
            config
          );*/
        
          faceMatch = true;
        }catch(error) {
          toast.error("Erro ao consultar face match");
          console.error(error);
        }
      }
      if((proposal?.dataCnpj?.cnpj.length == 11 && fezChamada && faceMatch) || (proposal?.dataCnpj?.cnpj.length == 14 && !fezChamada && !faceMatch)){
        if((qtTechnology > 0 && qtTechnology != null) && (technology != null && technology != '')){
          try{
            const response = await request(
              process.env.REACT_APP_API_URL + `/analysis/technology`,'post', token,
              {
                id: id,
                technology: sendtechnology,
                qtTechnology: qtTechnology,
              },
            );
            isTrue = true;
          }catch(error) {
            toast.error("Erro ao cadastrar a tecnologia");
            console.error(error);
          }
          if(((name != "" && name != null) && (email != "" && email != null)
          && (cargo != "" && cargo != null) && (telefone != "" && telefone != null)
          && (celular != "" && celular != null) && technicalManager)){

            //cadastra responsavel técnico
            try{
              const responseMenager = await request(
                process.env.REACT_APP_API_URL + `/manager/register`,'post', token,
                {
                  id: id,
                  name: name,
                  email: email,
                  cargo: cargo,
                  telefone: telefone,
                  celular: celular,
                  ecommerce: ecommerce,
                },
              );
              isTrue = true;
            }catch(error) {
              toast.error("Erro ao salvar o responsavel técnico.");
              console.error(error);
            }
          }else if(technicalManager){
            toast.error("Por favor, preencha os dados do responsavel técnivo");
          }

            if(!!branch && !!bank && !!account && !!accountHolder && !!cpfOrCnpjAccountHolder && isTrue && !!technology && !!qtTechnology){

              const response = await request(
                process.env.REACT_APP_API_URL + `/legalform/${id}`,'post', token,
                {
                  branch: branch,
                  bank: bank,
                  account: account,
                  accountHolder: accountHolder,
                  cpfOrCnpjAccountHolder: cpfOrCnpjAccountHolder,
                },
              );
              console.log("Resposta: ", response);
              if(mcc){
                return navigate(`/onboarding/risk/analysis/waiting/${id}?mcc=${mcc}`);
              }else{
                return navigate(`/onboarding/risk/analysis/waiting/${id}`);
              }

            }else{
                alert("Voce precisa preencher todos os campos dos dados bancários e os dados de tecnologia");
            }
        }else{
          toast.error("Por favor, preencha todos os campodo da tecnologia!");
        }
      }else{
        if(proposal?.dataCnpj?.cnpj.length == 11 && !faceMatch){
          toast.error("Erro na chamada Face Match");
        }else{
          toast.error("Selecione um mcc");
        }
      }
    }else{
      toast.error("Preencha os campo de upload")
    }
    setLoading(false);
  }

  const onChangeSelectedSection = (event) => {

    const selectedSectionId = event.target.value;
    const selectedSection = sections.find((section) => section.id === selectedSectionId);

    if(event.target.value != null && event.target.value != ""){
      setTechnology(event.target.value);
      setSendtechnology(selectedSection)
      setTechnicalManager(selectedSection.technicalManager);
    }
  };

  const onChangeSelectedSectionMcc = (event) => {

    const selectedSectionId = event.target.value;
    const selectedSection = listMcc.find((section) => section.id === selectedSectionId);

    if(event.target.value != null && event.target.value != ""){
      console.log("selectedSectionId: " + selectedSectionId);
      setMcc(selectedSectionId);
      setFezChamada(true);
      console.log("MCC: " + mcc);
      listProposalPF(selectedSectionId);
    }
  };

  const validaUser = () => {
    const dominio = user.email.split('@')[1];
    console.log("dominio do email: " + dominio);

    if(dominio === 'bnb.gov.br'){
      setTema(true);
    }else{
      setTema(false);
    }
  };

  const estiloAppBar = {
    backgroundColor: tema ? "#a6193c" : "#000536",
    //backgroundColor: tema ? "#000536" : "#a6193c"
  };

  React.useEffect(() => {
    getProposal();
  }, []);

  const TabelaPrecos = ({ dados }) => {

    return (
      <div>
        {dados.map((item, index) => (
          <table
            key={item.bandeira}
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              marginBottom: '20px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="2"
                  style={{
                    padding: '10px',
                    backgroundColor: '#f2f2f2',
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  {item.bandeira}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                    width: '50%',
                  }}
                >
                  Débito
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.debito}</td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                    width: '50%',
                  }}
                >
                  Crédito
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.credito}</td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                    width: '50%',
                  }}
                >
                  Parcelado 2x a 6x
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.parcelado2x6}</td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                    width: '50%',
                  }}
                >
                  Parcelado 7x a 12x
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.parcelado7x12}</td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ccc',
                    width: '50%',
                  }}
                >
                  Parcelado Emissor
                </td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.parceladoEmissor}</td>
              </tr>
              {item.precificacao && (
                <tr>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      width: '50%',
                    }}
                  >
                    Precificação
                  </td>
                  <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{item.precificacao}</td>
                </tr>
              )}
            </tbody>
          </table>
        ))}
      </div>
    );
  };
  
    return (
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          initialValues={{
            technologyId: ""
          }}
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            style={estiloAppBar} // cor alterada dependendo do domínio do usuário
            sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  fontWeight: Fonts.BOLD,
                  fontSize: 18,
                }}
              >
                Precificação de Departamento
              </Typography>
            </Toolbar>
          </AppBar>

          {proposal?.dataCnpj?.cnpj.length == 11 && (
              <Box sx={{ display: 'block' }}>
                <Grid style={{width: "100%", margin: "22px 0px 22px"}}>
                    <Typography>Mcc</Typography>
                    <Select style={{width: "100%"}}
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      value={mcc}
                      onChange={onChangeSelectedSectionMcc}
                    >
                      {listMcc?.map((section) => {
                        return (
                          <MenuItem key={section?.mcc} value={section?.mcc}>
                            {section?.mcc + " - " + section?.descricao}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
              </Box> 
            )
          }
          { !!pricingPartner && !!pricingPartner.departamento &&
              <TabelaPrecos dados={ pricingPartner.departamento[0].precoBandeira} />
          }
          <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
          
          
            <AppBar
            position="static"
            style={estiloAppBar} // cor alterada dependendo do domínio do usuário
            sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%",marginTop: "1rem" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  fontWeight: Fonts.BOLD,
                  fontSize: 18,
                }}
              >
                Dados Bancários
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid style={{width: "100%", display:"flex"}}>
            <Grid style={{width: "45%"}}>
            <Typography>Código do Banco</Typography>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="bank"
                    value={bank}
                    onChange={(e) => handleBankChange(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
            </Grid>
            <Grid style={{width: "45%"}}>
                <Typography>CPF/CNPJ</Typography>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="cpfOrCnpjAccountHolder"
                    value={cpfOrCnpjAccountHolder}
                    onChange={(e) => setcpfOrCnpjAccountHolder(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
            </Grid>
          </Grid>

          <Grid style={{width: "100%", display:"flex"}}>
            <Grid style={{width: "45%"}}>
                <Typography>Conta</Typography>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="account"
                    value={account}
                    onChange={(e) => handleBankChangeContaCorrente(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              
            </Grid>
            <Grid style={{width: "45%"}}>
                <Typography>Agência</Typography>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="branch"
                    value={branch}
                    onChange={(e) => handleBankChangeAgencia(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
            </Grid>
          </Grid>
          <Grid style={{width: "100%", display:"flex"}}>
            <Grid style={{width: "90%"}}>
            <Typography>Titular da Conta</Typography>
                <FormControl fullWidth size="small">
                  <TextField
                    size="small"
                    id="accountHolder"
                    value={accountHolder}
                    onChange={(e) => setAccountHolder(e.target.value)}
                    variant="outlined"
                  />
                </FormControl>
            </Grid>
          </Grid>

          <AppBar
            position="static"
            style={estiloAppBar} // cor alterada dependendo do domínio do usuário
            sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%",marginTop: "1rem" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  fontWeight: Fonts.BOLD,
                  fontSize: 18,
                }}
              >
                Tecnologia
                </Typography>
            </Toolbar>
          </AppBar>
          <Grid
          sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%" }}
          >
              <FormControl fullWidth size="small">
                <Grid style={{width: "100%", display:"flex"}}>
                  <Grid style={{width: "45%"}}>
                    <Typography>Tecnologia</Typography>
                    <Select style={{width: "100%"}}
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      value={technology}
                      onChange={onChangeSelectedSection}
                    >
                      {sections?.map((section) => {
                        return (
                          <MenuItem key={section.id} value={section.id}>
                            {section.tecnoligiaCod + " - " + section.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid style={{width: "45%"}}>
                    <Typography>Quantidade de tecnologia</Typography>
                    <FormControl fullWidth size="small">
                      <TextField
                        size="small"
                        id="qtTechnology"
                        value={qtTechnology}
                        onChange={(e) => handlesetQtTechnologyChange(e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
              {technicalManager && (
                <Box sx={{ display: 'block' }}>

                  {/* Seu conteúdo da box aqui */}

                  <AppBar
                    position="static"
                    style={estiloAppBar} // cor alterada dependendo do domínio do usuário
                    sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%",marginTop: "1rem" }}
                  >
                    <Toolbar>
                      <Typography
                        sx={{
                          fontWeight: Fonts.BOLD,
                          fontSize: 18,
                        }}
                      >
                        Dados do responsavel técnico
                      </Typography>
                    </Toolbar>
                  </AppBar>

                    <Grid style={{width: "100%", display:"flex"}}>
                    <Grid style={{width: "45%"}}>
                    <Typography>Nome completo</Typography>
                        <FormControl fullWidth size="small">
                          <TextField
                            required
                            size="small"
                            id="name"
                            value={name}
                            onChange={(e) => nameValidation(e.target.value)}
                            variant="outlined"
                          />
                        </FormControl>
                    </Grid>
                    <Grid style={{width: "45%"}}>
                        <Typography>Cargo</Typography>
                        <FormControl fullWidth size="small">
                          <TextField
                            required
                            size="small"
                            id="cargo"
                            value={cargo}
                            onChange={(e) => cargoValidation(e.target.value)}
                            variant="outlined"
                          />
                        </FormControl>
                    </Grid>
                  </Grid>

                  <Grid style={{width: "100%", display:"flex"}}>
                    <Grid style={{width: "45%"}}>
                        <Typography>Email</Typography>
                        <FormControl fullWidth size="small">
                          <TextField
                            required
                            size="small"
                            id="email"
                            value={email}
                            onChange={(e) => emailValidation(e.target.value)}
                            variant="outlined"
                          />
                        </FormControl>
                      
                    </Grid>
                    <Grid style={{width: "45%"}}>
                        <Typography>Url e-commerce</Typography>
                        <FormControl fullWidth size="small">
                          <TextField
                            size="small"
                            id="ecommerce"
                            value={ecommerce}
                            onChange={(e) => ecommerceValidation(e.target.value)}
                            variant="outlined"
                          />
                        </FormControl>
                    </Grid>
                  </Grid>
                  <Grid style={{width: "100%", display:"flex"}}>
                    <Grid style={{width: "45%"}}>
                    <Typography>Telefone</Typography>
                        <FormControl fullWidth size="small">
                        <TextField
                            required
                            size="small"
                            id="telefone"
                            value={telefone}
                            onChange={(e) => telefoneValidation(e.target.value)}
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                          />
                        </FormControl>
                    </Grid>
                    <Grid style={{width: "45%"}}>
                    <Typography>Celular</Typography>
                        <FormControl fullWidth size="small">
                        <TextField
                            required
                            size="small"
                            id="celular"
                            value={celular}
                            onChange={(e) => celularValidation(e.target.value)}
                            variant="outlined"
                            inputProps={{ maxLength: 15 }}
                          />
                        </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>


            {proposal?.dataCnpj?.cnpj.length == 11 && (
            <AppBar
            position="static"
            style={estiloAppBar} // cor alterada dependendo do domínio do usuário
            sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%",marginTop: "1rem" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  fontWeight: Fonts.BOLD,
                  fontSize: 18,
                }}
              >
                Upload
              </Typography>
            </Toolbar>
          </AppBar>)}
          {proposal?.dataCnpj?.cnpj.length == 11 && (
          <Grid
          sx={{ mb: 3, /*backgroundColor: "#000536",*/ width: "100%" }}
          >
              <FormControl fullWidth size="small">
                <Grid style={{width: "100%", display:"flex"}}>
                  <Grid style={{width: "45%"}}>
                    <Typography>Imagem 1</Typography>
                    <CardStyled style={{width: "95%"}}>
                      <CardContent>
                        <FileUploader
                          handleChange={(file) =>
                          handleUploadChange("img1", file)
                          }
                          name="file"
                          multiple={true}
                          // types={fileTypes}
                          children={
                          <div
                            style={{
                            textAlign: "center",
                            color: "#2C90D8",
                            padding: 0,
                            }}
                          >
                          {files?.img1?.length > 0 ? (
                            Object.keys(files?.img1).map((v) => {
                            const uploadedFile = files?.img1[v];
                            return <p key={v}>{uploadedFile.name}</p>;
                          })
                          ) : (
                          <p>
                            <CloudUploadOutlinedIcon /> Arraste e solte o
                                        arquivo preenchido aqui
                          </p>
                          )}
                          </div>
                        }
                        />
                      </CardContent>
                    </CardStyled>
                  </Grid>
                  <Grid style={{width: "45%"}}>
                    <Typography>Imagem 2</Typography>
                    <CardStyled  style={{width: "95%"}}>
                      <CardContent>
                        <FileUploader
                          handleChange={(file) =>
                          handleUploadChange("img2", file)
                          }
                          name="file"
                          multiple={true}
                          // types={fileTypes}
                          children={
                          <div
                            style={{
                            textAlign: "center",
                            color: "#2C90D8",
                            padding: 0,
                            }}
                          >
                          {files?.img2?.length > 0 ? (
                            Object.keys(files?.img2).map((v) => {
                            const uploadedFile = files?.img2[v];
                            return <p key={v}>{uploadedFile.name}</p>;
                          })
                          ) : (
                          <p>
                            <CloudUploadOutlinedIcon /> Arraste e solte o
                                        arquivo preenchido aqui
                          </p>
                          )}
                          </div>
                        }
                        />
                      </CardContent>
                    </CardStyled>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>)}
          </Grid>
          <Footer>
            <Grid
              container
              
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  style={estiloAppBar} // cor alterada dependendo do domínio do usuário
                  sx={{ /*backgroundColor: "navy",*/ borderRadius: "10px" }}
                  variant="contained"
                  disabled={loading}
                  onClick={saveLegalForm}
                >
                  Salvar e calcular risco
                </Button>
              </Grid>
            </Grid>
          </Footer>
        </Card>
        <Hover loading={loading} />
      </Box>
    );

};

export default StandardPricing;
