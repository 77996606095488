import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState, useCallback, Fragment } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Fonts } from "shared/constants/AppEnums";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import FormControl from "@mui/material/FormControl";
import { useAuthMethod } from "hooks/AuthHooks";
import request from "shared/utils/request";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextareaAutosize, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FileUploader } from "react-drag-drop-files";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";


const CustomTableCell = styled(TableCell)({
  paddingTop: "4px", // Adjust the top padding as needed
  paddingBottom: "4px", // Adjust the bottom padding as needed
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "navy",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

function calculateTotals(forecast, mcc, forecastGeneral) {
  let sumTpv = 0;
  let cardPresent = 0;
  for (const find of forecast) {
    const found = mcc.find(
      (element) => element.mcc == String(find.mcc)?.padStart(4, "0")
    );
    if (!found) continue;

    sumTpv = sumTpv + find.mccTotalTpv;
    let guarantee = find?.mccTotalTpv * found?.percentageGuarantee;
    cardPresent = cardPresent + guarantee;
  }
  const monthly = sumTpv / 12;
  const cardPresentPercent =
    cardPresent * (forecastGeneral?.performanceWithCard / 100);
  const cardNotPresent = cardPresent * 5;
  const cardNotPresentPercent =
    cardNotPresent * (forecastGeneral?.performanceWithoutCard / 100);

  const valueGuarantee = cardPresentPercent + cardPresent;
  const reserve50 = monthly / 2;
  const reserve35 = monthly * 0.35;
  const reserve10 = monthly * 0.1;
  const reserve5 = monthly * 0.05;

  return {
    monthTotal: monthly,
    cardPresentPercent,
    cardNotPresentPercent,
    cardNotPresent,
    valueGuarantee,
    reserve50,
    reserve35,
    reserve10,
    reserve5,
    sumTpv,
    cardPresent,
  };
}

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const Guarantee = () => {
  const [forecast, setForecast] = useState([]);
  const [forecastGeneral, setForecastGeneral] = useState([]);
  const [mcc, setMcc] = useState();
  const [analysis, setAnalysis] = useState();
  const [guarantee, setGuarantee] = useState([]);
  const [totals, setTotals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option1, setOption1] = useState();
  const [option2, setOption2] = useState();
  const [option3, setOption3] = useState();
  const [description, setDescription] = useState();
  const [special, setSpecial] = useState("yes");
  const [files, setFiles] = useState({});

  const { getToken } = useAuthMethod();

  let { id } = useParams();
  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    console.log(file);
    temp[name] = file;
    setFiles(temp);
  };

  // vai buscar na api a lista das análises de pricing
  const getDataForecast = useCallback(async function () {
    const token = await getToken();

    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/mcc`,
        "get",
        token
      );

      const resultForecast = await request(
        process.env.REACT_APP_API_URL + `/forecast/${id}`,
        "get",
        token
      );

      const resultForecastGeneral = await request(
        process.env.REACT_APP_API_URL + `/forecastgeneraloperation/${id}`,
        "get",
        token
      );

      const resultAnalysis = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "get",
        token
      );
      const resultProposal = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${id}`,
        "get",
        token
      );

      setMcc(result);
      setForecast(resultForecast);
      setSpecial(resultProposal[0].analysis.specialConditionRisk ?? "yes");
      setDescription(resultProposal[0].analysis.specialDescriptionRisk);
      setForecastGeneral(resultForecastGeneral);
      setAnalysis(resultAnalysis);
      setOption1(resultAnalysis?.guarantee?.guaranteeOption1);
      setOption2(resultAnalysis?.guarantee?.guaranteeOption2);
      setOption3(resultAnalysis?.guarantee?.guaranteeOption3);
      setTotals(calculateTotals(resultForecast, result, resultForecastGeneral));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDataForecast();
  }, [getDataForecast]);

  const onClickSendProposal = async () => {
    const token = await getToken();

    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + `/guarantee/${id}`,
        "post",
        token,
        {
          guaranteeOption1: option1,
          guaranteeOption2: option2,
          guaranteeOption3: option3,
        }
      );

      const result = await request(
        process.env.REACT_APP_API_URL + `/sendproposal/${id}`,
        "post",
        token
      );

      toast.success("Enviado com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar proposta");
    } finally {
      setLoading(false);
    }
  };

  const onClickSave = async () => {
    const token = await getToken();

    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + `/guarantee/${id}`,
        "post",
        token,
        {
          guaranteeOption1: option1,
          guaranteeOption2: option2,
          guaranteeOption3: option3,
        }
      );

      if ((!files.Risk || description.trim() === "") && special != "no") {
        return toast.error(
          "Os campos referentes a condicoes especiais sao necessários para irmos para próxima fase."
        );
      }
      for (const key of Object.keys(files)) {
        const uploadFiles = files[key];
        const data = new FormData();
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }

        const resultUpload = await request(
          process.env.REACT_APP_API_URL + "/documentupload/" + id + "/" + key,
          "post",
          token,
          data
        );
      }

      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          specialDescriptionRisk: description,
          specialConditionRisk: special,
        }
      );
      toast.success("Salvo com sucesso.");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao salvar garantia");
    } finally {
      setLoading(false);
    }
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeSpecial = (event) => {
    setSpecial(event.target.checked === true ? "no" : "yes");
  };

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Calcular Garantias
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ justifyContent: "end", display: "flex" }}></Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>MCC</StyledTableCell>
                      <StyledTableCell align="right">Nome</StyledTableCell>
                      <StyledTableCell align="right">
                        Faturamento
                      </StyledTableCell>
                      <StyledTableCell align="right">Garantia</StyledTableCell>
                      <StyledTableCell align="right">
                        Porcentagem
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {forecast?.map((row) => {
                      const found = mcc?.find(
                        (element) =>
                          element.mcc == String(row?.mcc)?.padStart(4, "0")
                      );
                      return (
                        <StyledTableRow key={row?.id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {row?.mcc}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {found?.mccName || "Não identificado."}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {currencyLocale.format(row?.mccTotalTpv)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {found &&
                              currencyLocale.format(
                                row?.mccTotalTpv * found?.percentageGuarantee
                              )}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {found && found?.percentageGuarantee + "%"}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer sx={{ marginTop: 8 }} component={Paper}>
                <Table aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={3} align="right">
                        Card presente
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.cardPresent)}
                      </StyledTableCell>
                      {/* <StyledTableCell align="right">ooooooooo</StyledTableCell> */}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={3}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        Card nao presente
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.cardNotPresent)}
                      </StyledTableCell>
                      {/* <StyledTableCell align="right">ooooooooo</StyledTableCell> */}
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" colSpan="2">
                        Garantia
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Faturamento Anual
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.sumTpv)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Faturamento Mensal
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.monthTotal)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Cartão Presente:{" "}
                        {Math.round(forecastGeneral?.performanceWithCard)}%
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.cardPresentPercent)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Cartão Não Presente:{" "}
                        {Math.round(forecastGeneral?.performanceWithoutCard)}%
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.cardNotPresentPercent)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Valor Garantia:
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.valueGuarantee)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" colspan="2">
                        Reserva
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        50% de Reserva:
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.reserve50)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        35% de Reserva:
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.reserve35)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        10% de Reserva:
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.reserve10)}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        5% de Reserva:
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {currencyLocale.format(totals?.reserve5)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <TableContainer sx={{ marginTop: 6 }} component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" colspan="3">
                        Opções de Garantia
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <StyledTableRow>
                      
                      <CustomTableCell component="th" scope="row">
                        Opção 1 -
                      </CustomTableCell>
                      <StyledTableCell>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={option1}
                          onChange={(event) => setOption1(event.target.value)}
                          fullWidth
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Opção 2 -
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={option2}
                          onChange={(event) => setOption2(event.target.value)}
                          fullWidth
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        Opção 3 -
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={option3}
                          onChange={(event) => setOption3(event.target.value)}
                          fullWidth
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Digite abaixo a condição especial já aprovada e anexe a
                evidência de aprovação dela*
              </Typography>
              <TextareaAutosize
                onChange={onChangeDescription}
                value={description}
                aria-label="minimum height"
                minRows={4}
                placeholder="Preenchimento obrigatório."
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <div style={{ border: "1px dotted blue", padding: "10px" }}>
                <FileUploader
                  handleChange={(file) => handleUploadChange("Risk", file)}
                  name="file"
                  multiple={true}
                  children={
                    <div
                      style={{
                        textAlign: "center",
                        color: "#2C90D8",
                        padding: 0,
                      }}
                    >
                      {files?.Risk?.length > 0 ? (
                        Object.keys(files?.Risk).map((v) => {
                          const uploadedFile = files?.Risk[v];
                          return <p key={v}>{uploadedFile.name}</p>;
                        })
                      ) : (
                        <p>
                          <CloudUploadOutlinedIcon /> Arraste e solte o arquivo
                          preenchido aqui
                        </p>
                      )}
                    </div>
                  }
                />
              </div>
            </Grid>

            <Grid item xs={5}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={special === "no"}
                      onChange={handleChangeSpecial}
                    />
                  }
                  label="Não possui condição especial"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Footer>
            {analysis?.statusRisk?.toUpperCase() !== "AJUSTAR GARANTIA" && (
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                onClick={onClickSave}
                disabled={loading}
              >
                Salvar
                {loading === true && <CircularProgress />}
              </Button>
            )}
           
           { analysis?.statusRisk?.toUpperCase()  === "AJUSTAR GARANTIA"    &&(
               <Button
               sx={{ backgroundColor: "navy", borderRadius: "10px" }}
               variant="contained"
               disabled={loading}
               onClick={onClickSendProposal}
             >
               Enviar para Proposta Comercial
             </Button>

            )}
          </Footer>
        </Card>
      </Box>
      <Hover loading={loading} />
    </Fragment>
  );
};

export default Guarantee;
