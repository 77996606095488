import React, { Component, useEffect, useState, Fragment } from 'react';

import dayjs from 'dayjs';

import Link from '@mui/material/Link';

import { toast } from 'react-toastify';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Hover } from 'elements/hover';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, Card } from '@mui/material';

import { DataTable } from 'elements/dataTable';

import './Consulta_ExtratoCartas.css';

export const ConsultaExtratoCartasClient = () => {
  const [valueTipo, setvalueTipo] = React.useState('');
  const [valueTipoMensagem, setvalueTipoMensagem] = React.useState('');
  const [valueDataInicio, setValueInicio] = React.useState(dayjs());
  const [valueDataFim, setValuefim] = React.useState(dayjs());

  const [valueIdentificador, setvalueIdentificador] = React.useState('');

  const [displayInputMerchantName, setDisplayMerchantName] = useState('none');
  const [displayInputEmail, setDisplayEmail] = useState('none');
  const [displayTable, setDisplayTable] = useState('none');

  const [data, setData] = useState('');

  const [rows, setRows] = useState('');

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  //TEXTFIELD

  const [valueInputMerchantName, setValueMerchantName] = React.useState();
  const [valueInputEmail, setValueEmail] = React.useState();

  const handleChangeEmail = (event) => {
    setValueEmail(event.target.value);

  };

  const handleChangeMerchantName = (event) => {
    setValueMerchantName(event.target.value);
  };

  ///CALENDARIO

  const handleChangeInicio = (newValue) => {
    setValueInicio(newValue);
  };

  const handleChangeFim = (newValue) => {
    setValuefim(newValue);
  };

  ///SELECTS

  const handleChangeTipo = (event) => {
    setvalueTipo(event.target.value);
    setData([])
  };

  const handleChangeTipoMensagem = (event) => {
    setvalueTipoMensagem(event.target.value);
  };

  const handleChangeIdentificador = (event) => {
    setvalueIdentificador(event.target.value);
  };

  const sentEmailLetters = `
    query (
      $tipoMensagem: String
      $dataInicial: String!
      $dataFinal: String!
      $email: String
      $merchantName: String
      $contadorPage: Int
    ) {
      sentEmailLetters(
        letter_type: $tipoMensagem
        initialSendDate: $dataInicial
        endSendDate: $dataFinal
        email: $email
        merchant_name: $merchantName
        page: $contadorPage
        per_page: 10
      ) {
        data {
          email
          total_letters
          download_attached_file
          email_sent_status
          sent_at
          viewed_at
          complaint_at
          merchant_name
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
      }
    }
  `;

  const listLetters = `
    query (
      $tipoMensagem: String
      $dataInicial: String!
      $dataFinal: String!
      $email: String
      $merchantName: String
      $contadorPage: Int
    ) {
      listLetters(
        letter_type: $tipoMensagem
        initialDate: $dataInicial
        finalDate: $dataFinal
        email: $email
        merchant_name: $merchantName
        page: $contadorPage
        per_page: 10
      ) {
        data {
          letter_number
          letter_type
          merchant_id
          merchant_name
          document
          email
          reference_date
          reference_file_id
          reference_file
          card_number
          nsu
          authorization_code
          sale_date
          sale_amount
          chargeback_process
          debit_or_chargeback_amount
          chargeback_reason
          email_rating
          id_sent_email_letter
          url_download
          produced_at
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
      }
    }
  `;

  const sentEmailStatements = `
    query (
      $dataInicial: String!
      $dataFinal: String!
      $email: String
      $merchantName: String
      $contadorPage: Int
    ) {
      sentEmailStatements(
        initialSendDate: $dataInicial
        endSendDate: $dataFinal
        email: $email
        merchant_name: $merchantName
        page: $contadorPage
        per_page: 10
      ) {
        data {
          id_sent_email_statement
          merchant_id
          branch_id
          merchant_name
          reference_file
          email
          attached_file
          download_attached_file
          email_sent_status
          sent_at
          viewed_at
          complaint_at
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
      }
    }
  `;

  const listStatements = `
    query (
      $dataInicial: String!
      $dataFinal: String!
      $email: String
      $merchantName: String
      $contadorPage: Int
    ) {
      listStatements(
        initialDate: $dataInicial
        finalDate: $dataFinal
        email: $email
        merchant_name: $merchantName
        page: $contadorPage
        per_page: 10
      ) {
        data {
          statement_id
          settlement_type
          reference_file_id
          reference_file
          modified_at
          merchant_id
          merchant_name
          branch_id
          has_sales_movement
          email
          reference_month
          id_sent_email_statement
          email_rating
          url_download
          produced_at
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
      }
    }
  `;

  const fetchGRAPHQL = async () => {
    setData([]);
    setLoading(true)
    try {
      let hasMorePages = true;
      let allData = [];
      let valueContadorPage = 0;
      let queryType = ""

      if (valueTipo === "sentEmailStatements") {
        queryType = sentEmailStatements;
      } else if (valueTipo === "sentEmailLetters") {
        queryType = sentEmailLetters;
      } else if (valueTipo === "listLetters") {
        queryType = listLetters;
      } else if (valueTipo === "listStatements") {
        queryType = listStatements;
      } 
  
      while (hasMorePages) {
        const response = await fetch('https://backend.entrepay.com.br/informes/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: queryType,
            variables: {
              tipoMensagem: valueTipoMensagem,
              dataInicial: dayjs(valueDataInicio).format('YYYY-MM-DD'),
              dataFinal: dayjs(valueDataFim).format('YYYY-MM-DD'),
              email: valueInputEmail,
              merchantName: valueInputMerchantName,
              contadorPage: valueContadorPage,
            },
          }),
        });
  
        const responseData = await response.json();
        console.log(responseData);
  
        if (responseData.errors) {
          throw new Error(responseData.errors[0].message);
        }
  
        // Concatena os dados da resposta com os dados existentes
        console.log(Object.values(responseData.data)[0])
        let dataObjeto = Object.values(responseData.data)[0]
        console.log("data objeto:", dataObjeto)
        allData = [...allData, ...dataObjeto.data];
        
        hasMorePages = dataObjeto.has_more_pages;
        valueContadorPage++;
      }
  
      // Atualiza a variável de estado com todos os dados concatenados
      setData(allData);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false)
      setDisplayTable(true)
    }
  };

  const columnsSentEmailLetters = [
    { field: 'MerchantName', headerName: 'Comerciante', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Email', headerName: 'Email', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Status', headerName: 'Status', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'TotalComunicados', headerName: 'Total de comunicados na mensagem', width: 280, flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'Data', headerName: 'Data', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: 'LinkAnexo',
      headerName: 'Link Anexo',
      width: '100%', 
      flex: 1, 
      align: 'center', 
      headerAlign: 'center',
      renderCell: (params, index) => <Link>LINK</Link>,
    },
  ];

  const columnsSentEmailStatements = [
    { field: 'MerchantName', headerName: 'Comerciante', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Email', headerName: 'Email', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Status', headerName: 'Status', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Data', headerName: 'Data', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    {
      field: 'LinkAnexo',
      headerName: 'Link Anexo',
      width: '100%', 
      flex: 1, 
      align: 'center', 
      headerAlign: 'center',
      renderCell: (params, index) => <Link>LINK</Link>,
    },
  ];

  const columnsListLetters = [
    { field: 'MerchantName', headerName: 'Comerciante', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Email', headerName: 'Email', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Valor', 
    headerName: 'Valor de Venda', 
    width: '100%', 
    flex: 1, 
    align: 'center',
    headerAlign: 'center' ,
     valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return params.value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    },
    { field: 'Data', headerName: 'Data', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    {
      field: 'LinkAnexoListLetters',
      headerName: 'Link Anexo',
      width: '100%', 
      flex: 1, 
      align: 'center', 
      headerAlign: 'center',
      renderCell: (params, index) => <Link>LINK</Link>,
    },
  ];

  const columnsListStatements = [
    { field: 'MerchantName', headerName: 'Comerciante', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Email', headerName: 'Email', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'Data', headerName: 'Data', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    {
      field: 'LinkAnexoListStatements',
      headerName: 'Link Anexo',
      width: '100%', 
      flex: 1, 
      align: 'center', 
      headerAlign: 'center',
      renderCell: (params, index) => <Link>LINK</Link>,
    },
  ];

  function handleCellClick(e) {
    console.log(e);
    if (e.field === 'LinkAnexo') {
      let link = e.row.LinkAnexo;
      window.open(link, '_blank');
    } else if (e.field === 'LinkAnexoListLetters') {
      let link = e.row.LinkAnexoListLetters;
      window.open(link, '_blank');
    } else if (e.field === 'LinkAnexoListStatements') {
      let link = e.row.LinkAnexoListStatements;
      window.open(link, '_blank');
    }
  }

  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        Não há resultados
      </Stack>
    );
  }

  useEffect(() => {
    if(data) {
      setRows(data?.map((product, index) => ({
        id: index,
        Valor: product.sale_amount,
        Email: product.email,
        MerchantName: product.merchant_name,
        Status: product.email_sent_status,
        TotalComunicados: product.total_letters,
        Data: dayjs(product.sent_at).format('DD-MM-YYYY'),
        LinkAnexoListLetters: product.url_download,
        LinkAnexoListStatements: product.url_download,
        LinkAnexo: product.download_attached_file,
      })))
    }
  }, [data]);

  useEffect(() => {
    if (valueIdentificador === "email") {
      setDisplayEmail('block')
      setDisplayMerchantName('none')
      setValueMerchantName()
    } else if (valueIdentificador === "merchantName") {
      setDisplayEmail('none')
      setDisplayMerchantName('block')
      setValueEmail()
    }  else {
      setValueMerchantName()
      setValueEmail()
      setDisplayEmail('none')
      setDisplayMerchantName('none')
    }
  }, [valueIdentificador]);
  
  return (
    <Fragment>
      <div class="inputsDiv">
        <div class="contentInputs">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ margin: 3 }}
            >
              <Select
                id="demo-simple-select-outlined"
                value={valueTipo}
                onChange={handleChangeTipo}
                sx={{
                  minWidth: '160px',
                }}
              >
                <MenuItem value={'sentEmailStatements'}>Envio de Emails de Extratos</MenuItem>
                <MenuItem value={'sentEmailLetters'}>Envio de Email de Cartas</MenuItem>
                <MenuItem value={'listLetters'}>Lista de Cartas</MenuItem>
                <MenuItem value={'listStatements'}> Lista de Extratos</MenuItem>
              </Select>
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 220 } }}
                label={'Inicio'}
                value={valueDataInicio}
                onChange={handleChangeInicio}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 220 } }}
                label={'Fim'}
                value={valueDataFim}
                onChange={handleChangeFim}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
              <FormControl sx={{ width: '150px' }}>
                <Select
                  label="Filtro por EC"
                  id="demo-simple-select-outlined"
                  value={valueIdentificador}
                  onChange={handleChangeIdentificador}
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={'email'}>Email</MenuItem>
                  <MenuItem value={'merchantName'}>Merchant</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={valueInputEmail}
                style={{ display: displayInputEmail }}
                onChange={handleChangeEmail}
              />
              <TextField
                id="outlined-basic"
                label="Merchant"
                variant="outlined"
                value={valueInputMerchantName}
                style={{ display: displayInputMerchantName }}
                onChange={handleChangeMerchantName}
              />
              {valueTipo === "listStatements" || valueTipo === "sentEmailStatements" ? null : (
                <Select
                label="Tipo de mensagem"
                id="demo-simple-select-outlined"
                value={valueTipoMensagem}
                onChange={handleChangeTipoMensagem}
                sx={{
                  minWidth: '160px',
                }}
                >
                <MenuItem value={'accreditation'}>Boas Vindas</MenuItem>
                <MenuItem value={'chargeback'}>ChargeBack</MenuItem>
                <MenuItem value={'cancellation'}>Cancelamento</MenuItem>
                {valueTipo === "listLetters" || valueTipo === "sentEmailLetters" ? null : (
                  <MenuItem value={'statement'}>Extrato</MenuItem>
                )}
                </Select>
              )}
              <FormControl>
                <Button
                  variant="contained"
                  onClick={() => fetchGRAPHQL()}
                  sx={{
                    m: 1,
                    minWidth: 50,
                    color: 'primary.contrastText',
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'primary.light' },
                  }}
                >
                  Pesquisar
                </Button>
              </FormControl>

              {/* <Button variant="contained" sx={{ m: 1, minWidth: 50, height: 50 }} onClick={() => getSummary()}>Aplicar filtros</Button> */}
            </Stack>
          </LocalizationProvider>
        </div>
      </div>
      <div class="main">
        <div class="content">
          <Card
          style={{ display: displayTable }}
          sx={{
            boxShadow:
              '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            padding: 2,
            borderRadius: '16px',
            width: '100%',
            height: '100%',
          }}
          >
            <DataTable
              style={{
                width: '100%'
              }}
              rows={rows ?? []}
              columns={
                valueTipo === "sentEmailStatements" ? columnsSentEmailStatements : 
                valueTipo === "sentEmailLetters" ? columnsSentEmailLetters : 
                valueTipo === "listLetters" ? columnsListLetters :
                valueTipo === "listStatements" ? columnsListStatements : []
              }
              components={{ NoRowsOverlay }}
              onCellClick={handleCellClick}
              pagination
              page={page}
              pageSize={pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Card>
        </div>
      </div>
      <Hover loading={loading} />
    </Fragment>
  );
};