import React, { Fragment } from "react";
import { Fonts } from "shared/constants/AppEnums";
// import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Translation from "shared/localization/locales/pt_BR.json"


export const ObjectView = ({ detail }) => {
  let data;
  try {
    data = JSON.parse(detail);
  } catch (error) {
    data = detail;
  }
 
  const tipo = typeof data;
    // console.log(`tipo: ${tipo}`);
  //   console.log(JSON.stringify(detail));
  //   console.log(detail.toString());
  //   console.log(" ");
  //   console.log(" ");

  if (tipo === "undefined" || tipo === "null") {
    return <Box></Box>;
  }

  if (tipo === "string" || tipo === "number" || tipo === "boolean") {
    return (
      <Box
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontWeight: Fonts.MEDIUM,
          fontSize: { xs: 12 },
        }}
      >
        {data.toString()}
      </Box>
    );
  }

  if (Array.isArray(data)) {
    return (
      <Fragment>
        {data.map((item, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                marginBottom: 4,
                borderWidth: 0.5,
                borderStyle: "solid",
                borderColor: "#888888",
                borderRadius: 3,
                padding: 6,
              }}
            >
              <ObjectView detail={item} />
            </Box>
          );
        })}
      </Fragment>
    );
  }

  if (tipo === "object" && data) {
    const keys = Object.keys(data || {});

    return (
      <Fragment>
        {keys.map((k, idx) => {
          return (
            <Box key={idx}>
              <Box
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: Fonts.BOLD,
                  fontSize: { xs: 16 },
                }}
              >
                 <>{Translation["bigdata." + k] ?? "bigdata." + k }</>
                {/* <IntlMessages id={"bigdata." + k} /> */}
              </Box>
              <ObjectView detail={data[k]} />
            </Box>
          );
        })}
      </Fragment>
    );
  }

  // default, se não achou o tipo e não sabe o que fazer
  return <Box>{JSON.stringify(data)}</Box>;
};
ObjectView.propTypes = {
  detail: PropTypes.any,
};
