import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { AppBar, Button, Grid, TextField, Toolbar } from "@mui/material";
// import * as yup from "yup";
import { styled } from "@mui/system";
import { FaCcVisa, FaCcAmex, FaCcMastercard } from "react-icons/fa";
import { Fonts } from "shared/constants/AppEnums";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import elo from "../../../../../assets/icon/elo.png";
import hipercard from "../../../../../assets/icon/hipercard.png";
import request from "shared/utils/request";
import { useAuthMethod } from "hooks/AuthHooks";
import FallbackSpinner from "elements/fallbackSpinner";
import { groupBy } from "lodash";

const GridItem = styled(Grid)({
  background: "#000536",
  color: "white",
  paddingTop: "1.2em",
});

const GridContainer = styled(Grid)({
  border: "1px solid rgb(39 38 38 / 20%)",
  borderRadius: "10px",
});

const GreyTypography = styled(Typography)({
  background: "#000536",
  color: "#ffffff",
});

const SmallCard = styled(Card)({
  background: "#000536",
  color: "#ffffff",
  width: "400px",
  height: "78px",
  borderRadius: "0px",
  marginBottom: "30px",
});

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const StandardMdr = () => {
  const { id } = useParams();
  const [proposal, setProposal] = useState();
  const [comission, setComission] = useState(0);
  const [status, setStatus] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState({});

  const { getToken } = useAuthMethod();

  const handleClickSave = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        await request(
          process.env.REACT_APP_API_URL + `/analysis/${id}`,
          "put",
          token,
          {
            comission,
          }
        );
        toast.success(" Salvo com sucesso!");
      } catch (error) {
        console.log(error);
        toast.error("Erro ao salvar");
      } finally {
        setLoading(false);
      }
  };

  const listProposal = async function () {
    try {
      setLoading(true);
      const token = await getToken();
      const result = await request(
        process.env.REACT_APP_API_URL + `/feemdr/${id}`,
        "get",
        token
      );
      setProposal(result);
      setComission(result?.analysis?.comission ?? 0);
      setCards(groupBy(result?.analysisMdr, (v) => v.cardBrand));
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar Tabela MDR");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal();
  }, []);

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Proposta MDR
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container>
          <Grid item>
            <SmallCard>
              <Typography>{`Tabela MDR`}</Typography>
              <Typography>
                Faturamento mensal: Até{" "}
                {currencyLocale.format(proposal?.pricingPartner?.tpvAverage)}
              </Typography>
              <Typography>
                Antecipacao{" "}
                {proposal?.pricingPartner?.prepayment < 50 ? "ABAIXO" : "ACIMA"}{" "}
                50% do volume
              </Typography>
            </SmallCard>
          </Grid>
          <Grid item sx={{ ml: 10 }}>
            <GreyTypography>Comissão</GreyTypography>
            <TextField
              small
              id="outlined-basic"
              // label="Taxa"
              variant="filled"
              value={comission}
              onChange={(e) => setComission(e.target.value)}
            />
          </Grid>
          <Grid item sx={{ ml: 10 }}>
            <Button
              sx={{ backgroundColor: "navy", borderRadius: "10px", ml: 2 }}
              variant="contained"
              disabled={loading}
              onClick={handleClickSave}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>

        {proposal?.forecastMccs?.map((mcc) => {
          return (
            <Box sx={{mb: 5}}>
              <GridContainer container key={mcc.id}>
                <GridItem xs={2} sx={{ borderRadius: "4px 0px 0px 4px" }} item>
                  <Typography>MCC</Typography>
                </GridItem>
                <Grid xs={2} item>
                  <TextField
                    disabled={true}
                    small
                    id="outlined-basic"
                    value={mcc?.mcc ?? 0}
                  />
                </Grid>
                <GridItem xs={2} item>
                  <Typography>DESCRIÇÃO</Typography>
                </GridItem>
                <Grid item sx={{ paddingTop: "1.2em", width: "47%" }}>
                  <Typography>{mcc?.mccDescription?.mccName}</Typography>
                </Grid>
              </GridContainer>

              {Object.keys(cards)?.map((brand) => {
                const mdrValues = cards[brand]?.filter(v => v.mcc.mcc == mcc.mcc);
                return mdrValues.length > 0 && (
                  <GridContainer
                    key={`${brand}-${mcc?.mcc}`}
                    container
                    sx={{ marginTop: 4 }}
                  >
                    {brand.toLowerCase() == "visa" && (
                      <GridItem
                        xs={4}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        item
                      >
                        <FaCcVisa size="3.5em" />
                      </GridItem>
                    )}
                    {brand.toLowerCase() == "mastercard" && (
                      <GridItem
                        xs={4}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        item
                      >
                        <FaCcMastercard size="3.5em" />
                      </GridItem>
                    )}
                    {brand.toLowerCase() == "elo" && (
                      <GridItem
                        xs={4}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        item
                      >
                        <img
                          src={elo}
                          style={{ width: "120px", height: "49px" }}
                        />
                      </GridItem>
                    )}
                    {brand.toLowerCase() == "hipercard" && (
                      <GridItem
                        xs={4}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        item
                      >
                        <img
                          src={hipercard}
                          style={{ width: "120px", height: "49px" }}
                        />
                      </GridItem>
                    )}
                    {brand.toLowerCase() == "amex" && (
                      <GridItem
                        xs={4}
                        sx={{ borderRadius: "4px 0px 0px 4px" }}
                        item
                      >
                        <FaCcAmex size="3.5em" />
                      </GridItem>
                    )}

                    {mdrValues?.map((fee) => {
                      return (
                        <Grid xs={2} item key={mdrValues.id}>
                          <GreyTypography>
                            {fee.product.toUpperCase()}
                          </GreyTypography>
                          {fee.mdr}
                        </Grid>
                      );
                    })}
                  </GridContainer>
                ) || null;
              })}
            </Box>
          );
        })}
      </Card>
      {loading && <FallbackSpinner />}
    </Box>
  );
};

export default StandardMdr;
