import * as React from 'react';
import { useState, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import People from '@mui/icons-material/People';
import { TextareaAutosize, Typography } from '@mui/material';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';

function Form() {
  const [form, setForm] = useState([]);
  const [formPartner, setFormPartner] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [analysisNeoway, setAnalysisNeoway] = useState([]);

  const { id } = useParams();

  const { getToken } = useAuthMethod();

  const getQuestions = useCallback(async () => {
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/form/${id}`, 'get', token,
      );
      const resultPartner = await request(
        process.env.REACT_APP_API_URL + `/formpartner/${id}`, 'get', token
      );
      const resultAnalysis = await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`, 'get', token
      );
      setForm(result);
      setAnalysis(resultAnalysis?.bigData?.bigData?.bigData?.dadosCadastrais && JSON.parse(resultAnalysis?.bigData?.bigData?.bigData?.dadosCadastrais));
      setAnalysisNeoway(resultAnalysis?.neowayData?.neowayData?.neowayData && JSON.parse(resultAnalysis?.neowayData?.neowayData?.neowayData));

      setFormPartner(resultPartner);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao buscar respostas');
    }
  }, []);

  React.useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  console.log(analysisNeoway);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pergunta</TableCell>
              <TableCell>Retorno no BigData</TableCell>
              <TableCell>Resposta do Cliente</TableCell>
           

            </TableRow>
          </TableHead>
          <TableBody>
            {form?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.form?.question}
                </TableCell>

                <TableCell>
                {row?.form?.question == "Razão Social:" &&(
               analysis?.Result[0]?.BasicData?.OfficialName
                )}
                 {row?.form?.question == "Nome Fantasia:" &&(
             analysis?.Result[0]?.BasicData?.Aliases?.UnstandardizedRFOfficialName
                )}
                   {row?.form?.question == "CNPJ:" &&(
               analysis?.Result[0]?.BasicData?.TaxIdNumber
                )}
                 {row?.form?.question == "Data de Constituição:" &&(
               analysis?.Result[0]?.BasicData?.FoundedDate
                )}
                 {row?.form?.question == "Estado:" &&(
              analysis?.Result[0]?.BasicData?.HeadquarterState
                )}
               
                </TableCell>
                <TableCell>{row?.questionAnswer}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: 5, marginBottom: 5, fontWeight: 500 }}>
        SOCIOS
      </Typography>
      <List style={{display: "flex"}}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            Resposta Cliente: 
            {formPartner?.map((row) => {
              return (
                <ListItem key={row.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <People />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={row.fullName}
                    secondary={
                      <>
                        CPF: {row.cpf}
                        <br />
                        RG: {row.rg}
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            Retorno Neoway: 
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <People />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={analysisNeoway?.beneficiarios?.[0]?.nome}
                    secondary={
                      <>
                        CPF:{analysisNeoway?.beneficiarios?.[0]?.documento}
                        <br />
                      </>
                    }
                  />
                </ListItem>
          </List>
        </List>

    </>
  );
}

export default Form;
