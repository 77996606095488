import React from 'react';

// For Azure MSAL Auth
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { msalConfig, tokenRequest } from 'shared/constants/msalConfig';

// TO DO: pegar os grupos e mostrar os menus condicionalmente
export const createMsalInstance = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  /**
   * To set an active account after the user signs in, register an event and listen to LOGIN_SUCCESS & LOGOUT_SUCCES. For more,
   * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      if (event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      if (msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      console.log(JSON.stringify(event));
    }
  });

  return msalInstance;
};

export const useAuthUser = () => {
  const { accounts, inProgress, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();

  return {
    inProgress,
    isAuthenticated,
    user: {
      displayName: account?.name || 'User',
      email: account?.username || '',
    },
  };
};

export const useAuthMethod = () => {
  const { instance } = useMsal();

  const logout = async function () {
    const currentAccount = instance.getActiveAccount();
    const logoutHint = currentAccount.idTokenClaims.login_hint;
    await instance.logoutPopup({ logoutHint: logoutHint });
  };

  const signIn = async function () {
    try {
      //await instance.loginPopup(tokenRequest);
      await instance.loginRedirect(tokenRequest);

    } catch (error) {
      console.log(error);
    }
  };

  const getToken = async function () {
    try {
      if (!instance.getActiveAccount()) {
        throw 'No active account';
      }

      const data = await instance.acquireTokenSilent(tokenRequest);
      //      console.log(data);
      return data.idToken;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    signIn,
    logout,
    getToken,
  };
};
