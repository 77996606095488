import React, { Fragment } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { Fonts } from "shared/constants/AppEnums";
import { grey } from "@mui/material/colors/index";

import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormikSelect from "elements/inputs/FormikSelect";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { useAuthUser } from "@crema/utility/AuthHooks";
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';



const validationSchema = yup.object({
  acquirerCod: yup
    .number()
    .required("Código da adquirente é obrigatório")
    .typeError("Código da adquirente deve ser um valor válido"),

  acquirerName: yup.string().required("Nome de adquirente é obrigatório "),
  status: yup
    .mixed()
    .oneOf(["Ativo", "Inativo"])
    .required("Status nao selecionado"),
});

const Registration = () => {
  const navigate = useNavigate();
  // const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthUser();

  const { getToken } = useAuthMethod();


  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Cadastrar Adquirente
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>

          <Formik
            validateOnChange={true}
            initialValues={{
              acquirerCod: "",
              acquirerName: "",
              status: "Ativo",
            }}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              const token = await getToken();

              try {
                const response = await request(
                  process.env.REACT_APP_ACCREDITATION_API_URL + `/acquirer/`, 'post', token,
                  {
                    acquirer_cod: data?.acquirerCod,
                    acquirer_name: data?.acquirerName,
                    status: data?.status,
                    user: user?.email,
                  }
                );
                // setOpen(true);
                toast.success("Adquirente cadastrado com sucesso");
                setTimeout(() => { navigate(
                  '/accreditation/parameters/acquirerGestation/List'
                );}, 1000);
               
              } catch (error) {
                toast.error("Erro ao cadastrar adquirente");
                console.log(error);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form
                sx={{
                  textAlign: "left",
                }}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Código da adquirente:
                  </Typography>

                  <AppTextField
                    name="acquirerCod"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Nome do adquirente:
                  </Typography>

                  <AppTextField
                    name="acquirerName"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      marginBottom: "4px",
                    }}
                  >
                    Status:
                  </Typography>
                  <FormControl fullWidth>
                    <FormikSelect
                      name="status"
                      id="status"
                      placeholder="Status"
                      options={[
                        { value: "Ativo", label: "Ativo" },
                        { value: "Inativo", label: "Inativo" },
                      ]}
                    />
                  </FormControl>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    width: "100%",
                    backgroundColor: "#000536",
                    height: 44,
                  }}
                  type="submit"
                >
                  Salvar {isSubmitting && <CircularProgress />}
                </Button>
                {/* <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Adquirente cadastrado com sucesso.
                    </DialogContentText>
                  </DialogContent>
                </Dialog> */}
              </Form>
            )}
          </Formik>

          <Box
            sx={{
              mt: { xs: 3, xl: 4 },
              textAlign: "center",
              color: grey[700],
              fontSize: 14,
              fontWeight: Fonts.BOLD,
            }}
          ></Box>
        </Card>
      </Box>
    </Fragment>
  );
};

export default Registration;
