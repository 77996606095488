import AppAnimate from "@crema/core/AppAnimate";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { format, parseISO } from "date-fns";
import { sortBy } from "lodash";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import formatCnpj from "shared/utils/formatCnpj";
import Grid from "@mui/material/Grid";
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';


const columns = [
  { id: "cnpj", label: "CNPJ", minWidth: 170 },
  { id: "name", label: "Nome", minWidth: 100 },
  { id: "statusRisk", label: "Status Risco", minWidth: 100 },
  { id: "comment", label: "Comentario", minWidth: 100 },
  { id: "user", label: "Responsavel", minWidth: 100 },
  { id: "Data", label: "Data", minWidth: 100 },
  { id: "Ações", label: "", minWidth: 100 },
];

const ListAnalysis = () => {
  const [proposalList, setProposalList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { getToken } = useAuthMethod();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // vai buscar na api a lista das análises de pricing
  const listProposal = async function () {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(process.env.REACT_APP_API_URL + `/leads`, 'get', token);
      const leads = sortBy(result, (v) => v.analysis?.statusRisk);
      setProposalList(leads);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setProposalList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProposal();
  }, []);

  const handleRowClick = function (aId) {
    navigate(`/onboarding/risk/analysis/detail/${aId}`);
  };

  const handleClickCompliance = function (aId) {
    navigate(`/onboarding/risk/compliance/${aId}`);
  };

  const handleClickStartProcess = async function (uuid) {
    const token = await getToken();
    try {
      const result = await request(process.env.REACT_APP_API_URL + `/processanalysis/${uuid}`, 'post', token);
      toast.success("Reiniciando APIs")
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
   
  };  
  return (
    <Fragment>
      <Hover loading={loading} />

      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            // maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Lista de analises
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ backgroundColor: "#000536", color: "#ffffff" }}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {proposalList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const analysis = row?.analysis;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell> {formatCnpj(row?.cnpj)}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{analysis?.statusRisk?.toUpperCase()}</TableCell>
                        <TableCell>{analysis?.comment}</TableCell>
                        <TableCell>{analysis?.user}</TableCell>
                        <TableCell>
                          {analysis?.analysisDate
                            ? format(
                                parseISO(analysis.analysisDate),
                                "dd-MM-yyyy HH:mm:ss"
                              )
                            : ""}
                        </TableCell>
                        <TableCell sx={{ display: "flex" }}>
                          <Button
                            onClick={() => handleRowClick(row?.analysis?.id)}
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            size="small"
                            disabled={
                             ( (analysis?.acceptTerms?.status?.toUpperCase() !==
                                "APROVADO" 
                                // ||
                              // analysis?.statusForm?.toUpperCase() !==
                              //   "COMPLETO" ||
                              // analysis?.statusForecastCompleted?.toUpperCase() !==
                              //   "COMPLETO" ||
                              // analysis?.statusUpload?.toUpperCase() !==
                              //   "COMPLETO"
                                 ) &&  analysis?.statusPreApproval?.toUpperCase() !== "REPROVADO") ||
                              (analysis?.acceptTerms?.status?.toUpperCase() === "APROVADO" && analysis?.statusPreApproval === null)
                            }
                          >
                            Relatorio
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                              ml: 3,
                            }}
                            variant="contained"
                            size="small"
                            onClick={() =>
                              handleClickCompliance(row?.analysis?.id)
                            }
                            disabled={analysis?.statusUpload !== "COMPLETO"}
                          >
                            Compliance
                          </Button>
                          <Button
                            sx={{
                              backgroundColor: "#000536",
                              borderRadius: "10px",
                              ml: 3,
                            }}
                            variant="contained"
                            size="small"
                            onClick={() =>
                              handleClickStartProcess(row?.analysis?.acceptTerms?.uuid)
                            }
                            disabled={analysis?.statusAPIsProcess?.toLowerCase() !== "error"}
                          >
                           Reanálise
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={proposalList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </Fragment>
  );
};

export default ListAnalysis;
