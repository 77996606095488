/**
 *
 *  Esse elemento deve ser renderizado dentro do tag <AuthenticatedTemplate>.
 *  Ele pega os grupos do usuário e monta as rotas, colocando o Forbidden onde o usuário nao tem permissao
 *
 */
import React, { Fragment, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';
import FallbackSpinner from 'elements/fallbackSpinner';
import { Client } from '@microsoft/microsoft-graph-client';
import { tokenRequest } from 'shared/constants/msalConfig';
import { pages } from 'pages/pages.js';
const groupIds = require('shared/constants/groupIds.json');

import { ErrorPage } from 'elements/error';
import { SideBarMenu } from 'elements/sideBarMenu';
import { Forbidden } from 'elements/forbidden';
import { Blank } from 'elements/blank';

export const Contents = () => {
  const { instance } = useMsal();
  const [router, setRouter] = useState(null);
  const [groups, setGroups] = useState([]);
  const [routes, setRoutes] = useState([]);

  // Atualiza os grupos quando renderiza na primeira vez
  useEffect(() => {
    const updateGroups = async () => {
      try {
        if (instance.getActiveAccount()) {
          const login = await instance.acquireTokenSilent(tokenRequest);
          const graphClient = Client.init({
            // Use the provided access token to authenticate requests
            authProvider: (done) => {
              done(null, login.accessToken);
            },
          });

          //const userDetails = await graphClient.api("/me").get();
          // console.log(userDetails);

          // Atualiza a variavel groups com os IDs dos grupos do usuario, quem vieram do AD
          const g = (await graphClient.api('/me/memberOf').get()).value;
          setGroups(
            g.map((item) => {
              return item.id;
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    //
    updateGroups();
  }, []);

  const getChildrenRoutes = (aPages, aParentPath = '') => {
    const children = [];
    for (const p of aPages) {
      const path = `${aParentPath}/${p.path}`;
      if (p.children && p.children.length > 0) {
        const c = getChildrenRoutes(p.children, path);
        children.push(...c);
      } else {
        children.push({
          element: getElement(p),
          path: path,
        });
      }
    }

    return children;
  };

  // Se atualizou os grupos, atualiza as rotas
  useEffect(() => {
    const r = [
      {
        element: <SideBarMenu pages={pages} />,
        errorElement: <ErrorPage />,
        path: '/',
        children: getChildrenRoutes(pages),
      },
    ];

    setRoutes(r);
  }, [groups]);

  // Se atualizou as rotas, atualiza o Router
  useEffect(() => {
    if (routes.length > 0) {
      const r = createBrowserRouter(routes);
      setRouter(r);
    }
  }, [routes]);

  // retorna se o usuário pode ou nao acessar
  const allowed = (userGroups, pageGroups) => {
    // retorna true se arr1 e arr2 têm algum elemento em comum
    const contains = (arr1, arr2) => {
      return arr1.some((element) => {
        return arr2.includes(element);
      });
    };

    // monta array com os ids dos grupos das páginas
    const ids = [];
    for (const p of pageGroups) {
      for (const item of groupIds) {
        if (p === item.description) {
          ids.push(item.id);
        }
      }
    }

    return contains(ids, userGroups);
  };

  // monta apenas as páginas que são permitidas
  const getElement = (page) => {
    try {
      if (page.element && page.allowedGroups) {
        // Se os grupos do usuário nao incluem os grupos permitidos da pagina,
        // retorna o elemento indicando que nao esta' autorizado
        if (allowed(groups, page.allowedGroups)) {
          if (page.element) {
            return page.element;
          } else {
            return <Blank />;
          }
        } else {
          // No começo, enquanto ainda não pegou os grupos do usário,
          // mostro em branco para não mostrar o Forbidden.
          // Se já tem os grupos e sabe que não tem acesso, aí mostro
          if (groups.length === 0) {
            return <Blank />;
          } else {
            return <Forbidden />;
          }
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <Fragment>
      {router && (
        <RouterProvider router={router} fallbackElement={<FallbackSpinner />} />
      )}
    </Fragment>
  );
};
