import React from "react";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { Fonts } from "shared/constants/AppEnums";
// import {Form, Formik} from 'formik';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// import {FaRegCalendarCheck} from "react-icons/fa";
import { format, parseISO } from "date-fns";
import { styled } from "@mui/material/styles";
// import Stack from '@mui/material/Stack';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';

// import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

// import * as yup from 'yup';

// const validationSchema = yup.object({
//   keyword: yup.string().required('Digite'),
// });

const ProposalItem = () => {
  const [proposal, setProposal] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  const { getToken } = useAuthMethod();

  // vai buscar na api a lista das propopstas (TO DO pode passar algum filtro)
  const getProposal = async function () {
    const token = await getToken();
    try {
      setLoading(true);
      if (typeof id === "undefined") {
        console.log("proposal id undefined");
        navigate("/");

        return <div></div>;
      }

      const result = await request(
        process.env.REACT_APP_API_URL + `/termsanalysis/${id}`,'get', token
      );
      console.log("resultado", result);
      setProposal(result);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProposal();
  }, [id]);

  const adjustGuaranteeEmail = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + "/adjustguarantee/" + id, 'post', token
      );

      toast.success("Email enviado para risco com a solicitacao.");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar email");
      setLoading(false);
    }
  };

  const adjustPricingEmail = async () => {
    const token = await getToken();
    try {
      setLoading(true);
      await request(
        process.env.REACT_APP_API_URL + "/adjustpricing/" + id, 'post', token
      );

      toast.success("Email enviado para pricing com a solicitacao.");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar email");
      setLoading(false);
    }
  };

  const handleRowClickAdjustGuarantee = function () {
    adjustGuaranteeEmail();
  };
  const handleRowClickForm = function () {
    navigate(`/onboarding/proposals/form/${id}`);
  };
  const handleRowClickForecast = function () {
    navigate(`/onboarding/proposals/forecast/${id}`);
  };
  const handleRowClickUploads = function () {
    navigate(`/onboarding/proposals/uploads/${id}`, {
      state: { fromComercial: true },
    });
  };
  const handleRowClickRisk = function () {
    navigate(`/onboarding/proposals/report/${id}`);
  };
  const handleRowClickPricing = function () {
    if(proposal?.analysis?.pricingType != "Personalizada"){
      navigate(`/onboarding/pricing/analysis/standardproposal/${id}`);
    }else{
      adjustPricingEmail();
    }
    
  };
  const handleRowClickSpecialConditionsDocuments = function () {
    navigate(`/onboarding/pricing/analysis/special/${id}`, {
      state: { fromComercial: true },
    });
  };
  const handleRowClickComplianceDocuments = function () {
    navigate(`/onboarding/proposals/compliance/${id}`, {
      state: { fromComercial: true },
    });
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
          padding: { xs: 8, md: 12 },
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Acompanhamento Detalhado da Analise {proposal?.analysis?.dataCnpj?.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Etapa</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Data</TableCell>
                  <TableCell align="center">Negociacao</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Termos de Aceite</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.status?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.termsAcceptDate != null
                        ? format(
                            parseISO(proposal?.termsAcceptDate),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>

                    <TableCell align="center">
                      <Typography>-</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Formulário</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusForm?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.dateForm != null
                        ? format(
                            parseISO(proposal?.analysis?.dateForm),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickForm}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={!proposal?.analysis?.dateForm || loading}
                      >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Forecast</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusForecastCompleted?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis.dateForecast != null
                        ? format(
                            parseISO(proposal?.analysis?.dateForecast),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickForecast}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={!proposal?.analysis.dateForecast || loading}
                      >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={proposal?.analysis?.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Documentos Pessoais</Typography>
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusUpload?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.dateUpload != null
                        ? format(
                            parseISO(proposal?.analysis?.dateUpload),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickUploads}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={!proposal?.analysis?.dateUpload || loading}
                      >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Pré analise</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusPreApproval?.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.statusPreApprovalDate != null
                        ? format(
                            parseISO(proposal?.analysis?.statusPreApprovalDate),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>

                    <TableCell align="center">-</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Risco</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusRisk?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.statusRiskDate != null
                        ? format(
                            parseISO(proposal?.analysis?.statusRiskDate),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickRisk}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={
                          !proposal?.analysis?.statusRiskDate || loading
                        }
                      >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Garantia</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.guarantee?.statusGuarantee?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.guarantee?.guaranteeDate != null
                        ? format(
                            parseISO(
                              proposal?.analysis?.guarantee?.guaranteeDate
                            ),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        <Button
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 5,
                          }}
                          onClick={() => handleRowClickAdjustGuarantee(id)}
                          variant="contained"
                          size="small"
                          disabled={
                            !proposal?.analysis?.guarantee?.guaranteeDate ||
                            loading
                          }
                        >
                          Ajustar Garantia
                          {/* {loading === true && id == row.id && <CircularProgress />} */}
                        </Button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Pricing</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusPricing?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.statusPricingDate != null
                        ? format(
                            parseISO(
                              proposal?.analysis?.statusPricingDate
                            ),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        <Button
                          onClick={handleRowClickPricing}
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 5,
                          }}
                          variant="contained"
                          size="small"
                          disabled={
                           proposal?.analysis?.dateForecast === null ||  proposal?.analysis?.dateForecast != null && proposal?.analysis?.statusRisk.toUpperCase() != "APROVADO"
                            
                          }
                        >
                          Ajustar Pricing
                          {/* {loading === true && id == row.id && <CircularProgress />} */}
                        </Button>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                      <TableCell align="center" component="th" scope="row">
                      <Typography>Documentos Regulatorios</Typography>
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusCompliance?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.dateCompliance != null
                        ? format(
                            parseISO(proposal?.analysis?.dateCompliance),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickComplianceDocuments}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={!proposal?.analysis?.dateProposal || loading}
                      >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Typography>Juridico</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusCsv?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.dateCsv != null
                        ? format(
                            parseISO(proposal?.analysis?.dateCsv),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                      <TableCell align="center" component="th" scope="row">
                      <Typography>Condições Especiais</Typography>
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {proposal?.analysis?.statusCompliance?.toUpperCase() || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {proposal?.analysis?.dateCompliance != null
                        ? format(
                            parseISO(proposal?.analysis?.dateCompliance),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={handleRowClickSpecialConditionsDocuments}
                        sx={{
                          backgroundColor: "#000536",
                          borderRadius: "10px",
                          ml: 5,
                        }}
                        variant="contained"
                        size="small"
                        disabled={(proposal?.analysis?.specialConditionPricing == null && proposal?.analysis?.specialConditionRisk == null) || loading}
                        >
                        Visualizar
                        {/* {loading === true && id == row.id && <CircularProgress />} */}
                      </Button>
                    </TableCell>
                  </TableRow>
               
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

ProposalItem.propTypes = {
  proposalId: PropTypes.object.isRequired,
};

export default ProposalItem;
