import React, { useEffect, Fragment, useState } from 'react';
import { DataTable } from 'elements/dataTable';
import { Button, Box, Typography } from '@mui/material';
import { formatDocument } from 'shared/utils/formatDocument';

export const Detalhado = ({ transacoes, company, comissao, handleClose }) => {
  const [valorTotal, setValorTotal] = useState(0);
  const [rows, setRows] = useState([]);

  const columns = [
    { field: 'data', headerName: 'Data', width: 90 },
    { field: 'hora', headerName: 'Hora', width: 90 },
    {
      field: 'valorTransacao',
      headerName: 'Transação',
      width: 100,
      align: 'right',
    },
    {
      field: 'valorLiquido',
      headerName: 'Líquido Transação',
      width: 100,
      align: 'right',
    },
    {
      field: 'mdr',
      headerName: 'MDR Transação',
      width: 100,
      align: 'right',
    },
    {
      field: 'discount_amount',
      headerName: 'Desconto',
      width: 100,
      align: 'right',
    },
    {
      field: 'nominal_value',
      headerName: 'Valor nominal boleto',
      width: 100,
      align: 'right',
    },
    {
      field: 'amount',
      headerName: 'Valor total boleto',
      width: 100,
      align: 'right',
    },
    {
      field: 'digitable_line',
      headerName: 'Código de Barras',
      width: 100,
      align: 'right',
    },
    {
      field: 'due_date',
      headerName: 'Vencimento',
      width: 100,
      align: 'right',
    },
    { field: 'bandeira', headerName: 'Bandeira', width: 150 },
    { field: 'produto', headerName: 'Produto', width: 200 },
    { field: 'metodo', headerName: 'Método', width: 110 },
    { field: 'cardNumber', headerName: 'Cartão', width: 140 },
    { field: 'parcelas', headerName: 'Parcelas', width: 90 },
    { field: 'nsu', headerName: 'NSU', width: 120 },
    { field: 'terminal', headerName: 'Terminal', width: 120 },
  ];

  function companyFilter(aTransacao) {
    return aTransacao.spr?.company?.document === company.document;
  }

  // se mudaram as transacoes ou a comissao, recalcula o valor total
  useEffect(() => {
    const tr = transacoes.filter(companyFilter);
    setValorTotal(comissao * tr.length);
  }, [transacoes, comissao]);

  // se mudaram as transacoes, remonta a linhas para exibição
  useEffect(() => {
    const tr = transacoes.filter(companyFilter);
    const r = tr.map((item, index) => ({
      id: index,
      data: new Date(item.slg.date).toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
      hora: new Date(item.slg.date).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }),
      valorTransacao: parseFloat(item.slg.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      valorLiquido: parseFloat(item.slg.liquid_amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      mdr: (
        parseFloat(item.slg.amount) - parseFloat(item.slg.liquid_amount)
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      terminal: parseInt(item.psi.serial_number, 10).toString(),
      bandeira: item.slg.product.brand.name,
      produto: item.slg.product.payment_method.description,
      metodo: item.slg.entry_mode.description,
      cardNumber: item.slg.card_number,
      parcelas: item.slg.get_installments.length,
      nsu: item.slg.nsu,
      digitable_line: item.psi.agendamento.digitable_line,
      due_date: new Date(item.psi.agendamento.due_date).toLocaleDateString(
        'pt-BR',
        {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }
      ),
      discount_amount: parseFloat(
        item.psi.agendamento.discount_amount
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      nominal_value: parseFloat(
        item.psi.agendamento.nominal_value
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      amount: parseFloat(item.slg.amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    }));

    setRows(r);
    //    console.log(rows);
  }, [transacoes]);

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxHeight: '80%',
          margin: 2,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            bgcolor: 'primary.main',
            gap: 2,
            padding: 2,
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText',
            }}
          >
            {company.trade_name}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText',
            }}
          >
            {company.company_name}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText',
            }}
          >
            CNPJ/CPF:&nbsp;{formatDocument(company.document)}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText',
            }}
          >
            Total de transações:&nbsp;{rows.length}
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText',
            }}
          >
            Comissão total:&nbsp;
            {valorTotal.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>

          <Button
            sx={{
              borderRadius: 3,
              width: '100%',
              textTransform: 'capitalize',
              marginTop: 'auto',
              height: '40px',
              width: '80px',
              color: 'primary.main',
              bgcolor: 'primary.light',
            }}
            variant="contained"
            onClick={(e) => handleClose(e)}
          >
            Fechar
          </Button>
        </Box>

        <DataTable rows={rows} columns={columns} />
      </Box>
    </Fragment>
  );
};

// Detalhado.propTypes = {
//   transacoes: PropTypes.array,
//   comissao: PropTypes.integer,
//   handleClose: PropTypes.function,
// };
