import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { Fonts } from "shared/constants/AppEnums";
import { toast } from "react-toastify";
import { Hover } from "elements/hover";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useAuthUser } from "@crema/utility/AuthHooks";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const columns = [
  { id: "Codigo do produto", label: "Código do produto", minWidth: 170 },
  { id: "Nome do produto", label: "Nome do produto", minWidth: 100 },
  { id: "Codigo da adquirente", label: "Código da adquirente", minWidth: 100 },
  { id: "Descricao do produto", label: "Descrição do produto", minWidth: 100 },
  { id: "Status", label: "Status", minWidth: 100 },
  { id: "Ações", label: "Ações", minWidth: 100 },
];

const List = () => {
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [statusEdit, setStatusEdit] = useState("");
  const [descriptionEdit, setDescriptionEdit] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [edit, setEdit] = useState();
  const [id, setId] = useState();
  const { user } = useAuthUser();



  const { getToken } = useAuthMethod();


  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleEdit = (id, product_name, product_description, status) => {
    setEditOpen(true);
    setEdit(product_name);
    setDescriptionEdit(product_description);
    setStatusEdit(status);
    setId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeToggleStatus = (event, newStatus) => {
    setStatus(newStatus);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onChangeEdit = (event) => {
    setEdit(event.target.value);
  };

  const onChangeDescriptionEdit = (event) => {
    setDescriptionEdit(event.target.value);
  };

  // const onClickDelete = () => {
  //   deleteProduct();
  // };

  const onClickEdit = () => {
    

    handleChangeStatus(id, edit, descriptionEdit, statusEdit );
    setEditOpen(false);
  };
  

  const listProduct = async function () {
    const token = await getToken();
    let products;
    try {
      setLoading(true);
      if (search !== "") {
        setPage(0);
        const result = await request(
          process.env.REACT_APP_ACCREDITATION_API_URL +
            `/product/?status=${status}&product_cod=${search}&product_name=${search}&acquirer_cod=${search}`, 'get', token
        );
        products = result.data;
      } else {
        const result = await request(
          process.env.REACT_APP_ACCREDITATION_API_URL +
            `/product/?status=${status}`, 'get', token
        );
        products = result.data;
      }
      setProductList(products);
    } catch (error) {
      console.error(error);
      setProductList([]);
    } finally {
      setLoading(false);
    }
  };

  // const deleteProduct = async () => {
  //   try {
  //     await axios.delete(
  //       process.env.REACT_APP_ACCREDITATION_API_URL + `/product/${id}`
  //     );
  //     toast.success("Produto deletado com sucesso.");
  //     setOpen(false);
  //     listProduct();
  //     setId(null);
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("Error ao deletar produto");
  //   }
  // };

  const handleChangeStatus = async (id, product_name, product_description, status) => {
    const token = await getToken();
    setLoading(true);
    try {
      await request(
        process.env.REACT_APP_ACCREDITATION_API_URL + `/product/${id}`,'put', token,
        {
          status,
          product_name,
          product_description,
          user: user.email,
        }
      );
      toast.success("Status atualizado com sucesso.");
      listProduct();
    } catch (error) {
      console.error(error);
      toast.error("Error ao atualizar status");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listProduct();
  }, [status]);

  const handleClickAdd = function (aId) {
    navigate("/accreditation/parameters/productGestation/registration");
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 7, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Consultar Produtos
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ justifyContent: "end", display: "flex" }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={handleChangeSearch}
          />

          <Button
            onClick={listProduct}
            sx={{ backgroundColor: "#000536", mb: 5, mt: 1, ml: 1 }}
            variant="contained"
            small
          >
            Buscar
          </Button>
        </Box>
        <Grid container>
          <Grid item xs={6} sx={{ display: "flex", alignItem: "start" }}>
            <Button
              onClick={handleClickAdd}
              sx={{ backgroundColor: "#000536", mb: 1 }}
              variant="contained"
              small
            >
              Adicionar Produtos
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "end", mb: 1 }}
          >
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={handleChangeToggleStatus}
              aria-label="Platform"
            >
              <ToggleButton value="">Todos</ToggleButton>
              <ToggleButton value="ativo">Ativo</ToggleButton>
              <ToggleButton value="inativo">Inativo</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#000536",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                      <TableCell align="center">{row?.product_cod}</TableCell>
                      <TableCell align="center">{row?.product_name}</TableCell>
                      <TableCell align="center">{row?.acquirer_cod}</TableCell>
                      <TableCell align="center">{row?.product_description}</TableCell>
                      <TableCell align="center">
                        {row?.status?.toUpperCase()}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Button
                          onClick={() => handleEdit(row?.id, row?.product_name, row?.product_description, row?.status)}
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                          }}
                          variant="contained"
                          size="small"
                          disabled ={row.status?.toLowerCase() === "inativo" ? true : false}
                        >
                          Editar
                        </Button>{" "}
                        {/* <Button
                          onClick={() => handleOpen(row?.id)}
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                        >
                          Deletar
                        </Button> */}
                        <Button
                          onClick={() =>
                            handleChangeStatus(
                              row?.id,
                              row?.product_name,
                              row?.product_description,
                              row?.status?.toLowerCase() === "ativo"
                                ? "INATIVO"
                                : "ATIVO"
                            )
                          }
                          sx={{
                            backgroundColor: "#000536",
                            borderRadius: "10px",
                            ml: 3,
                          }}
                          variant="contained"
                          size="small"
                        >
                          {row?.status?.toLowerCase() === "ativo"
                            ? "Desativar"
                            : "Ativar"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: 400 }}>
                <h2 id="parent-modal-title">Tem certeza que deseja deletar?</h2>
                <Button
                  onClick={onClickDelete}
                  sx={{
                    backgroundColor: "#000536",
                    borderRadius: "10px",
                    ml: 3,
                    mt: 3,
                  }}
                  variant="contained"
                  size="small"
                >
                  Deletar
                </Button>
                <Button
                  // onClick={handleClose}
                  sx={{
                    backgroundColor: "#000536",
                    borderRadius: "10px",
                    ml: 3,
                    mt: 3,
                    alignItems: "end",
                  }}
                  variant="contained"
                  size="small"
                >
                  Cancelar
                </Button>
              </Box>
            </Modal> */}
            <Modal
              open={editOpen}
              // onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...style, width: 400 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontWeight: "bold",
                        marginBottom: "4px",
                        fontSize: 18,
                        color:"#000536",
                      }}
                    >
                     Editar
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontWeight: 500,
                        marginBottom: "4px",
                      }}
                    >
                      Nome do Produto:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%"}}>
                      <TextField
                        name="productName"
                        variant="outlined"
                        value={edit}
                        onChange={onChangeEdit}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontWeight: 500,
                        marginBottom: "4px",
                      }}
                    >
                      Descrição do produto
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%"}}>
                      <TextField
                        name="productDescription"
                        variant="outlined"
                        value={descriptionEdit}
                        onChange={onChangeDescriptionEdit}
                        
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={9} sx={{textAlign: "end", mr:1, mt:2}}>
                    <Button
                      onClick={onClickEdit}
                      sx={{
                        backgroundColor: "#000536",
                        borderRadius: "10px",                      
                      }}
                      variant="contained"
                      size="small"
                    >
                      Salvar
                    </Button>
                  </Grid>
                  <Grid item xs={2} sx={{textAlign: "end", mt: 2}}>
                    {" "}
                    <Button
                      onClick={handleClose}
                      sx={{
                        backgroundColor: "#000536",
                        borderRadius: "10px",
                        
                       
                        alignItems: "end",
                      }}
                      variant="contained"
                      size="small"
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={productList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default List;
