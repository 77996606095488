import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Fonts } from "shared/constants/AppEnums";


const ListItem = (props) => {
  const { criteria } = props;

  if(criteria.show === false){
    return <div></div>;
  }

  return (
    <Card
      sx={{
        p: 5,
        mb: 5,
      }}
      className='item-hover'
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            mr: { sm: 5 },
            mb: { xs: 3, sm: 0 },
            ".crUserImage": {
              objectFit: "cover",
              borderRadius: 5,
              width: { sx: "100%", sm: 77 },
              height: { sx: 180, sm: 130 },
            },
          }}
        >
          {criteria.image}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              color: "text.secondary",
            }}
          >
            <Box
              component='h3'
              sx={{
                mb: { xs: 2, sm: 0 },
                fontSize: 16,
                fontWeight: Fonts.BOLD,
              }}
            >
              {criteria.name}
            </Box>

            <Box
              sx={{
                ml: { xs: -4, sm: "auto" },
                mr: { sm: -4 },
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "space-between", sm: "flex-end" },
                color: "text.secondary",
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              pr: { lg: 6, xl: 16 },
            }}
          >
            <Box
              component='p'
              sx={{
                color: "text.secondary",
                mb: 4,
                fontSize: 14,
              }}
            >
              {criteria.information}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { md: "center" },
            }}
          >
            <Box
              sx={{
                ml: { md: "auto" },
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ListItem;

ListItem.propTypes = {
  criteria: PropTypes.object.isRequired,
};
