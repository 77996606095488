import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Fonts } from "shared/constants/AppEnums";
import { DataGrid, ptBR } from "@mui/x-data-grid";

const currencyLocale = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});


const Preview = ({ data, onCancel, onSave }) => {
  console.log("AAA", data);
  const columns = [
    { field: "TM de", headerName: "TM de" ,  valueFormatter: (params) =>isNaN(params.value) === true ? params.value : currencyLocale.format(params.value)},
    { field: "TM até", headerName: "TM até" ,  valueFormatter: (params) =>isNaN(params.value) === true ? params.value : currencyLocale.format(params.value)},
    { field: "TPV de", headerName: "TPV de",  valueFormatter: (params) => currencyLocale.format(params.value)},
    { field: "TPV até", headerName: "TPV até",  valueFormatter: (params) => currencyLocale.format(params.value)},
    { field: "ANTECIPA > 50%", headerName: "ANTECIPA > 50%", width: 200 },
    { field: "FEE", headerName: "FEE", valueFormatter: (params) =>` ${(params.value * 100).toFixed(2)} %` },
  ];


  const onClickSave = () => {
    const save = [];

    for (const value of data) {
    const plusPrice = {

      ticketMedioFrom: value["TM de"]=== "> R$ 250,01" ? 251 : value["TM de"],
      ticketMedioTo: value["TM até"] === "> R$ 250,01" ? 251 : value["TM até"],
      tpvFrom: value["TPV de"],
      tpvTo: value["TPV até"],
      prepaymentMoreThan50: value["ANTECIPA > 50%"] === "SIM" ? 1 : 0,
      fee: (value.FEE * 100).toFixed(2),

      }
      save.push(plusPrice)
    }
    onSave(save);
  };
  return (
    <Box>
      <Box sx={{ justifyContent: "end", display: "flex", mb: 5 }}>
        <Button
          onClick={onCancel}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Salvar
        </Button>
      </Box>
      <Box style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[30, 60, 100]}
          checkboxSelection={false}
          getRowId={(row) => row["__rowNum__"]}
        />
      </Box>
    </Box>
  );
};

Preview.defaultProps = {
  data: [],
};
Preview.propTypes = {
  data: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default Preview;
