import React, { Fragment } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Fonts } from 'shared/constants/AppEnums';
import { useTheme } from '@mui/material';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import preval from 'preval.macro';
import generatedGitInfo from 'generatedGitInfo.json';

AiOutlineInfoCircle;
export const AboutPage = () => {
  const theme = useTheme();

  return (
    <Fragment>
      <Box
        sx={{
          py: { xl: 8 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          gap: 2,
          padding: 3,
        }}
      >
        <Typography>
          Build Date: {preval`module.exports = new Date().toLocaleString();`}
        </Typography>
        <Typography>
          GIT Branch: <code>{generatedGitInfo.gitBranch}</code>
        </Typography>
        <Typography>
          GIT Commit Hash:<code>{generatedGitInfo.gitCommitHash}</code>
        </Typography>
      </Box>
    </Fragment>
  );
};
