import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import React, { useState, useCallback } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Fonts } from "shared/constants/AppEnums";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import { Hover } from "elements/hover";
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextareaAutosize, Typography } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";




const Footer = styled("footer")({
  display: "flex",
  justifyContent: "end",
  padding: "30px",
});

const currencyLocale = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const Proposal = () => {
  const [pricing, setPricing] = useState({});
  const [loading, setLoading] = useState(false);
  const [pricingPartner, setPricingPartner] = useState();
  const [description, setDescription] = useState();
  const [special, setSpecial] = useState("yes");
  const [files, setFiles] = useState({});


  let { id } = useParams();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "navy",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const { getToken } = useAuthMethod();

  const listProposal = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    try {
      const result = await request(
        process.env.REACT_APP_API_URL + `/pricingproposal/${id}`, 'get', token,
      );
      setPricingPartner(result);
      setSpecial(result[0].analysis.specialConditionPricing ?? "yes");
      setDescription(result[0].analysis.specialDescriptionPricing);
      setPricing(
        result.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {})
      );
    } catch (error) {
      console.error(error);
      toast.error("Erro ao listar dados");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    listProposal();
  }, []);

  const onChangeValue = (value, id, column) => {
    const temp = { ...pricing };
    temp[id][column] = value;

    setPricing(temp);
  };

  const onClickSendProposal = async () => {
    const token = await getToken();
    try {
      if ((!files.Pricing || description.trim() === "") && special != "no") {
        return toast.error(
          "Os campos referentes a condicoes especiais sao necessários para irmos para próxima fase."
        );
      }
      setLoading(true);

      for (const key of Object.keys(files)) {
        const uploadFiles = files[key];
        const data = new FormData();
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }

        const resultUpload = await request(
          process.env.REACT_APP_API_URL + "/documentupload/" + id + "/" + key,
          "post",
          token,
          data
        );
      }

      await request(
        process.env.REACT_APP_API_URL + `/analysis/${id}`,
        "put",
        token,
        {
          specialDescriptionPricing: description,
          specialConditionPricing: special,
        }
      );
      await request(
        process.env.REACT_APP_API_URL + `/sendproposal/${id}`, 'post' , token
      );

      toast.success("Enviado com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar proposta");
    } finally {
      setLoading(false);
    }
  };

  const onClickSave = async () => {
    const token = await getToken();
    setLoading(true);
    try {
      for (const key of Object.keys(pricing)) {
        const payload = pricing[key];
        await request(
          process.env.REACT_APP_API_URL + `/pricingproposal/${payload.id}`, 'put', token,
          {
            tpvMin: payload.tpvMin,
            tpvMax: payload.tpvMax,
            discountFee: payload.discountFee,
            prepaymentFee: payload.prepaymentFee,
            customTpvMin: payload.customTpvMin,
            customTpvMax: payload.customTpvMax,
          }
        );
      }

      await listProposal();
      toast.success("Atualizado com sucesso");
    } catch (error) {
      if (error.response.status == 400) {
        toast.error("Retention fee nao permitido");
      } else {
        toast.error("Erro ao atualizar");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSpecial = (event) => {
    setSpecial(event.target.checked === true ? "no" : "yes");
  };

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleUploadChange = (name, file) => {
    const temp = { ...files };
    console.log(file);
    temp[name] = file;
    setFiles(temp);
  };

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          // maxWidth: 576,
          width: "100%",
          textAlign: "center",
          padding: { xs: 8, lg: 12, xl: "48px 64px" },
          overflow: "hidden",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <AppBar
          position="static"
          sx={{ mb: 12, backgroundColor: "#000536", width: "100%" }}
        >
          <Toolbar>
            <Typography
              sx={{
                mb: 1.5,
                fontWeight: Fonts.BOLD,
                fontSize: 20,
                flexGrow: 1,
              }}
            >
              Precificação Personalizada de Parcerias
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Typography>TPV médio mensal</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="TPV médio mensal"
                variant="outlined"
                value={currencyLocale.format(pricingPartner?.[0]?.tpvAverage ?? "")}

                //   onChange={onChangeTpv}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>Ticket Médio</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="Ticket Médio"
                variant="outlined"
                value={currencyLocale.format(pricingPartner?.[0]?.tmAverage ?? "")}
                //   onChange={onChangeTicketMedio}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>% de antecipação</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder=" % de antecipação"
                variant="outlined"
                value={pricingPartner?.[0]?.prepayment ?? ""}
                //   onChange={onChangeAntecipacao}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>Prazo Médio</Typography>
            <FormControl fullWidth size="small">
              <TextField
                size="small"
                id="outlined-basic"
                placeholder="Prazo Médio"
                variant="outlined"
                value={pricingPartner?.[0]?.weightedDays}
                //   onChange={onChangeAntecipacao}
                disabled={true}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ mt: 8, mb: 5 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#000536" }}>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{ color: "#ffffff" }}
                    >
                      TPV - Volume transacionado por mês
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      sx={{ color: "#ffffff" }}
                    >
                      Fee (ITC + Fee)
                    </TableCell>
                  </TableRow>
                  {pricingPartner?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>entre</TableCell>
                      <TableCell align="center">
                        <FormControl fullWidth size="small">
                          <TextField
                            size="small"
                            placeholder="Tpv minimo"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            variant="outlined"
                            value={pricing?.[row.id]?.tpvMin}
                            onChange={(e) =>
                              onChangeValue(e.target.value, row.id, "tpvMin")
                            }
                            disabled={loading}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">e</TableCell>
                      <TableCell align="center">
                        <FormControl fullWidth size="small">
                          <TextField
                            size="small"
                            placeholder="Tpv maximo"
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            variant="outlined"
                            value={pricing?.[row.id]?.tpvMax}
                            onChange={(e) =>
                              onChangeValue(e.target.value, row.id, "tpvMax")
                            }
                            disabled={loading}
                          />
                        </FormControl>
                      </TableCell>

                      <TableCell align="center">
                        <FormControl fullWidth size="small">
                          <TextField
                            size="small"
                            placeholder="Fee"
                            variant="outlined"
                            value={pricing?.[row.id]?.discountFee != 0 ? pricing?.[row.id]?.discountFee : pricing?.[row.id]?.fee}
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            onChange={(e) =>
                              onChangeValue(
                                e.target.value,
                                row.id,
                                "discountFee"
                              )
                            }
                            disabled={loading}
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableHead>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#000536" }}>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{ color: "#ffffff" }}
                    >
                      Faixa de valor a ser antecipada
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      sx={{ color: "#ffffff" }}
                    >
                      Taxa Antecip.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>entre</TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth size="small">
                        <TextField
                          size="small"
                          placeholder="Fee"
                          variant="outlined"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          value={
                            pricing?.[pricingPartner?.[0]?.id]?.customTpvMin
                          }
                          onChange={(e) =>
                            onChangeValue(
                              e.target.value,
                              pricingPartner?.[0]?.id,
                              "customTpvMin"
                            )
                          }
                          disabled={loading}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">e</TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth size="small">
                        <TextField
                          size="small"
                          placeholder="Fee"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          variant="outlined"
                          value={
                            pricing?.[pricingPartner?.[0]?.id]?.customTpvMax
                          }
                          onChange={(e) =>
                            onChangeValue(
                              e.target.value,
                              pricingPartner?.[0]?.id,
                              "customTpvMax"
                            )
                          }
                          disabled={loading}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth size="small">
                        <TextField
                          size="small"
                          placeholder="Fee"
                          variant="outlined"
                          value={
                            pricing?.[pricingPartner?.[0]?.id]?.prepaymentFee
                          }
                          onChange={(e) =>
                            onChangeValue(
                              e.target.value,
                              pricingPartner?.[0]?.id,
                              "prepaymentFee"
                            )
                          }
                          disabled={loading}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Digite abaixo a condição especial já aprovada e anexe a evidência
              de aprovação dela*
            </Typography>
            <TextareaAutosize
              onChange={onChangeDescription}
              value={description}
              aria-label="minimum height"
              minRows={4}
              placeholder=" Preenchimento obrigatório."
              style={{ width: "100%" }}
            />
            <Grid container>
              <Grid item xs={6}>
                <div style={{ border: "1px dotted blue", padding: "10px" }}>
                  <FileUploader
                    handleChange={(file) => handleUploadChange("Pricing", file)}
                    name="file"
                    multiple={true}
                    children={
                      <div
                        style={{
                          textAlign: "left",
                          color: "#2C90D8",
                          padding: 0,
                        }}
                      >
                        {files?.Pricing?.length > 0 ? (
                          Object.keys(files?.Pricing).map((v) => {
                            const uploadedFile = files?.Pricing[v];
                            return <p key={v}>{uploadedFile.name}</p>;
                          })
                        ) : (
                          <p>
                            <CloudUploadOutlinedIcon /> Arraste e solte o
                            arquivo preenchido aqui
                          </p>
                        )}
                      </div>
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={special === "no"}
                        onChange={handleChangeSpecial}
                      />
                    }
                    label="Não possui condição especial"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justifySelf="flex-end">
            {(pricingPartner?.[0]?.analysis?.pricingType === "Padrão" ||
              pricingPartner?.[0]?.analysis?.pricingType === null) && (
              <>
                <Typography>Desconto Comercial</Typography>
                <FormControl size="small">
                  <TextField
                    size="small"
                    id="outlined-basic"
                    placeholder="Desconto de Retenção"
                    variant="outlined"
                    value={retentionFee}
                    onChange={onChangeRetentionFee}
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <Button
                    sx={{
                      backgroundColor: "navy",
                      borderRadius: "10px",
                      ml: 2,
                    }}
                    variant="contained"
                    disabled={loading}
                    onClick={onClickDiscountFee}
                  >
                    Salvar
                  </Button>
                </FormControl>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ backgroundColor: "navy", borderRadius: "10px" }}
              variant="contained"
              onClick={onClickSave}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
        <Footer>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                sx={{ backgroundColor: "navy", borderRadius: "10px" }}
                variant="contained"
                disabled={loading}
                onClick={onClickSendProposal}
              >
                Enviar para Proposta Comercial
              </Button>
              
            </Grid>
          </Grid>
        </Footer>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default Proposal;
