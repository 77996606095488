import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import AppScrollbar from "@crema/core/AppScrollbar";
import SidebarItem from "./SideBarItem";
import AppList from "@crema/core/AppList";
import ListEmptyResult from "@crema/core/AppList/ListEmptyResult";
import SidebarPlaceholder from "@crema/core/AppSkeleton/SidebarListSkeleton";
import PropTypes from "prop-types";

export const Sidebar = ({ report, changeTopic, currentTopic }) => {
  const [topics, setTopics] = useState([]);

  // chamado quando o report é alterado
  useEffect(() => {
    const t = [];

    let id = 1;
    for (const topicName of Object.keys(report)) {
      t.push({
        id: id,
        name: topicName,
      });

      id++;
    }

    setTopics(t);
  }, [report]);

  return (
    <Fragment>
      <Box
        sx={{
          // px: { xs: 4, md: 5 },
          // pt: { xs: 4, md: 5 },
          // pb: 2.5,
        }}
      ></Box>

      <AppScrollbar
        sx={{
          height: "calc(100% - 76px)",
        }}
      >
        <Box
          sx={{
            // pr: 4,
            // pb: { xs: 4, md: 5, lg: 6.2 },
          }}
        >
          <List
            sx={{
              // mb: { xs: 2, xl: 5 },
            }}
            component='nav'
            aria-label='main mailbox folders'
          >
            <AppList
              data={topics}
              ListEmptyComponent={
                <ListEmptyResult
                  loading={true}
                  placeholder={
                    <Box
                      sx={{
                        // px: { xs: 4, md: 5, lg: 6.2 },
                      }}
                    >
                      <SidebarPlaceholder />
                    </Box>
                  }
                />
              }
              renderRow={(item) => <SidebarItem key={item.id} item={item} changeTopic={changeTopic} currentTopic={currentTopic} />}
            />
          </List>
        </Box>
      </AppScrollbar>
    </Fragment>
  );
};
Sidebar.propTypes = {
  report: PropTypes.object,
  changeTopic: PropTypes.func.isRequired,
  currentTopic: PropTypes.string.isRequired,
};
Sidebar.defaultProps = {
  report: {},
  currentTopic: "",
};
