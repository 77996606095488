import React, { Fragment } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { Fonts } from "shared/constants/AppEnums";
import { grey } from "@mui/material/colors/index";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormikPatternFormat from 'elements/inputs/FormikPatternFormat';
import { checkCnpj } from "shared/utils/cnpj";
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';
import { useAuthUser } from '@crema/utility/AuthHooks';
import TermosPrivacidade from "pages/apps/Terms/TermosPrivacidade";
import TermosUso from "pages/apps/Terms/TermosUso";
import Modal from "@mui/material/Modal";


const validationSchema = yup.object({
  cnpj: yup
    .string()
    /*.matches(/^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/, {
      message: <em>Documento inválido</em>,
    })
    //  .matches(/^[0-9]+$/)
    .min(14)
    .max(19)
    .test("cnpj", "CNPJ inválido", function (value) {
      return checkCnpj(value);
    })*/,
  clientType: yup
    .mixed()
    .oneOf(["PSP", "VAR", "EC"])
    .required("Tipo nao selecionado"),

  email: yup.string().email().required("email invalido"),
});

const NewProposal = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termoUso, setTermoUso] = useState(false);
  const [termoPrivacidade, setTermoPrivacidade] = useState(false);
  const [openTermosUso, setOpenTermosUso] = useState(false);
  const [openTermosPrivacidade, setOpenTermosPrivacidade] = useState(false);
  const [tema, setTema] = useState(false);


  const handleOpenTermosPrivacidade = () => {
    setOpenTermosPrivacidade(true);
  };
  const handleCloseTermosPrivacidade = () => {
    setOpenTermosPrivacidade(false);
  };

  const handleOpenTermosUso = () => {
    setOpenTermosUso(true);
  };

  const handleCloseTermosUso = () => {
    setOpenTermosUso(false);
  };

  const { user } = useAuthUser();

  const { getToken } = useAuthMethod();

  const handleClose = () => {
    setOpen(false);
  };

  const getTerms = async (token,aId) => {
    try {
      const response = await request(
        process.env.REACT_APP_API_URL + `/analysis/${aId}`, 'get', token
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  // const updateTerms = async (token,uuid) => {
  //   try {
  //     await request(process.env.REACT_APP_API_URL + `/terms/${uuid}`,'put', token, { status: "Aprovado"});
  //     await request(process.env.REACT_APP_API_URL + `/processanalysis/${uuid}`, 'post', token);

  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  const validaUser = () => {
    const dominio = user.email.split('@')[1];

    if(dominio === 'bnb.gov.br'){
      setTema(true);
    }else{
      setTema(false);
    }
  };

  const estiloAppBar = {
    backgroundColor: tema ? "#a6193c" : "#000536",
    //backgroundColor: tema ? "#000536" : "#a6193c"
  };

  useEffect(() => {
    validaUser();

  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          pb: 6,
          py: { xl: 8 },
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            maxWidth: 576,
            width: "100%",
            textAlign: "center",
            padding: { xs: 8, lg: 12, xl: "48px 64px" },
            overflow: "hidden",
            boxShadow:
              "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AppBar
              position="static"
              style={estiloAppBar} // cor alterada dependendo do domínio do usuário
              sx={{ mb: 7, /*backgroundColor: "#000536",*/ width: "100%" }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Cadastrar Proposta
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>

          <Formik
            validateOnChange={true}
            initialValues={{
              cnpj: "",
              clientType:"EC",
              email: "",
              clientCel: "",
              clientName: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              
              if (!termoPrivacidade) {
                alert('Você deve aceitar a Política de Privacidade');
                setSubmitting(false);
                return;
              }
              
              if (!termoUso) {
                alert('Você deve aceitar os Termos de Uso da Plataforma');
                setSubmitting(false);
                return;
              }
          
              const token = await getToken();

              let isTrue;
              try{
                 //Chamada para o back para validar o usuário
                 const userValidation = await request(
                  process.env.REACT_APP_API_URL + `/user/validation/` + user.email,'post', token
                );
                isTrue = userValidation;

                if(isTrue.email == null){
                  throw new Error("Não vinculado canal venda para o usuário");
                }


              }catch (error) {
                toast.error("Não vinculado canal venda para o usuário");
                console.error(error);
              }

              try {
                if(isTrue.email != null){        
                  const response = await request(
                    process.env.REACT_APP_API_URL + `/lead`,'post', token,
                    {
                      ...data,
                      cnpj: data.cnpj.replace(/[^\d]+/g, ""),
                      clientCel: data.clientCel.replace(/\D/g, ''),
                      salesName: user.displayName,
                      clientName: data.clientName,
                      emailUser: user.email,
                      cep: data.cep,
                      rua: data.endereco,
                      numero: data.numero, 
                      bairo: data.bairro,
                      cidade: data.cidade,
                      Estado: data.estado,
                      complemento: data.complemento,
                      salesChannel: isTrue.canalVenda,
                      name: data.clientName,
                    },
                  );
                  console.log("Resposta: ", response);
                  // const respTerm = await getTerms(token,response.analysis.id);
                  // console.log("Resgatei o term", respTerm);
                  // setTerms(respTerm.acceptTerms);

                  // console.log("Marca como aceito");
                  // await updateTerms(token,respTerm.acceptTerms.uuid,data);


                  // console.log("Faz redirect para: " + `/onboarding/risk/analysis/waiting/${respTerm.id}`);
                  // return navigate(`/onboarding/risk/analysis/waiting/${respTerm.id}`);

                  console.log(`Navigate to price -> /onboarding/pricing/analysis/standardproposal/${response.analysis.id}`)
      
                  navigate(`/onboarding/pricing/analysis/standardproposal/${response.analysis.id}`);
                }
              } catch (error) {
                toast.error("Erro ao cadastrar CNPJ");
                console.error(error);
              }
            }}
          >
          {({ isSubmitting }) => (
              <Form
                sx={{
                  textAlign: "left",
                }}
                noValidate
                autoComplete="off"
              >
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                 <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Documento:</Typography>

                  <FormikPatternFormat
                    required
                    format="##############"
                    allowEmptyFormatting
                    mask=""
                    name="cnpj"
                    variant="outlined"
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Nome:</Typography>
                  <AppTextField
                    required
                    name="clientName"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Email:</Typography>
                  <AppTextField
                    required
                    name="email"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Cep:</Typography>
                  <FormikPatternFormat
                    required
                    name="cep"
                    format="#####-###"
                    mask="_"
                    allowEmptyFormatting
                    variant="outlined"
                    placeholder="#####-###"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Endereço:</Typography>
                  <AppTextField
                    required
                    name="endereco"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Número:</Typography>
                  <FormikPatternFormat 
                    required
                    name="numero"
                    variant="outlined"
                    format="#####"
                    mask=""
                    allowEmptyFormatting
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Bairro:</Typography>
                  <AppTextField
                    required
                    name="bairro"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Cidade:</Typography>
                  <AppTextField
                    required
                    name="cidade"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Estado:</Typography>
                  <AppTextField
                    required
                    name="estado"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Complemento:</Typography>
                  <AppTextField
                    
                    name="complemento"
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, xl: 4 },
                  }}
                >
                  <Typography sx={{ textAlign: "justify", fontWeight: 500, marginBottom: "4px" }}>Celular:</Typography>
                    <FormikPatternFormat
                      required
                      format="## #####-####"
                      allowEmptyFormatting
                      mask="_"
                      name="clientCel"
                      variant="outlined"
                      placeholder="(##) #####-####"
                    />
                </Box>

                <div style={{display: "flex", textAlign: "initial",flexDirection: "column", fontWeight: 500, lineHeight: 2}}>
                    <label>
                        <input
                          type="checkbox"
                          checked={termoPrivacidade}
                          onChange={() => setTermoPrivacidade(!termoPrivacidade)}
                        /> Li e aceito a <a href="#" onClick={handleOpenTermosPrivacidade}>Política de Privacidade </a>
                    </label>
                    <label>
                        <input
                          type="checkbox"
                          checked={termoUso}
                          onChange={() => setTermoUso(!termoUso)}
                        /> Li e aceito os <a href="#" onClick={handleOpenTermosUso}>Termos de Uso da Plataforma </a>
                    </label>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  style={estiloAppBar} // cor alterada dependendo do domínio do usuário
                  sx={{
                    width: "100%",
                    //backgroundColor: "#000536",
                    height: 44,
                  }}
                  type="submit"
                >
                  Enviar {isSubmitting && <CircularProgress />}
                </Button>
                
              </Form>
            )}
          </Formik>

          <Box
            sx={{
              mt: { xs: 3, xl: 4 },
              textAlign: "center",
              color: grey[700],
              fontSize: 14,
              fontWeight: Fonts.BOLD,
            }}
          ></Box>
        </Card>
      </Box>
      <Modal open={openTermosPrivacidade} onClose={handleCloseTermosPrivacidade}>
        <TermosPrivacidade handleCloseTermosPrivacidade={handleCloseTermosPrivacidade}/>
      </Modal>
      <Modal open={openTermosUso} onClose={handleCloseTermosUso}>
          <TermosUso handleCloseTermosUso={handleCloseTermosUso}/>
      </Modal>

    </Fragment>
  );
};

export default NewProposal;