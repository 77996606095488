import React, { Fragment } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Logo from '../../../assets/user/favicon-entre-pay.png';
import Background from '../../../assets/user/background-entre.png';
import { useAuthMethod } from '@crema/utility/AuthHooks';
import { useLocation } from 'react-router-dom';

export const Signin = () => {
  const { signIn } = useAuthMethod();

  const currentPath = window.location.pathname;


  const handleSignIn = async function () {

    try{
      await signIn();
    }catch (error) {
        console.log("ERRO NO LOGIN",error)
    }
    console.log("Terminei o signIn")
    window.location.replace(`/onboarding/proposals/registration`);
  };
  if (currentPath === '/acceptContractTerms') {
    return "";
  }else{
    return (
      <Fragment>
        <Box
          sx={{
            pb: 6,
            py: { xl: 8 },
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            sx={{
              marginTop: '9%',
              maxWidth: 1024,
              width: '100%',
              padding: 2,
              paddingLeft: { xs: 8, md: 2 },
              overflow: 'hidden',
              boxShadow:
                '10px 10px 10px 10px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%)',
            }}
          >
            <Grid container spacing={5}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  backgroundImage: `url(${Background})`,
                  '& svg': {
                    width: '100%',
                    height: '100%',
                    display: 'inline-block',
                    paddingRight: { xs: 0, lg: 10 },
                  },
                }}
              >
                {/* <Logo fill={theme.palette.primary.main} /> */}
                <img src={Logo} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  textAlign: 'center',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSignIn}
                  sx={{
                    width: '100%',
                    height: 44,
                    marginTop: 5,
                  }}
                >
                  LOGIN
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Fragment>
    );
  };
}
