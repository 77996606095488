import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
// import {Form, Formik} from 'formik';
import Toolbar from '@mui/material/Toolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';


// import * as yup from 'yup';

// const validationSchema = yup.object({
//   keyword: yup.string().required('Digite'),
// });

const currencyLocale = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const FeePlusPriceList = () => {
  const [plusPrice, setPlusPrice] = useState();
  const [loading, setLoading] = useState(false);

  const { getToken } = useAuthMethod();

  const feePlusPriceList = useCallback(async function () {
    const token = await getToken();
    try {
      setLoading(true);
      const result = await request(
        process.env.REACT_APP_API_URL + `/feeplusprice`, 'get', token
      );
      setPlusPrice(result);
    } catch (error) {
      console.error(error);
      setPlusPrice([]);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    feePlusPriceList();
  }, [feePlusPriceList]);

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: 'flex',
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: '100%',
          textAlign: 'center',
          overflow: 'hidden',
          padding: { xs: 8, md: 12 },
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <AppBar
            position="static"
            sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
          >
            <Toolbar>
              <Typography
                sx={{
                  mb: 1.5,
                  fontWeight: Fonts.BOLD,
                  fontSize: 20,
                  flexGrow: 1,
                }}
              >
                Lista de fees plus Price
              </Typography>
            </Toolbar>
          </AppBar>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">TM de</TableCell>
                  <TableCell align="center">TM até</TableCell>
                  <TableCell align="center">TPV de</TableCell>
                  <TableCell align="center">TPV até</TableCell>
                  <TableCell align="center">ANTECIPA &gt; 50%</TableCell>
                  <TableCell align="center">FEE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plusPrice?.map((plusPrice) => {
                  return (
                    <TableRow
                      key={plusPrice.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {plusPrice?.ticketMedio === 251
                          ? '> R$ 250'
                          : currencyLocale.format(plusPrice?.ticketMedioFrom)}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {plusPrice?.ticketMedio === 251
                          ? '> R$ 250'
                          : currencyLocale.format(plusPrice?.ticketMedioTo)}
                      </TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(plusPrice?.tpvFrom)}
                      </TableCell>
                      <TableCell align="center">
                        {currencyLocale.format(plusPrice?.tpvTo)}
                      </TableCell>
                      <TableCell align="center">
                        {plusPrice?.prepaymentMoreThan50 ? 'SIM' : 'NAO'}
                      </TableCell>

                      <TableCell align="center">{plusPrice?.fee}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <Hover loading={loading} />
    </Box>
  );
};

export default FeePlusPriceList;
