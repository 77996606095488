import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Typography } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import Button from '@mui/material/Button';
// import {Form, Formik} from 'formik';
import Toolbar from '@mui/material/Toolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import Preview from './Preview';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import request from 'shared/utils/request';
import { useAuthMethod } from 'hooks/AuthHooks';


// import * as yup from 'yup';

// const validationSchema = yup.object({
//   keyword: yup.string().required('Digite'),
// });

const UpdateData = () => {
  const [items, setItems] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { getToken } = useAuthMethod();

  const readExcel = ({ target: { files } }) => {
    setIsUploading(true);
    const promise = new Promise((resolve, reject) => {
      setFile(files[0]);
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(files[0]);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { range: 1 });

        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setPreviewOpen(true);
      setIsUploading(false);
      setItems(d);
    });
  };
  const handleCancel = () => setPreviewOpen(false);

  const onSave = async (data) => {
    const token = await getToken();
    setIsSaving(true);

    try {
      
      const groupSize = 20;

      for (let i = 0; i < data.length; i += groupSize) {
        const group = data.slice(i, i + groupSize);
        await request(
          process.env.REACT_APP_API_URL + `/mcc/batch`, 'post', token, 
          group
        );
      }

      toast.success('Upload feito com sucesso!');
      setPreviewOpen(false);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao fazer o upload');
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Box
      sx={{
        pb: 6,
        py: { xl: 8 },
        display: 'flex',
        flex: 1,
      }}
    >
      <Card
        sx={{
          width: '100%',
          textAlign: 'center',
          overflow: 'hidden',
          padding: { xs: 8, md: 12 },
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          paddingLeft: {
            lg: 8,
            xl: 20,
          },
          paddingRight: {
            lg: 12,
            xl: 20,
          },
        }}
      >
        {previewOpen && (
          <Preview data={items} onCancel={handleCancel} onSave={onSave} />
        )}
        {!previewOpen && (
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <AppBar
              position="static"
              sx={{ mb: 7, backgroundColor: '#000536', width: '100%' }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    mb: 1.5,
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    flexGrow: 1,
                  }}
                >
                  Update Arquivos
                </Typography>
              </Toolbar>
            </AppBar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    {/* <TableCell align="center">Ultima atualizacao</TableCell> */}
                    <TableCell align="center">Upload</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      MCC
                    </TableCell>
                    {/* <TableCell align="center"></TableCell> */}
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        component="label"
                        disabled={isUploading}
                        sx={{
                          marginRight: 5,
                          marginTop: 2,
                          padding: '9px 12px',
                          lineHeight: 1,
                          width: 96,
                          fontWeight: Fonts.MEDIUM,
                          backgroundColor: '#000536',
                        }}
                      >
                        Upload
                        <input type="file" onChange={readExcel} hidden />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {isSaving && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaving}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Card>
    </Box>
  );
};

export default UpdateData;
