import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'elements/dataTable';

import dayjs from 'dayjs';
import axios from 'axios';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Card,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formatDocument } from 'shared/utils/formatDocument';

import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';

const codProd = require('shared/constants/codProdBtg.json');

export const Liquidante = () => {
  // token da autenticação
  const { getToken } = useAuthMethod();

  const [tokenPagination, setTokenPagination] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // Tipos de mensagem
  const [valueTipoMensagem, setTipoMensagem] = useState('SLC0001'); // Tipo Mensagem
  const tiposMensagem = [
    { label: 'SLC0001', value: 'SLC0001' },
    { label: 'SLC0002', value: 'SLC0002' },
    { label: 'SLC0005', value: 'SLC0005' },
  ];
  const handleChangeTipoMensagem = (event) => {
    setTipoMensagem(event.target.value);
    setData([])
  };

  // Arranjo de pagamento (bandeira). usado apenas no SLC0001
  const [arranjo, setArranjo] = useState('VCC');
  const handleChangeArranjo = (e) => {
    setArranjo(e.target.value);
    console.log(e.target.value)
  };

  // Data Início
  const [valueDataInicio, setDataInicio] = React.useState(dayjs()); // Data Inicio
  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue);
  };

  // Data Fim
  const [valueDataFim, setDataFim] = React.useState(dayjs()); // Data Fim
  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue);
  };

  // Lista de transações
  const [transacoes, setTransacoes] = React.useState([]); // Data Fim

  const columns = [
    {
      field: 'vlrLanc',
      headerName: 'Valor',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return params.value.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      renderCell: (params) => {
        const value = params.formattedValue || '';
        return <Typography style={{ textDecoration: 'underline' }}>{value}</Typography>;
      },
    },
    { field: 'createTimestamp', headerName: 'Data', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'description', headerName: 'Descrição', align: 'center', headerAlign: 'center', flex: 1 },
    { field: 'CNPJNLiqdantCredtd', 
      headerName: 'DOC', 
      align: 'center', 
      headerAlign: 'center', 
      flex: 1, 
      valueFormatter: (params) => {
        return formatDocument(params.value)
      },
    },
  ];

  const columnsSLC0002 = [
    { field: 'createTimestamp', headerName: 'Data', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'idMovtoBody', headerName: 'IdMovto', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'identdLinhaBilatBody', headerName: 'IdentdLinhaBilat', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'bankBranchAccount', headerName: 'BankBranchAccount', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'cashDirection', headerName: 'CashDirection', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'codProdt', headerName: 'CodProdt', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'dtMovtoBody', headerName: 'DtMovto', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'sitLancSLC', headerName: 'SitLancSLC', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'NUOp', headerName: 'NUOp', align: 'center', headerAlign: 'center', flex: 1},
    { field: 'origin', headerName: 'Origin', align: 'center', headerAlign: 'center', flex: 1, },
  ];

  const generateRandom = () => {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleSearch = async () => {
    try {
      setLoading(true);

      // Pega o token do login para passar na chamada
      const token = await getToken();
      const result = await sendRequest(token);
      console.log(result);
      // setTransacoes(result.body.items);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // TODO: mudar e pegar do nosso banco de dados, alimentado pelo webhook
  const sendRequest = async (aToken, aPage = 0) => {
    /* CREDIT RESERVATION */
    // try {
    //   const params = {
    //     method: 'GET',
    //     url: 'https://backend.entrepay.com.br/integrations-homolog/listCreditReservation',
    //     params: {
    //       page: 15,
    //       dataInicial: valueDataInicio.format('YYYY-MM-DD'),
    //       dataFinal: valueDataFim.add(1, 'day').format('YYYY-MM-DD'),
    //       arranjo: "MASTERCARD"
    //     },
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${aToken}`,
    //     },
    //   };
    //   const res = await axios(params);
    //   console.log(res);
    //   setData(res.data)
    //   return res.data;
    // } catch (error) {
    //   toast.error(error.message);
    //   console.log(error);
    // }
    
    try {
      // Primeira chamada à API para obter o token
      const params = {
        method: 'GET',
        url: 'https://backend.entrepay.com.br/integrations-homolog/listSettlementService',
        params: {
          page: 15,
          token: tokenPagination,
          slc: valueTipoMensagem,
          dataInicial: valueDataInicio.format('YYYY-MM-DD'),
          dataFinal: valueDataFim.add(1, 'day').format('YYYY-MM-DD'),
          arranjo: arranjo,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
      };

      const res = await axios(params);
      console.log(res);

      // Inicializa o tokenPagination com o valor do token retornado pela API
      setTokenPagination(res.headers['x-target-token']); 

      // Adiciona os dados iniciais no estado
      setData(res.data);

      // Loop para continuar fazendo chamadas enquanto houver token
      while (tokenPagination !== '') {
        params.params.token = tokenPagination;
        const resLoop = await axios(params);
        console.log(resLoop);

        // Obtenha o valor do token do header "x-target-token" da resposta
        const newTokenPagination = resLoop.headers['x-target-token']; 

        // Atualize a variável tokenPagination com o novo valor
        setTokenPagination(newTokenPagination);

        // Concatena os novos dados no estado
        setData((prevData) => [...prevData, ...resLoop.data]);
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <Box
      id="CONTAINER"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'left',
        textAlign: 'left',
        padding: 2,
        borderBottom: `dashed 1px `,
        width: '100%',
      }}
    >
      <Hover loading={loading} />

      <Box
        id="CONTROLE"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'left',
          textAlign: 'left',
          padding: 2,
          borderBottom: `dashed 1px `,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
            padding: 2,
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Tipo de Mensagem</InputLabel>
            <Select
              value={valueTipoMensagem}
              label="Tipo de Mensagem"
              onChange={(e) => handleChangeTipoMensagem(e)}
              sx={{
                width: 200,
              }}
            >
              {tiposMensagem.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 100 } }}
                label="Data de início"
                value={valueDataInicio}
                onChange={(value) => handleChangeDataInicio(value)}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputProps={{ style: { height: '0.1em', width: 100 } }}
                value={valueDataFim}
                label="Data final"
                onChange={(value) => handleChangeDataFim(value)}
                slotProps={{ textField: { variant: 'outlined' } }}
              />
            </LocalizationProvider>
          </FormControl>

          {valueTipoMensagem === 'SLC0001' && (
            <FormControl fullWidth>
              <InputLabel>Arranjo de Pagamento</InputLabel>
              <Select
                value={arranjo}
                label="Arranjo de pagamento"
                onChange={(e) => handleChangeArranjo(e)}
                sx={{
                  width: 240,
                }}
              >
                {codProd.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{
            m: 1,
            minWidth: 50,
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            color: 'primary.contrastText',
            backgroundColor: 'primary.main',
            '&:hover': { backgroundColor: 'primary.light' },
          }}
        >
          Pesquisar
        </Button>
      </Box>

      {/* tabela com as transações */}
      <Card
        sx={{
          boxShadow:
            '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
          padding: 2,
          borderRadius: '16px',
          width: '100%',
          height: '100%',
        }}
      >
        <DataTable rows={ data.map((item, index) => ({ ...item, 
        createTimestamp:  dayjs(item.createTimestamp).format('DD-MM-YYYY'), 
        description: item.description, 
        valor: item.vlrLanc,
        CNPJNLiqdantCredtd: item.cnpjLiqdantCredtd,
        idMovtoBody: item.idMovtoBody,
        identdLinhaBilatBody: item.identdLinhaBilatBody,
        BankBranchAccount: item.bankBranchAccount,
        CashDirection: item.cashDirection,
        CodProdt: item.codProdt,
        DtMovtoBody:  dayjs(item.dtMovtoBody).format('DD-MM-YYYY'), 
        SitLancSLC: item.sitLancSLC,
        NUOp: item.nuop,
        Origin: item.origin,
        })) 
        ?? [] } 
        columns={valueTipoMensagem === "SLC0002" ? columnsSLC0002 : columns} 
        getRowId={(row) =>  generateRandom()}
        pageSize={15}
        />
      </Card>
    </Box>
  );
};
