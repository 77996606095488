import React, { Component, useEffect, Fragment, useState } from 'react';

import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from 'recharts';

import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import { Hover } from 'elements/hover';

import {
  InputLabel,
  Button,
  MenuItem,
  FormControl,
  Select,
  Stack,
} from '@mui/material';

import './Consulta_EntregaEmails.css';

import { toast } from 'react-toastify';

export const ConsultaEntregaEmailsClient = () => {

  const [valueWorkFlow, setValueWorkFlow] = React.useState('accreditation_letter');
  const [valueDataInicio, setValueInicio] = React.useState(dayjs());
  const [valueDataFim, setValuefim] = React.useState(dayjs());

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState('');

  const [displayGraphic, setDisplayGraphic] = useState('none');

  const [dataGraphic, setDataGraphic] = useState([]);

  const handleChangeInicio = (newValue) => {
    setValueInicio(newValue);
  };

  const handleChangeFim = (newValue) => {
    setValuefim(newValue);
  };

  ///SELECT

  const handleChangeWorkFlow = (event) => {
    setValueWorkFlow(event.target.value);
  };

  const QUERY = `
    query ($workflow: String, $data: String!, $dataFinal: String!) {
      summary(
        workflow: $workflow, 
        initialDate: $data, 
        finalDate: $dataFinal
      ) {
        total_pdf
        send_error
        sent
        awaiting_status
        delivered
        soft_bounce
        hard_bounce
        complaint
      }
    }
  `;

  const COLORS = ['#0088FE', '#ffea22', '#ffa00a', '#F7050D', '#76675d', '#993399', '#2ac200'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleSearch = async () => {
    setLoading(true)
    try {
      const response = await fetch('https://backend.entrepay.com.br/informes/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: QUERY,
          variables: {
            workflow: valueWorkFlow,
            data: valueDataInicio.format('YYYY/MM/DD'),
            dataFinal: valueDataFim.format('YYYY/MM/DD'),
          },
        }),
      });

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.errors) {
        throw new Error(responseData.errors[0].message);
      }
      setData(responseData.data);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    setDataGraphic([])
    if(data) {
      console.log(data.summary);
      let arrayEmail = [];

      let objetoEmailEnviado = {
        name: 'Emails Enviados',
        value: data.summary.sent,
      };
      let objetoAwaitingStatus = {
        name: 'Esperando Status',
        value: data.summary.awaiting_status,
      };
      let objetoSoftBounce = {
        name: 'Soft Bounce',
        value: data.summary.soft_bounce,
      };
      let objetoHardBounce = {
        name: 'Hard Bounce',
        value: data.summary.hard_bounce,
      };
      let objetoSendError = {
        name: 'Erros De Envio',
        value: data.summary.send_error,
      };
      let objetoComplaint = {
        name: 'Reclamados',
        value: data.summary.complaint,
      };
      let objetoDelivered = {
        name: 'Entregues',
        value: data.summary.delivered,
      };

      arrayEmail.push(
        objetoEmailEnviado,
        objetoAwaitingStatus,
        objetoSoftBounce,
        objetoHardBounce,
        objetoSendError,
        objetoComplaint,
        objetoDelivered
      );

      console.log("Array Email:", arrayEmail)

      const newArrayEmail = arrayEmail.filter((object) => {
        return object.value !== 0;
      });

      if(newArrayEmail.length === 0) {
        toast.error("Dados não existentes!");
        setDisplayGraphic('none')
      } else {
        setDisplayGraphic('block')
      }
      console.log(arrayEmail);
      setDataGraphic(newArrayEmail)
    }
  }, [data]);

  return (
    <Box>
      <div>
        <div class="inputsDiv">
          <div class="contentInputs">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                sx={{ margin: 3 }}
              >
                <DesktopDatePicker
                  inputProps={{ style: { height: '0.1em', width: 220 } }}
                  value={valueDataInicio}
                  onChange={handleChangeInicio}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
                <DesktopDatePicker
                  inputProps={{ style: { height: '0.1em', width: 220 } }}
                  value={valueDataFim}
                  onChange={handleChangeFim}
                  slotProps={{ textField: { variant: 'outlined' } }}
                />
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={valueWorkFlow}
                    onChange={handleChangeWorkFlow}
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    <MenuItem value={'accreditation_letter'}>Boas Vindas</MenuItem>
                    <MenuItem value={'cancellation_letter'}>Cancelamento</MenuItem>
                    <MenuItem value={'chargeback_letter'}>Chargeback</MenuItem>
                    <MenuItem value={'statement'}>Extrato</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleSearch()}
                    sx={{
                      m: 1,
                      minWidth: 50,
                      color: 'primary.contrastText',
                      backgroundColor: 'primary.main',
                      '&:hover': { backgroundColor: 'primary.light' },
                    }}
                  >
                    Pesquisar
                  </Button>
                </FormControl>
              </Stack>
            </LocalizationProvider>
          </div>
        </div>
        <div class="main">
          <div class="content">
            <div class="emailDiv" style={{ display: displayGraphic }}>
              <div class="divTextEmail">
                <h2 class="textEmail">Emails</h2>
              </div>
              <div class="subtitleDiv">
                <div class="subtitle">
                  <h4>Porcentagem de Emails</h4>
                </div>
              </div>
              <div class="graphicEmail">
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        cx="50%"
                        cy="50%"
                        dataKey="value"
                        data={dataGraphic}
                        fill="#8884d8"
                        labelLine={false}
                        label={renderCustomizedLabel}
                      >
                        {dataGraphic.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Hover loading={loading} />
      </div>
    </Box>
  );
};
