import axios from 'axios';

async function request (url, method, aToken, body) {
    
      const params = {
        method: method,
        url: url,
        data: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${aToken}`,
        },
      };
      const res = await axios(params);
      return res.data;
    
  }
  
  export default request;