import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuthMethod } from 'hooks/AuthHooks';
import request from 'shared/utils/request';
import { GiArcheryTarget } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
import { FaRegListAlt } from 'react-icons/fa';
import AppList from '@crema/core/AppList';
import ListItem from './Report/ListItem';
import Card from "@mui/material/Card";
import { Fonts } from "shared/constants/AppEnums";
import { useAuthUser } from '@crema/utility/AuthHooks';
import { toast } from 'react-toastify';

const Waiting = ( ) => {
  const { id } = useParams();
  const { getToken } = useAuthMethod();
  const navigate = useNavigate();

  const [totalScore1, setTotalScore1] = useState();
  const [loading, setLoading] = useState(true);
  const [fezChamada, setFezChamada] = useState(false);
  const [riskStatus, setRiskStatus] = useState('Carregando...');
  const [proposal, setProposal] = useState();
  const [passouRisco, setPassouRisco] = useState(false);
  const [score, setScore] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [etapa, setEtapa] = useState('Consultando dados ...');
  const [bgColor, setBgColor] = useState('');
  const [pricingPartner, setPricingPartner] = useState();
  const { user } = useAuthUser();
  const [userIP, setUserIP] = useState('');
  const [mcc, setMcc] = useState("");



  let interval; // Declare a variável interval fora do useEffect
  const enviaEmailContrato = async (pricing) => {

    const token = await getToken();
      const [
          resultAnalysis1,
        ] = await Promise.all([
          request(process.env.REACT_APP_API_URL + `/analysis/${id}`,'get', token),
        ]);
    console.log("Envio Email Analise: ", resultAnalysis1)
    console.log("Envio Email Price: ", pricing)

    let dadosCadastrais = JSON.parse(resultAnalysis1.bigData.bigData.bigData.dadosCadastrais);

    let objDadosAceite = {
      id: id,
      cnpj: resultAnalysis1.dataCnpj.cnpj,
      uuid: resultAnalysis1.acceptTerms.uuid,
      precoBandeira: !!pricing.departamento && !!pricing.departamento[0] ? pricing.departamento[0].precoBandeira : "",
      token: token
    }

    let objEnviar = {
      email: resultAnalysis1.email,
      nome: resultAnalysis1.clientName,
      razaoSocial: dadosCadastrais.Result[0].BasicData.OfficialName,
      cnpj: resultAnalysis1.dataCnpj.cnpj,
      contato: "Para nos contatar, envie email para: suporte@entrepay.com.br",
      domicilioBancario: {
        banco: !!resultAnalysis1.legalForm && !!resultAnalysis1.legalForm.bank ? resultAnalysis1.legalForm.bank : bank,
        agencia: !!resultAnalysis1.legalForm && !!resultAnalysis1.legalForm.branch ? resultAnalysis1.legalForm.branch : branch,
        documento: !!resultAnalysis1.legalForm && !!resultAnalysis1.legalForm.cpfOrCnpjAccountHolder ? resultAnalysis1.legalForm.cpfOrCnpjAccountHolder : cpfOrCnpjAccountHolder,
        conta: !!resultAnalysis1.legalForm && !!resultAnalysis1.legalForm.account ? resultAnalysis1.legalForm.account : account,
        titular: !!resultAnalysis1.legalForm && !!resultAnalysis1.legalForm.accountHolder ? resultAnalysis1.legalForm.accountHolder : accountHolder
      },
      precoBandeira: !!pricing.departamento && !!pricing.departamento[0] ? pricing.departamento[0].precoBandeira : "",
      urlaceite: process.env.REACT_APP_REDIRECT_URI + `/acceptContractTerms/?a=` + encodeObjectToBase64(objDadosAceite),
    }

    console.log("Enviar email: ", objEnviar);


    const response = await request(process.env.REACT_APP_API_URL + `/accreditation/email`,'post', token, objEnviar);
    console.log("Resposta: ", response);

  }


  function encodeObjectToBase64(obj) {
    const jsonString = JSON.stringify(obj);
    const base64String = btoa(jsonString);
    return base64String;
  }

  const calculaScore = async () => {
    const token = await getToken();
    try {
      //SCORE
      const resultAnalysisScore = await request(
        process.env.REACT_APP_API_URL + `/analysisscore/${id}`,'get', token,
      );
      const totalScore = resultAnalysisScore?.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      setTotalScore1(totalScore);
      //toast.success('Path finder atualizado com sucesso.');
    } catch (error) {
      console.error(error);
      toast.error('Erro ao retornar score');
    } finally {
      setLoading(false);
    }
  };
  
  const getProposal = async function () {
    console.log("Chamou getProposal")
    const axios = require('axios');

    const token = await getToken();
    try {
      const [
        result,
      ] = await Promise.all([
        request(process.env.REACT_APP_API_URL + `/analysis/${id}`,'get', token),
      ]);
      console.log("result", result)

      const terms = result.acceptTerms;
      const getUuid = terms.uuid;

      setEtapa("Calculando resultado ...");

        let bodyJson = {
          "document": result.dataCnpj.cnpj,
          "idPayFac": null,
          "isBranch": null,
          "channel": "1",
          "socialName": null,
          "fantasyName":null,
          "email": result.email,
          "telephone1": result.clientCel,
          "telephone2": null,
          "openingDate": null,
          "dateOfBoarding": null,
          "technology": null,
          "cnae": null,
          "mcc": "",
          "promoterId": null,
          "shopping": false,
          "urlEcommerce": null,
          "isEcommerce": false,
          "category":"subadquirente",
          "address": {
            "streetAddress": null,
            "city": null,
            "zipCode": null,
            "number": null,
            "state": null
          }
        }
            try{
              if(!fezChamada){
                  console.log("Chamando credenciamento com json: ", bodyJson);
                  setFezChamada(true);
                  sleep(1000);
                  const prop = await request(
                    process.env.REACT_APP_API_URL + `/analysis/${id}`, 'get', token
                  );
                  setProposal(prop);

                  let respRisk = "";
                  if(prop?.dataCnpj?.cnpj.length == 11){
                    bodyJson.category = "credenciamento ec pf";
                    respRisk = await request(process.env.REACT_APP_API_URL + `/accreditation/risk/analysis/proposal/${id}`, 'post', token, bodyJson);
                    console.log("Resposta do risco: ",respRisk);
                  }
                  setEtapa("Enviando dados ...");
                      
                  if(prop?.dataCnpj?.cnpj.length == 14 || (respRisk.statusRisk == "APROVADO" || respRisk.statusRisk == "REJEITADO" || respRisk.statusRisk == "EM ANALISE" && prop?.dataCnpj?.cnpj.length == 11)){                   
                      let response = "";
                      if(prop?.dataCnpj?.cnpj.length == 14){
                        let objJsonEnviar = {
                          uuid: getUuid,
                        }
                        console.log("Enviando para o credenciamento");
                        response = await request(
                          process.env.REACT_APP_API_URL + `/accreditation/api/registerVar`,'post', token, objJsonEnviar
                        );
                        console.log(response);
                      }else{
                        let objJsonEnviar = {
                          uuid: getUuid,
                        }
                        response = await request(
                          process.env.REACT_APP_API_URL + `/accreditation/api/registerVarPF`,'post', token, objJsonEnviar
                        );
                        console.log(response);
                      }

                        fetch('https://api.ipify.org?format=json')
                          .then(response => response.json())
                          .then(data => {

                            setUserIP(data.ip)
                            let uuid = getUuid;
                            const now = new Date();
                            const currentDate = now.toISOString();
                            console.log('Endereço IP do usuário:', data.ip);

                            let objPostar =  {
                                status: "Aprovado",
                                statusTermsUse: "Aprovado",
                                termsAcceptDate: currentDate,
                                country: "BR",
                                ip: data.ip,
                            }
                            axios.put(process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid, objPostar).then(resp => {
                              console.log("Resp aceite: ",resp)
                              //window.location.href = REACT_APP_REDIRECT_URI;
                            })
                          })
                          .catch(error => console.log(error));

                          calculaScore();

                          setPassouRisco(true);
                          setLoading(false);
                          if(prop?.dataCnpj?.cnpj.length == 11){
                            if(respRisk.statusRisk == "APROVADO"){
                              setBgColor('green');
                              toast.success('Proposta cadastrada');
                            }else if(respRisk.statusRisk == "EM ANALISE"){
                              setBgColor('yellow');
                              toast.success('Proposta cadastrada - Emcaminhada para a análise de risco');
                            }else{
                              setBgColor('red');
                              toast.success('Proposta cadastrada e rejeitada pela análise automática de risco')
                            }
                          }else{
                            if(response?.statusRisk == "APROVADO"){
                              setBgColor('green');
                              toast.success('Proposta cadastrada');
                            }else if(response?.statusRisk == "EM ANALISE"){
                              setBgColor('yellow');
                              toast.success('Proposta cadastrada - Emcaminhada para a análise de risco');
                            }else{
                              setBgColor('red');
                              toast.success('Proposta cadastrada e rejeitada pela análise automática de risco')
                            }
                          }
                  }else{
                    calculaScore();
                    setPassouRisco(false);
                    setLoading(false);
                    setBgColor('red');
                    toast.success('Erro no processamento');
                  }
              }
             
            }catch(error){
              console.log("Erro aqui oh no resgate do risco: ", error)
              setPassouRisco(false);
              setLoading(false);
              setTotalScore1("999");
              toast.error('Erro ao analisar a proposta - Contate o nosso suporte');
            }
      
    }catch(error){
      console.log("Algo aconteceu aqui: ", error)
    }
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    console.log("USE EFFECT");
      getProposal();

      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const mccurl = params.get('mcc');
      if (mccurl) {
        console.log('Valor de mcc:', mccurl);
        setMcc(mccurl);
      } else {
        console.log('Valor de mcc não encontrado na URL.');
      }
  }, []); // Passar um array vazio como segundo argumento

  return (
   <>
      {loading ? 
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            backgroundColor="#f5f5f5"
          >
          <CircularProgress color="primary" size={60} />
          <Typography variant="body1" color="textSecondary" mt={2}>
           {etapa}
          </Typography>
        </Box>
      :
        <Fragment>
          <Card sx={{p: 5,mb: 5, backgroundColor: bgColor}} className='item-hover'>
              <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }}}>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      mb: 3,
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      color: "text.secondary",
                    }}
                  >
                    <Box
                      component='h3'
                      sx={{
                        mb: { xs: 2, sm: 0 },
                        fontSize: 16,
                        fontWeight: Fonts.BOLD,
                      }}
                    >
                      Resultado Score TOTAL: {totalScore1}
                    </Box>
                    <br />
                    {!!passouRisco && "Proposta cadastrada."}

                    <br />

                    
                    <button onClick={() => navigate(`/onboarding/proposals/registration/`)} style={{ fontSize: '1rem', padding: '0.5rem 1rem', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', marginTop: '1rem', cursor: 'pointer' }}>      
                      Registrar nova proposta
                    </button>

                  </Box>
                </Box>
              </Box>
            </Card>
        </Fragment>
      }
    </>
  );
};
export default Waiting;