import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import request from "shared/utils/request";

const AcceptContractTerms = () => {
  const [userIP, setUserIP] = useState('');

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const enviaCredenciamento = async (obj) => {
      const token = obj.token;

      let objJsonEnviar = {
        uuid: obj.uuid,
        condicoes: []
      }
      obj.precoBandeira.map((bandeira,i) => {
        objJsonEnviar.condicoes[i] = {};
        objJsonEnviar.condicoes[i].bandeira = bandeira.bandeira,
        objJsonEnviar.condicoes[i].limMinFinanceiro = bandeira.credito,
        objJsonEnviar.condicoes[i].limMinComercial = bandeira.credito,
        objJsonEnviar.condicoes[i].condDebito = bandeira.debito,
        objJsonEnviar.condicoes[i].condCredito = bandeira.credito,
        objJsonEnviar.condicoes[i].condParc1 = bandeira.parcelado2x6,
        objJsonEnviar.condicoes[i].condParc2 = bandeira.parcelado7x12,
        objJsonEnviar.condicoes[i].condParcemissor = bandeira.parceladoEmissor,
        objJsonEnviar.condicoes[i].condCredMin = bandeira.credito,
        objJsonEnviar.condicoes[i].condDebMin = bandeira.debito,
        objJsonEnviar.condicoes[i].condParc1Min = bandeira.parcelado2x6,
        objJsonEnviar.condicoes[i].condParc2Min = bandeira.parcelado7x12,
        objJsonEnviar.condicoes[i].condParcEmMin = bandeira.parceladoEmissor,
        objJsonEnviar.condicoes[i].condCredMax = bandeira.credito,
        objJsonEnviar.condicoes[i].condDebMax = bandeira.debito,
        objJsonEnviar.condicoes[i].condParc1Max = bandeira.parcelado2x6,
        objJsonEnviar.condicoes[i].condParc2Max = bandeira.parcelado7x12,
        objJsonEnviar.condicoes[i].condParcEmMax = bandeira.parceladoEmissor
      });

      console.log("Envinando para o credenciamento: ",objJsonEnviar)
      const response = await request(
        process.env.REACT_APP_API_URL + `/accreditation/api/registerVar`,'post', token, objJsonEnviar
      );
      
      console.log("Resposta: ", response);

      return true;
    
  }


  const getProposal = async function () {
    const searchParams = new URLSearchParams(window.location.search);
    const aParam = atob(searchParams.get('a'));
    const obj = !!aParam && JSON.parse(aParam);
    const axios = require('axios');

    console.log('Valor de "a":', obj);
    

    if(!!obj && !!obj.id){

        await enviaCredenciamento(obj);

        fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {

            setUserIP(data.ip)
            let uuid = obj.uuid;
            const now = new Date();
            const currentDate = now.toISOString();
            console.log('Endereço IP do usuário:', data.ip);

            let objPostar =  {
                status: "Aprovado",
                statusTermsUse: "Aprovado",
                termsAcceptDate: currentDate,
                country: "BR",
                ip: data.ip,
            }
             axios.put(process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid, objPostar).then(resp => {
              console.log("Resp aceite: ",resp)
              alert("Proposta completa!")
              window.location.href = "https://www.entrepay.com.br";
             })

          
          })
          .catch(error => console.log(error));
    }else{
        alert("Contrato nao encontrado");
        window.location.href = "https://www.entrepay.com.br";
    }

  }

  useEffect(() => {
     getProposal();
  }, []);



  return (
    "Registrando ...."
  )
};

export default AcceptContractTerms;
