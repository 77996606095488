import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { Fonts } from "shared/constants/AppEnums";
import { DataGrid, ptBR } from "@mui/x-data-grid";

const currencyLocale = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

const MdrPreview = ({ data, onCancel, onSave }) => {
  console.log("AAA", data);
  const columns = [
    { field: "MCC", headerName: "MCC"},
    { field: "CNAE", headerName: "CNAE"},
    { field: "TM de", headerName: "TM de"},
    { field: "TM ate", headerName: "TM até"},
    { field: "TPV de", headerName: "TPV de"},
    { field: "TPV ate", headerName: "TPV até"},
    { field: "Antecipação < 50%", headerName: "Antecipação < 50%" },
    { field: "Bandeira", headerName: "Bandeira"},
    { field: "Produto", headerName: "Produto" },
    { field: "MDR", headerName: "MDR", valueFormatter: (params) => ` ${(params.value * 100).toFixed(2)} %` },


  ];

  const onClickSave = () => {
    const save = [];

    for (const value of data) {
    const mdr = {

      mcc: value.MCC,
      cnae: value.CNAE,
      ticketMedioFrom: value["TM de"],
      ticketMedioTo: value["TM ate"],
      tpvFrom: value["TM de"],
      tpvTo: value["TPV ate"],
      prepaymentMoreThan50: value["Antecipação < 50%"], 
      cardBrand: value.Bandeira,
      product: value.Produto,
      mdr:Number((value.MDR * 100).toFixed(2)),

      

      }
      save.push(mdr)
    }
    onSave(save);
  };
  return (
    <Box>
      <Box sx={{ justifyContent: "end", display: "flex", mb: 5 }}>
        <Button
          onClick={onCancel}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickSave}
          variant="contained"
          component="label"
          sx={{
            marginRight: 5,
            marginTop: 2,
            padding: "9px 12px",
            lineHeight: 1,
            width: 96,
            fontWeight: Fonts.MEDIUM,
            backgroundColor: "#000536",
          }}
        >
          Salvar
        </Button>
      </Box>
      <Box style={{ height: "500px", width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rowsPerPageOptions={[30, 60, 100]}
          checkboxSelection={false}
          getRowId={(row) => row["__rowNum__"]}
        />
      </Box>
    </Box>
  );
};

MdrPreview.defaultProps = {
  data: [],
};
MdrPreview.propTypes = {
  data: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default MdrPreview;
