import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { plain } from 'shared/utils/plain';
import { formatDocument } from 'shared/utils/formatDocument';
import uuid4 from 'uuid4';

import axios from 'axios';

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { DataTable } from 'elements/dataTable';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { Hover } from 'elements/hover';
import { useAuthMethod } from 'hooks/AuthHooks';

export const ConsultaContratoCessao = () => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{
              allColumns: true,
              delimiter: ';',
              utf8WithBom: true,
            }}
          />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  };

  const [loading, setLoading] = useState(false);

  // token da autenticação
  const { getToken } = useAuthMethod();

  const [dateFrom, setDateFrom] = useState(dayjs());
  const handleChangeDateFrom = (newValue) => {
    setDateFrom(dayjs(newValue));
  };

  const [dateTo, setDateTo] = useState(dayjs());
  const handleChangeDateTo = (newValue) => {
    setDateTo(dayjs(newValue));
  };

  const [paymentNetworks, setPaymentNetworks] = useState([]);
  const [currentPaymentNetwork, setCurrentPaymentNetwork] = useState('');
  const handleChangePaymentNetwork = (event) => {
    setCurrentPaymentNetwork(event.target.value);
    console.log(event.target.value)
  };

  const [issuers, setIssuers] = useState([]);
  const [assignees, setAssignees] = useState([]);

  const columns = [
    { field: 'idCessionario', headerName: 'ID Cessionário', hide: true },
    { field: 'cessionario', headerName: 'Cessionário', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'dataLiquidacao', headerName: 'Data Liquidação', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    {
      field: 'valor',
      headerName: 'Valor',
      width: '100%', 
      flex: 1, align: 'center', 
      headerAlign: 'center',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return params.value.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      },
    },
    { field: 'bandeira', headerName: 'Bandeira', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'idEmissor', headerName: 'ID Emissor', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'emissor', headerName: 'Emissor', width: '100%', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'idContrato', headerName: 'Registro do Contrato', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'status', headerName: 'Status', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'dataRegistro', headerName: 'Data Registro', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'codErro', headerName: 'Cod Erro', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
    { field: 'descErro', headerName: 'Descrição', width: '100%', flex: 1, align: 'center', headerAlign: 'center'},
  ];

  const columnVisible = {
    idCessionario: false,
    idEmissor: false,
  };

  const [rows, setRows] = useState([]);

  const getName = (aId, aList) => {
    for (const a of aList) {
      if (a.value === aId) {
        return a.label;
      }
    }
    return '';
  };

  // Ele retorna AMEX na lista de bandeiras, mas dá erro na hora de trazer os emissores
  function paymentNetworkFilter(aPaymentNetwork) {
    return aPaymentNetwork.value !== 'AMEX';
  }

  const fetchContracts = async (aPage) => {
    try {
      setLoading(true);

      // Opcional: bandeira
      let params = {};
      if (currentPaymentNetwork.length > 0) {
        params = {
          paymentNetwork: currentPaymentNetwork,
        };
      }

      const result = await fetchData(
        `https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/credit-reservations/${dateFrom.format(
          'YYYY-MM-DD'
        )}/${dateTo.format('YYYY-MM-DD')}`,
        params
      );

      const d = result.map((item) => {
        return {
          id: uuid4(),
          idCessionario: item.body.assigneeId,
          cessionario: getName(item.body.assigneeId, assignees),
          dataLiquidacao: item.body.reservationDate,
          valor: item.body.amount / 100,
          bandeira: getName(item.body.paymentNetwork, paymentNetworks),
          idEmissor: item.body.cardIssuerId,
          emissor: getName(item.body.cardIssuerId, issuers),
          idContrato: item.pactualId,
          status: item.status,
          dataRegistro: item.createTimestamp,
          codErro: item.errorCode,
          descErro: item.errorDescription,
        };
      });
      setRows(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todas as bandeiras
  const fetchPaymentNetworks = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/payment-networks'
      );

      const d = result.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setPaymentNetworks(d.filter(paymentNetworkFilter));
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os cessionários
  const fetchAssignees = async () => {
    try {
      setLoading(true);

      const result = await fetchData(
        'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/assignees'
      );

      const d = result.map((item) => {
        return {
          value: item.assigneeAccountId,
          label: item.body.name,
        };
      });
      setAssignees(d);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setAssignees([]);
    } finally {
      setLoading(false);
    }
  };

  // busca a lista de todos os emisores
  const fetchCardIssuers = async () => {
    try {
      const emissores = [];
      setLoading(true);

      // a api só lista por bandeira
      for (const p of paymentNetworks) {
        const i = await fetchCardIssuersByPaymentNetwork(p.value);
        emissores.push([...i]);
      }
      setIssuers(emissores);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCardIssuersByPaymentNetwork = async (aPaymentNetwork) => {
    const result = await fetchData(
      'https://backend.entrepay.com.br/btg-homolog/tr/depositary-bank/card-issuer/search',
      {
        paymentNetwork: aPaymentNetwork,
      }
    );

    const d = result.map((item) => {
      return {
        value: item.cardIssuerId,
        label: item.name,
      };
    });
    return d;
  };

  const fetchData = async (aUrl, aParams = {}) => {
    // token do ad
    const token = await getToken();

    const params = {
      method: 'GET',
      url: aUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: aParams,
    };
    const res = await axios(params);
    if (res.status === 200) {
      return res.data.body;
    } else {
      let msg = '';
      for (const e of res.errors) {
        console.log(e.message);
        msg += e.message + ' ';
      }
      throw msg;
    }
  };

  const handleSearch = async (event) => {
    fetchContracts();
  };

  const handleCellClick = (e) => {
    //    const document = e.row;
  };

  /**
   * useEffect
   */
  // Inicial
  useEffect(() => {
    fetchPaymentNetworks();
    fetchAssignees();
  }, []);

  // Quando tem a lista das bandeiras, busca os emissores
  useEffect(() => {
    fetchCardIssuers();
  }, [paymentNetworks]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        padding: 2,
        width: '100%',
      }}
    >
      <Hover loading={loading} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'left',
          padding: 2,
          gap: 2,
          width: '100%',
        }}
      >
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: '0.1em', width: 220 } }}
              label={'De*'}
              value={dateFrom}
              onChange={handleChangeDateFrom}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputProps={{ style: { height: '0.1em', width: 220 } }}
              label={'Até*'}
              value={dateTo}
              onChange={handleChangeDateTo}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Bandeira</InputLabel>
          <Select
            value={currentPaymentNetwork}
            label="Bandeira"
            onChange={(e) => handleChangePaymentNetwork(e)}
            sx={{
              width: 200,
            }}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {paymentNetworks.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              m: 1,
              minWidth: 50,
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.light' },
            }}
          >
            Pesquisar
          </Button>
        </FormControl>
      </Box>

      <DataTable
        rows={rows}
        columns={columns}
        onCellClick={handleCellClick}
        columnVisibilityModel={columnVisible}
      />
    </Box>
  );
};
